import {
  MatchedPartyStatusCd,
  MatchedPartyStatusCdHelper,
  MatchedPartySourceCdHelper,
  ChargeToCd, ActionCd,
} from '@xpo-ltl/sdk-common';
import { AsMatchedParty, AsEnteredBolParty } from '@xpo-ltl/sdk-shipment';
import { Country } from '../../../../bill-entry/model/country';
import { Party, PARTY_TYPE_ID } from '../../../../bill-entry/model/party';
import { CountryTransformer } from './country-transformer';

export class PartiesTransformer {
  static toAsEnteredBolParty(parties: Party[], nonRevenue: boolean): AsEnteredBolParty[] {
    if (parties) {
      return parties.map(party => {

        // If the shipper or consignee have empty required fields, return an error
        if (Party.empty(party) && party.partyTypeId != null && party.partyTypeId !== PARTY_TYPE_ID.BillTo) {
          throw new Error(`AsEnteredParty Does Not Have All Required Fields`);
        } else if (Party.notEmpty(party) || (party.partyTypeId === PARTY_TYPE_ID.BillTo && party.actionCd === ActionCd.DELETE)) {
          if (party.partyTypeId !== PARTY_TYPE_ID.BillTo || (( party.partyTypeId === PARTY_TYPE_ID.BillTo ) && !nonRevenue)) {
            const asEntered = new AsEnteredBolParty();
            asEntered.partyTypeCd = Party.toBolPartyTypeCd(party.partyTypeId);
            asEntered.name1 = party.name1;
            asEntered.name2 = party.name2 || undefined;
            asEntered.address = party.address;
            asEntered.city = party.city;
            asEntered.stateCd = party.state ? party.state.toUpperCase() : '';
            asEntered.countryCd = CountryTransformer.toUpsertShipment(party.country);
            asEntered.zip6 = party.zip ? party.zip.trim() : party.zip;
            asEntered.zip4RestUs = party.zip4 ? party.zip4.trim() : party.zip4;
            asEntered.phoneNbr = (party.phone) ? party.phone.replace(/[^\d]/g, '') : '';
            asEntered.phoneNbr = party.extension && party.extension.trim().length
              ? `${asEntered.phoneNbr}X${party.extension}`
              : asEntered.phoneNbr;
            asEntered.listActionCd = party.actionCd;
            return asEntered;
          }
        }
      }).filter(v => !!v);
    } else {
      throw new Error(`AsEnteredParties cannot be undefined`);
    }
  }

  static toAsMatchedParty(asMatchedParties: Party[], debtorType: ChargeToCd, asEnteredParties: Party[]): AsMatchedParty[] {
    if (asMatchedParties && asEnteredParties) {
      const parties = asMatchedParties.map(party => {
        if (party.partyTypeId !== PARTY_TYPE_ID.BillTo && !Party.notEmpty(party)) {
          throw new Error('AsMatchedParty does not have all required fields, please try Rematching.');
        }

        if (party.partyTypeId !== PARTY_TYPE_ID.BillTo
          || (
            party.partyTypeId === PARTY_TYPE_ID.BillTo && debtorType
            && (party.matchedStatusCd === MatchedPartyStatusCd.DERIVED_BILL_TO || Party.notEmpty(party))
          )
        ) {
          const asMatched = new AsMatchedParty();
          const phoneParts = PartiesTransformer.parsePhoneParts(party.phone);

          asMatched.typeCd = Party.toMatchedPartyTypeCd(party.partyTypeId, debtorType);
          asMatched.cisCustNbr = party.customerNumber;
          asMatched.matchedStatusCd = party.matchedStatusCd;
          asMatched.matchedSourceCd = party.matchedSourceCd;
          asMatched.asMatchedMadCd = party.madCode;
          asMatched.name1 = party.name1;
          asMatched.name2 = party.name2 || undefined;
          asMatched.address = party.address;
          asMatched.city = party.city;
          asMatched.stateCd = party.state.toUpperCase();
          asMatched.countryCd = CountryTransformer.toUpsertShipment(this.fetchPartyCountry(party, asEnteredParties));
          asMatched.zip6 = party.zip ? party.zip.trim() : party.zip;
          asMatched.zip4RestUs = party.zip4 ? party.zip4.trim() : party.zip4;
          asMatched.sequenceNbr = `${party.sequenceNbr}`;
          asMatched.listActionCd = party.actionCd;
          asMatched.phoneAreaCdNbr = phoneParts.areaCode;
          asMatched.phoneNbr = phoneParts.phone;
          asMatched.phoneExtensionNbr = phoneParts.extension || party.extension;

          return asMatched;
        }
      }).filter(v => !!v);

      this.setSelfInvoicing(parties, debtorType, asMatchedParties);

      return parties;
    } else {
      throw new Error(`AsMatchedParties cannot be undefined`);
    }
  }

  static fromAsEnteredBolParty(asEnteredParties: AsEnteredBolParty[], origSic: string, destSic: string): Party[] {
    const parties: Party[] = [new Party(PARTY_TYPE_ID.Shipper), new Party(PARTY_TYPE_ID.Consignee), new Party(PARTY_TYPE_ID.BillTo)];
    if (asEnteredParties) {
      asEnteredParties.forEach(party => {
        const partyIndex: number = Party.toPartyTypeId(party.partyTypeCd);

        if (partyIndex !== -1 && partyIndex < parties.length) { // Ensure that index is in the bounds of uiAsEnteredParties
          parties[partyIndex] = new Party(partyIndex);
          const parsedPhone = PartiesTransformer.parsePhoneNumberAndExtension(party.phoneNbr);

          parties[partyIndex].phone = parsedPhone.phone;
          parties[partyIndex].extension = parsedPhone.extension;
          parties[partyIndex].address = party.address;
          parties[partyIndex].city = party.city;
          parties[partyIndex].country = CountryTransformer.toBill(party.countryCd);
          parties[partyIndex].name1 = party.name1;
          parties[partyIndex].name2 = party.name2;
          parties[partyIndex].sicCd = this.getSic(partyIndex, origSic, destSic);
          parties[partyIndex].state = party.stateCd;
          parties[partyIndex].zip = party.zip6;
          parties[partyIndex].zip4 = party.zip4RestUs;
          parties[partyIndex].actionCd = party.listActionCd;
        }
      });
    }

    return parties;
  }

  static fromAsMatchedParty(asMatchedParty: AsMatchedParty[], origSic: string = '', destSic: string = ''): Party[] {
    const parties: Party[] = [new Party(PARTY_TYPE_ID.Shipper), new Party(PARTY_TYPE_ID.Consignee), new Party(PARTY_TYPE_ID.BillTo)];

    if (asMatchedParty) {
      asMatchedParty.forEach(party => {
        const partyIndex: number = Party.toPartyTypeId(party.typeCd);

        if (partyIndex !== -1 && partyIndex < parties.length) { // Ensure that index is in the bounds of uiAsMatchedParties
          parties[partyIndex] = new Party(partyIndex);
          const parsedPhone = PartiesTransformer.parsePhoneNumberAndExtension(party.phoneNbr);

          parties[partyIndex].address = party.address;
          parties[partyIndex].city = party.city;
          parties[partyIndex].country = CountryTransformer.toBill(party.countryCd);
          parties[partyIndex].customerNumber = party.cisCustNbr;
          parties[partyIndex].madCode = party.asMatchedMadCd;
          parties[partyIndex].name1 = party.name1;
          parties[partyIndex].name2 = party.name2;
          parties[partyIndex].sicCd = this.getSic(partyIndex, origSic, destSic);
          parties[partyIndex].phone = parsedPhone.phone;
          parties[partyIndex].extension = parsedPhone.extension;
          parties[partyIndex].state = party.stateCd;
          parties[partyIndex].zip = party.zip6;
          parties[partyIndex].zip4 = party.zip4RestUs;
          parties[partyIndex].matchedStatusCd = MatchedPartyStatusCdHelper.toEnum(party.matchedStatusCd);
          parties[partyIndex].matchedSourceCd = MatchedPartySourceCdHelper.toEnum(party.matchedSourceCd);
          parties[partyIndex].actionCd = party.listActionCd;
          parties[partyIndex].sequenceNbr = Number.parseInt(party.sequenceNbr);
          parties[partyIndex].selfInvoiceInd = party.selfInvoiceInd;
        }
      });
    }

    return parties;
  }

  private static setSelfInvoicing(asMatchedParty: AsMatchedParty[], debtorType: ChargeToCd, uiAsMatchedParties: Party[]): void {
    if (
      uiAsMatchedParties
      && uiAsMatchedParties[PARTY_TYPE_ID.BillTo]
      && uiAsMatchedParties[PARTY_TYPE_ID.BillTo].matchedStatusCd === MatchedPartyStatusCd.NO_DERIVED_BILL_TO_SELF_INV
    ) {
      asMatchedParty[
        debtorType === ChargeToCd.COLL
          ? PARTY_TYPE_ID.Consignee
          : PARTY_TYPE_ID.Shipper
      ]
      .selfInvoiceInd = true;
    }
  }

  /**
   * Parses phone numbers from 1111111, 1111111111, 1111111X11111, 11111111111X11111 formats
   *
   * @param {string} input
   * @returns {{phone: string; extension: string}}
   */
  private static parsePhoneNumberAndExtension(input: string): {phone: string, extension: string} {
    const parsedInfo = {phone: undefined, extension: undefined};

    if (input) {
      const splitInfo = input.toUpperCase().split('X');
      parsedInfo.phone = splitInfo[0];

      if (splitInfo.length > 1) {
        parsedInfo.extension = splitInfo[1];
      }
    }

    return parsedInfo;
  }


  public static parsePhoneParts(input: string): { areaCode: string, phone: string, extension: string } {
    const parsedPhoneParts = {areaCode: undefined, phone: undefined, extension: undefined};
    if (input) {
      const extension = input.toUpperCase().split('X');

      if (extension) {
        if (extension.length > 0) {
          const phone = extension[0].replace(/[^\d]/g, '');

          if (phone.length > 7 ) {
            parsedPhoneParts.areaCode = phone.substring(0, 3);
            parsedPhoneParts.phone = phone.substring(3);
          } else {
            parsedPhoneParts.phone = phone;
          }
        }

        if (extension.length > 1) {
          parsedPhoneParts.extension = extension[1];
        }
      }
    }

    return parsedPhoneParts;
  }

  /**
   *  If no country code then look at party and see if it has one,  if so use it
   *  if not it's blank
   *
   * @param countryCode
   * @param aParty
   * @returns {any}
   */
  private static fetchPartyCountry(aParty: Party, asEnteredParties: Party[]): Country {
    let country = aParty.country;

    if (!country) {
      const foundParty = asEnteredParties.find(p => p.partyTypeId === aParty.partyTypeId);

      if (foundParty) {
        country = foundParty.country;
      }
    }

    return country;
  }

  /**
   * If shipper return origin sic, if consignee return destinationSic, else return undefined
   *
   * @private
   * @static
   * @param {PARTY_TYPE_ID} partyId
   * @param {string} origSic
   * @param {string} destSic
   * @returns {string}
   * @memberof PartiesTransformer
   */
  private static getSic(partyId: PARTY_TYPE_ID, origSic: string, destSic: string): string | undefined {
    return partyId === PARTY_TYPE_ID.Shipper
      ? origSic
      : partyId === PARTY_TYPE_ID.Consignee
        ? destSic
        : undefined;
  }
}
