import { Pipe, PipeTransform } from '@angular/core';
import { BillClassCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'billClassCd'
})
export class BillClassCdPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let displayValue = '';
    switch (value) {
      case BillClassCd.CO_BUS_SHPMT:
        displayValue = 'COBZ';
        break;
      case BillClassCd.GENERAL_CLAIMS_BUS_SHPMT:
        displayValue = 'GCBZ';
        break;
      case BillClassCd.ASTRAY_FRT_SEGMENT:
        displayValue = 'MOVR';
        break;
      case BillClassCd.PARTIAL_SEGMENT:
        displayValue = 'PSEG';
        break;
      case BillClassCd.MASTER_SHPMT:
        displayValue = 'MSTR';
        break;
      case BillClassCd.MASTER_SEGMENT:
        displayValue = 'MSEG';
        break;
      case BillClassCd.NORMAL_MVMT:
        displayValue = 'NORM';
        break;
      case BillClassCd.CLAIMS_OVRG_RPTG_BILL:
        displayValue = 'OREP';
        break;
      case BillClassCd.EXPEDITE:
        displayValue = 'EXPD';
        break;
      case BillClassCd.SPLIT_SEGMENT:
        displayValue = 'PTLT';
        break;
      case BillClassCd.ACCESSORIAL_ONLY:
        displayValue = 'ACCO';
        break;
      default:
        displayValue = value;

    }
    return displayValue;
  }

}
