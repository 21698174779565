import { Pipe, PipeTransform } from '@angular/core';
import { startCase } from 'lodash';


@Pipe({
  name: 'camelCaseToSpaces'
})
export class CamelCaseToSpacesPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (value) {
      return startCase(value);
    }
    return null;
  }
}
