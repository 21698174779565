import { Component, NgZone, OnDestroy, OnInit, Input } from '@angular/core';
import { Subject} from 'rxjs';
import { first, takeUntil} from 'rxjs/operators';
import { NotificationMessage } from '../services/notification/notification-message';
import { NotificationTypeEnum } from '../services/notification/notification-type-enum';
import { NotificationService } from '../services/notification/notification.service';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Component({
  selector: 'xpo-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  message: string;
  show: boolean;

  @Input()
  showProgressSpinner = true;

  private unsubscribe = new Subject<void>();
  // I have removed SimpleSnackBar, XpoSnackBarRef imports form '@xpo/common' because lib is deprecated
  // private snackBarRef: XpoSnackBarRef<SimpleSnackBar>;

  constructor(private notificationService: NotificationService, private zone: NgZone, private snackBar: XpoSnackBar) {}

  ngOnInit() {
    this.notificationService
      .getSubscriber()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((message: NotificationMessage) => {
        try {
          this.zone.run(() => {
            if (message.type !== NotificationTypeEnum.ShowSnackbar) {
              this.message = message.text;
              this.show = message.type === NotificationTypeEnum.ShowOverlay;
            } else {
              // this.snackBarRef = this.snackBar.open(
              //   message.text,
              //   message.snackbarConfig != null &&
              //     message.snackbarConfig.actionHandler != null &&
              //     message.snackbarConfig.actionHandler.actionLabel() != null
              //     ? message.snackbarConfig.actionHandler.actionLabel()
              //     : null,
              //   {
              //     duration: message.snackbarConfig.durationInMillis,
              //     extraClasses: ['xpo-simple-snackbar'],
              //     status: message.snackbarConfig.status === 'error' ? 'error' : 'success',
              //   }
              // );
              // if (message.snackbarConfig != null && message.snackbarConfig.actionHandler != null) {
              //   if (message.snackbarConfig.actionHandler.onAction != null) {
              //     this.snackBarRef
              //       .onAction().pipe(first())
              //       .subscribe(message.snackbarConfig.actionHandler.onAction);
              //   }
              //   if (message.snackbarConfig.actionHandler.afterOpened != null) {
              //     this.snackBarRef
              //       .afterOpened()
              //       .pipe(first())
              //       .subscribe(message.snackbarConfig.actionHandler.afterOpened);
              //   }
              //   if (message.snackbarConfig.actionHandler.afterDismissed != null) {
              //     this.snackBarRef
              //       .afterDismissed()
              //       .pipe(first())
              //       .subscribe(message.snackbarConfig.actionHandler.afterDismissed);
              //   }
              // }
            }
          });
        } catch (error) {
          // todo: log
        }
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
