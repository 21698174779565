export enum BillFormFields {
  MatchingPerformed = 'matchingPerformed',
  AsMatchedParties = 'asMatchedParties',
  AsEnteredBolParties = 'asEnteredBolParties',
  OrderHeader = 'orderHeader',
  OrderDetails = 'orderDetails',
  Remarks = 'remarks',
  Parties = 'parties',
  BillingInstructions = 'billingInstructions',
  SupplementalReferenceNumberCollection = 'supplementalReferenceNumberCollection',
  Accessorials = 'accessorials',
  SpecialInstructions = 'specialInstructions',
  TimeDateCritical = 'timeDateCritical',
  AdditionalInformation = 'additionalInformation',
  IsFromCache = 'isFromCache',
  IsSrnCorrection = 'isSrnCorrection'

}
