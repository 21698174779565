import { Pipe, PipeTransform } from '@angular/core';
import { BolPartyTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'bolPartyTypeCd'
})
export class BolPartyTypeCdPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let displayValue = '';
    switch (value) {
      case BolPartyTypeCd.SHPR:
        displayValue = 'Shipper';
        break;
      case BolPartyTypeCd.CONS:
        displayValue = 'Consignee';
        break;
      case BolPartyTypeCd.BILL_TO:
        displayValue = 'Bill To';
        break;
      default:
        displayValue = value;
    }
    return displayValue;
  }

}
