export class SpecialInstructions {
  static guaranteedCodes: string[] = ['GUR', 'GFR'];
  static g12Codes: string[] = ['G12'];
  static rapidRemoteServiceCodes: string[] = ['RRS'];
  static freezableIndCodes: string[] = ['XFZ'];

  afterHoursPickUp = false;
  codInd = false;
  excessiveValueInd = false;
  exclusiveUseInd = false;
  freezableInd = false;
  guaranteedInd = false;
  hazmatInd = false;
  mexicoDoorToDoorInd = false;
  rapidRemoteServiceInd = false;
  g12Ind = false;
}
