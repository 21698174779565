<xpo-card class="confirm-salvage-dialog">
  <xpo-card-header>
    <xpo-card-title class="confirm-salvage-dialog__title">
      {{ title }}
    </xpo-card-title>
  </xpo-card-header>
  <xpo-card-content class="confirm-salvage-dialog__message">
    <p [innerHTML]="message"></p>
    <div class="confirm-salvage-dialog__message__parties">
      <div *ngFor="let party of parties; let i = index">
        <p class="text-bold">{{ i === 0 ? 'You Entered' : 'Salvage Location Address' }}</p>
        <p>{{ party.name1 }}</p>
        <p>{{ party.address }}</p>
        <p>{{ party.city }} {{ party.state }}</p>
        <p>{{ party.zipJoined }}</p>
      </div>
    </div>
  </xpo-card-content>
  <xpo-card-actions>
    <div class="confirm-salvage-dialog__positive-button-group">
      <button
        #salvageAction
        *ngFor="let button of buttons"
        mat-flat-button xpoButton xpoSmallButton
        [attr.autofocus]="button.autofocus"
        (click)="handleClick(button.key)"
      >
        {{ button.displayText }}
      </button>
    </div>
  </xpo-card-actions>
</xpo-card>
