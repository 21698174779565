import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';
import { detect } from 'detect-browser';

import { ChromeVersionGuardDialogComponent } from './chrome-version-guard-dialog.component';
import { MatDialog } from '@angular/material/dialog';

export function ChromeVersionGuard(): CanActivateFn {
  return () => {
    const minChromeVersion = 62;
    const dialog = inject(MatDialog);

    const browserInfo = detect();

    if (
      browserInfo.name &&
      browserInfo.name.toUpperCase() === 'CHROME' &&
      !(Number.parseInt(browserInfo.version.split('.')[0]) >= minChromeVersion)
    ) {
      dialog.open(ChromeVersionGuardDialogComponent, {
        disableClose:true,
        data: {
          currentVersion: browserInfo.version,
          minVersion: minChromeVersion,
        },
      });
      return false;
    }
    return true;
  }
  
}
