import { AdditionalInformationFormFields } from './additional-information-form-fields.enum';

export class AdditionalInformation {
  // Blind Shipment
  isTrueBlind = false;
  isBlind = false;
  shipperName: string = undefined;
  shipperAddress: string = undefined;
  shipperCity: string = undefined;
  shipperState: string = undefined;
  shipperZip: string = undefined;
  shipperZip4: string = undefined;
  shipperCountryCd: string = undefined;

  // COD
  codAmount: number = undefined;
  codPaymentTypeCode: string = undefined; // TODO
  codSequenceNbr: number = undefined;

  partPrepaidAmount = 0;
  partPrepaidRemarks: string = undefined;
  partPrepaidSequenceNbr: number = undefined;

  partCollectAmount = 0;
  partCollectRemarks: string = undefined;
  partCollectSequenceNbr: number = undefined;

  // Driver Collect
  driverCash: number = undefined;
  checkNumber: string = undefined;
  driverCashSequenceNbr: number = undefined;

  // Documents
  hasCustomsInvoiceDocument = false;
  hasPackingListDocument = false;
  hasSupplementalPagesDocument = false;
  hasCBPInbondDocument = false;
  // Inbond
  inbondNumber: string = undefined;
  inbondStateCode: string = undefined; // TODO;
  inbondCity: string = undefined;
  inbondSequenceNbr: number = undefined;
  // Other
  linealFoot: number = undefined;
  declaredValueAmount: number = undefined;
  // Advanced/Beyond
  advancedCarrierSCAC: string = undefined;
  advancedCarrierPickupDate: Date = undefined;
  advancedCarrierTrackingNumber: string = undefined;
  advancedCarrierSequenceNbr: number = undefined;

  beyondCarrierSCAC: string = undefined;
  beyondCarrierPickupDate: Date = undefined;
  beyondCarrierTrackingNumber: string = undefined;
  beyondCarrierSequenceNbr: number = undefined;

  constructor() {}

  /**
   * Returns keys for advancedCarrier options
   */
  static get advancedCarrierKeys(): string[] {
    return [
      AdditionalInformationFormFields.AdvancedCarrierPickupDate,
      AdditionalInformationFormFields.AdvancedCarrierSCAC,
      AdditionalInformationFormFields.AdvancedCarrierTrackingNumber,
    ];
  }

  /**
   * Returns keys for beyondCarrier options
   */
  static get beyondCarrierKeys(): string[] {
    return [
      AdditionalInformationFormFields.BeyondCarrierPickupDate,
      AdditionalInformationFormFields.BeyondCarrierSCAC,
      AdditionalInformationFormFields.BeyondCarrierTrackingNumber,
    ];
  }

  static get codAmountKeys(): string[] {
    return [
      AdditionalInformationFormFields.CodAmount,
      AdditionalInformationFormFields.CodPaymentTypeCode,
      AdditionalInformationFormFields.PartPrepaidAmount,
      AdditionalInformationFormFields.PartCollectAmount,
      AdditionalInformationFormFields.PartPrepaidRemarks,
      AdditionalInformationFormFields.PartCollectRemarks,
    ];
  }

  static get remarkKeys(): string[] {
    return [AdditionalInformationFormFields.PartPrepaidRemarks, AdditionalInformationFormFields.PartCollectRemarks];
  }

  static get driverCollectKeys(): string[] {
    return [AdditionalInformationFormFields.DriverCash, AdditionalInformationFormFields.CheckNumber];
  }

  /**
   * Returns true if one of the cod fields is populated
   * @param info additional information panel model
   */
  static hasCodValues(info: AdditionalInformation): boolean {
    return !!(info.codAmount || info.codPaymentTypeCode || info.partCollectRemarks || info.partPrepaidRemarks);
  }

  /**
   * Returns true if one of the remarks fields is populated, Ignore codAmt and Type if populated speed -2936
   * @param info additional information panel model
   */
  static hasRemarksValues(info: AdditionalInformation): boolean {
    return !!(!info.codAmount && !info.codPaymentTypeCode && (info.partCollectRemarks || info.partPrepaidRemarks));
  }

  /**
   * Returns true if one of the driver collect is populated
   * @param info additional information panel model
   */
  static hasDriverCollectValues(info: AdditionalInformation): boolean {
    return !!(info.driverCash || info.checkNumber);
  }

  /**
   * Returns true if one of the advanced carrier fields is populated
   * @param info additional information panel model
   */
  static hasAdvancedCarrierValues(info: AdditionalInformation): boolean {
    return (
      !!info.advancedCarrierPickupDate ||
      AdditionalInformation.stringValueExists(info.advancedCarrierSCAC) ||
      AdditionalInformation.stringValueExists(info.advancedCarrierTrackingNumber)
    );
  }

  /**
   * Returns true if one of the beyond carrier fields is populated
   * @param info additional information panel model
   */
  static hasBeyondCarrierValues(info: AdditionalInformation): boolean {
    return (
      !!info.beyondCarrierPickupDate ||
      AdditionalInformation.stringValueExists(info.beyondCarrierSCAC) ||
      AdditionalInformation.stringValueExists(info.beyondCarrierTrackingNumber)
    );
  }

  static stringValueExists(aString: string) {
    return aString && aString.trim().length > 0;
  }
}
