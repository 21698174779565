<div class="header developer-a">
  <!-- Development deployed by A -->
  <div class="header__title-wrapper" routerLink="/">
    <div class="header__app-title">Edge</div>
    <div class="header__app-sub-title">Order Entry</div>
  </div>

  <div class="header__button-wrapper">
    <div *ngFor="let panel of panelService.getPanels() | async; let i = index">
      <button
        class="header__button"
        [class.header__button--selected]="selectedPanelIndex === i"
        (click)="focusPanel(i)"
        [innerHtml]="panel.headerDisplay"
      ></button>
      <span class="header__button__badge" [ngClass]="getClasses(panel)"> </span>
    </div>
  </div>
  <a xpo-regular-link *ngIf="showFeedback" class="header__feedback action" [title]="feedbackEmail" (click)="openFeedbackDialog()">
    Feedback
  </a>
  <div *ngIf="!isProd" class="non-prod-flag">
    <span>Non-Prod</span>
  </div>
</div>
