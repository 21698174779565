import { Component, Inject, ViewEncapsulation, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { Party } from '../../../../bill-entry/bill-entry/model';
import { ConfirmSalvageButtonModel } from './confirm-salvage-button-modal';
import { ConfirmSalvageDataModel } from './confirm-salvage-data-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { XpoButton } from '@xpo-ltl/ngx-ltl-core/button';

@Component({
  selector: 'xpo-confirm-salvage-dialog',
  templateUrl: './confirm-salvage-dialog.component.html',
  styleUrls: ['./confirm-salvage-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmSalvageDialogComponent implements AfterViewInit {
  private _title: string;
  private _message: string;
  private _parties: Party[] = [];
  private _buttons: ConfirmSalvageButtonModel[] = [];

  @ViewChildren('salvageAction') salvageActions: QueryList<XpoButton>;
  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: ConfirmSalvageDataModel,
    private dialogRef: MatDialogRef<ConfirmSalvageDialogComponent>
  ) {
    this._title = dialogData.title;
    this._message = dialogData.message;

    if (!!dialogData.parties) {
      dialogData.parties.forEach((option) => {
        this._parties.push(option.party);
        this._buttons.push(option.button);
      });
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.salvageActions.forEach((action) => {
        const nativeElement = action['_elementRef'].nativeElement;
        if (nativeElement.autofocus) {
          // action.focus();
        }
      });
    }, 500);
  }

  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get parties(): Party[] {
    return this._parties;
  }

  get buttons(): ConfirmSalvageButtonModel[] {
    return this._buttons;
  }

  handleClick(key: string) {
    this.dialogRef.close(key);
  }
}
