<div class="form-footer">
  <button
    xpoButton
    *ngFor="let button of secondaryButtons"
    class="bill-button bill-button__alt-primary"
    [class.hidden]="button.hide"
    [disabled]="button.disabled"
    (click)="button.action(button)"
    [innerHtml]="button.displayValue"
    (window:keydown)="button.handleKeyDown($event)"
  ></button>
  <div class="xpo-flex-stretch" class="form-footer__version">
    Version: {{ buildVersion }} - {{ browserInfo.name }}:
    {{ browserInfo.version }}
  </div>
  <button
    xpoButton
    *ngFor="let button of primaryButtons"
    class="bill-button bill-button__primary"
    [class.hidden]="button.hide"
    disabled
    [disabled]="button.disabled"
    (click)="button.action(button)"
    [innerHtml]="button.displayValue"
    (window:keydown)="button.handleKeyDown($event)"
  ></button>
</div>
