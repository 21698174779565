import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment-timezone';

@Pipe({
  name: 'utcFormat'
})
export class UtcDateFormatPipe implements PipeTransform {

  transform(value: Date): any {

   if (!value) {
     return '';
   }

   return moment(value).tz('UTC').format('MM/DD/YYYY');
  }
}
