import { LateTenderCd } from '@xpo-ltl/sdk-common';
import { UpsertShipmentRqst, GetShipmentResp } from '@xpo-ltl/sdk-shipment';
import { SpecialInstructions } from '../../../../bill-entry/model/special-instructions';
export class SpecialInstructionsTransformer {

  static toBill(resp: GetShipmentResp): SpecialInstructions {
    const specialInstructions = new SpecialInstructions();

    specialInstructions.afterHoursPickUp = resp.shipment.lateTenderCd === LateTenderCd.LATE_TENDER;
    specialInstructions.exclusiveUseInd = resp.shipment.exclusiveUseInd;
    specialInstructions.freezableInd = resp.shipment.freezableInd;
    specialInstructions.hazmatInd = resp.shipment.hazmatInd || SpecialInstructionsTransformer.hasHazmatCommodities(resp);
    specialInstructions.mexicoDoorToDoorInd = resp.shipment.mexicoDoorToDoorInd;
    specialInstructions.excessiveValueInd = resp.shipment.excessiveValueInd;
    specialInstructions.guaranteedInd = resp.shipment.guaranteedInd;
    specialInstructions.codInd = resp.shipment.codInd;
    specialInstructions.rapidRemoteServiceInd = (resp.shipment.serviceTypeCd === '3' &&
      !resp.shipment.exclusiveUseInd && !resp.shipment.hazmatInd && !resp.shipment.freezableInd);
    specialInstructions.g12Ind = (resp.shipment.serviceTypeCd === '4' &&
      !resp.shipment.exclusiveUseInd && !resp.shipment.hazmatInd && !resp.shipment.freezableInd && !resp.shipment.guaranteedInd);
    return specialInstructions;
  }

  static toUpsertShipmentRqst(specialInstructions: SpecialInstructions, rqst: UpsertShipmentRqst): void {
    if (specialInstructions) {
      rqst.shipment.lateTenderCd = specialInstructions.afterHoursPickUp ? LateTenderCd.LATE_TENDER : LateTenderCd.NOT_A_LATE_TENDER;
      rqst.shipment.exclusiveUseInd = specialInstructions.exclusiveUseInd;
      rqst.shipment.freezableInd = specialInstructions.freezableInd;
      rqst.shipment.guaranteedInd = specialInstructions.guaranteedInd;
      rqst.shipment.hazmatInd = specialInstructions.hazmatInd;
      rqst.shipment.mexicoDoorToDoorInd = specialInstructions.mexicoDoorToDoorInd;
      rqst.shipment.excessiveValueInd = specialInstructions.excessiveValueInd;
      rqst.shipment.codInd = specialInstructions.codInd;
      if (specialInstructions.g12Ind === true) {
        rqst.shipment.serviceTypeCd = '4';
      } else if (specialInstructions.rapidRemoteServiceInd === true) {
        rqst.shipment.serviceTypeCd = '3';
      } else {
        rqst.shipment.serviceTypeCd = '1';
      }
    }
  }

  private static hasHazmatCommodities(resp: GetShipmentResp): boolean {
    return resp && resp.commodity ? resp.commodity.some(v => v.hazardousMtInd) : false;
  }
}
