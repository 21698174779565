import { Injectable } from '@angular/core';
import { Envelope } from '@xpo-ltl/api-common';
import { SendEmailRqst } from '@xpo-ltl/api-infrastructure';
import { BaseService } from '../base-service';
import { DataApiService } from '../data-api/data-api.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

@Injectable()
export class InfrastructureApiService extends BaseService {
  constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
    super();
  }

  /**
   * This service will send an email message based on the request message. The request message
   * must contain at least one email address in 'to' list and 'from' email address and 'from name'.
   *
   * Pre Condition: A valid email address is passed in the 'to' list and 'from' email address and
   * 'from name' is provided along with message subject and text.
   *
   * Post Condition: The service will send an email message to the email address mentioned in the 'to' list.
   *
   * @param {SendEmailRqst} request
   * @returns {Promise<any>}
   * @memberof InfrastructureApiService
   */
  sendEmail(request: SendEmailRqst): Promise<any> {
    return new Promise((resolve, reject) => {
      this.dataApiService
        .post(this.getUrl('messages/email'), request, null, this._dataOptions)
        .then(
          (response: Envelope) => {
            resolve(response);
          },
          error => {
            reject(error);
          }
        );
    });
  }

  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.infrastructureEndpoint);
  }
}


