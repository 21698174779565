import { Injectable } from '@angular/core';
import { BaseService } from '../base-service';
import { DataApiService } from '../data-api/data-api.service';

import { Envelope } from '@xpo-ltl/api-common';
import { GetEmployeeDetailsByEmpIdResp, GetEmployeeDetailsByEmpIdRqst } from '@xpo-ltl/sdk-humanresource';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

@Injectable()
export class HumanResourceApiService extends BaseService {
  constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
    super();
  }

  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.humanResourceApiEndpoint);
  }

  getEmployeeDetailsByEmpId(request: GetEmployeeDetailsByEmpIdRqst): Promise<GetEmployeeDetailsByEmpIdResp> {
    if (!request) {
      throw new Error('EmployeeDetails request Required');
    }
    if (request && !request.employeeId) {
      throw new Error('Request EmployeeId is Required');
    }
    let queryParams = {};
    queryParams = { requestType: request.requestType };

    return new Promise((resolve, reject) => {
      this.dataApiService
        .get(this.getUrl(`/employees/${request.employeeId}`), queryParams, this._dataOptions, null)
        .then(
          (response: Envelope) => {
            resolve(response['data'] as GetEmployeeDetailsByEmpIdResp);
          },
          (error) => {
            reject(error);
          }
        );
    });
  }
}
