import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ApplicationMinimumVersionGuard } from './core/guards/application-minimum-version/application-minimum-version.guard';
import { VersionMismatchGuard } from './core/guards/application-minimum-version/version-mismatch.guard';
import { ChromeVersionGuard } from './core/guards/chrome-version-guard/chrome-version-guard.service';
import { DocumentResolver } from './shared/resolvers/document-resolver';
import { LoggedInUserResolver } from './shared/resolvers/logged-in-user-resolver';
import { XpoAuthenticationGuard } from '@xpo/ngx-auth';

const appRoutes: Routes = [
  {
    path: 'ebol-search',
    loadChildren: () => import('app/bill-entry/search/search.module').then(m => m.SearchModule),
    canActivate: [XpoAuthenticationGuard, ChromeVersionGuard(), ApplicationMinimumVersionGuard()],
  },
  {
    path: 'bill-entry',
    loadChildren: () => import('app/bill-entry/bill-entry.module').then(m => m.BillEntryModule),
    canActivate: [XpoAuthenticationGuard, ChromeVersionGuard(), ApplicationMinimumVersionGuard()],
    resolve: {
      documentResolverPackage: DocumentResolver,
    },
  },
  {
    path: 'document-view',
    loadChildren: () => import('app/bill-entry/document-view/document-view.module').then(m => m.DocumentViewModule),
    canActivate: [ChromeVersionGuard(), ApplicationMinimumVersionGuard()],
  },
  {
    path: 'version-mismatch',
    loadChildren: () => import('app/bill-entry/version-mismatch/version-mismatch.module').then(m => m.VersionMismatchModule),
  },
  {
    path: 'not-authorized',
    loadChildren: () => import('app/bill-entry/unauthorized/unauthorized.module').then(m => m.UnauthorizedModule),
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/ebol-search',
  },
  {
    // route anything we don't understand to the ebol-search
    path:'**',
    redirectTo: 'ebol-search',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
