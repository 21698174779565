export enum TimeDateCriticalFormFields {
  DateTypeCode = 'dateTypeCode',
  Date1 = 'date1',
  Date2 = 'date2',
  TimeTypeCode = 'timeTypeCode',
  Time1 = 'time1',
  Time2 = 'time2',
  EstimatedDeliveryDate = 'estimatedDeliveryDate',
  NotifyOnArrival = 'notifyOnArrival',
  AppointmentRequired = 'appointmentRequired',
  TDC = 'tdc',
}
