import { DateTimeHelper } from '../../../util/date-time-helper';
import { BaseValidation } from './base-validation';
import { Severity } from './severity.enum';

export class DateRangeValidator extends BaseValidation<Date> {
  private _daysBeforeToday;
  private _daysAfterToday;

  constructor(
    daysBeforeToday: number,
    daysAfterToday: number,
    errorCode?: string,
    errorDescription?: string,
    fieldName?: string,
    severity?: Severity
  ) {
    super(errorCode, errorDescription, fieldName, severity);
    this._daysAfterToday = daysAfterToday;
    this._daysBeforeToday = daysBeforeToday;
  }

  isValid(value: Date): boolean {
    if (!value) {
      return false;
    }

    const fromCompareDate = DateTimeHelper.conditionDateToServer(new Date());
    const toCompareDate = DateTimeHelper.conditionDateToServer(new Date());

    fromCompareDate.setUTCDate(fromCompareDate.getUTCDate() - this._daysBeforeToday);
    toCompareDate.setUTCDate(toCompareDate.getUTCDate() + this._daysAfterToday);
    return value >= fromCompareDate && value <= toCompareDate;
  }
}
