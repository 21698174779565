import {
  BolPartyTypeCd,
  ChargeToCd,
  MatchedPartySourceCd,
  MatchedPartyStatusCd,
  MatchedPartyTypeCd,
} from '@xpo-ltl/sdk-common';
import { isNullOrUndefined } from '../../../shared/services/tools/tools.service';
import { Lookup } from '../../../shared/model/lookup';
import { Actionable } from './actionable';
import { Country } from './country';
export enum PARTY_TYPE_ID {
  Shipper = 0,
  Consignee = 1,
  BillTo = 2,
}

export class Party extends Actionable {
  static readonly MAX_CITY_LENGTH = 20;
  static readonly MAX_ADDRESS_LENGTH = 30;
  static readonly MAX_NAME_LENGTH = 30;

  partyTypeId: PARTY_TYPE_ID = null;
  partyTypeName: string = null;
  name1: string = null;
  name2: string = null;
  address: string = null;
  city: string = null;
  state: string = null;
  zip: string = null;
  zip4: string = null;
  /**
   * used for UI input which splits to zip + zip4
   */
  zipJoined: string = null;
  phone: string = null;
  extension: string = null;
  country: Country = null;
  customerNumber: number = null;
  sicCd: string = null;
  matchedStatusCd: MatchedPartyStatusCd = null;
  matchedSourceCd: MatchedPartySourceCd = null;
  madCode: string = null;
  hardErrors: any[] = null;
  selfInvoiceInd: boolean;

  private static equals(input1: any, input2: any): boolean {
    if (!input1 && !input2) {
      return true;
    }

    return (
      !isNullOrUndefined(input1) &&
      !isNullOrUndefined(input2) &&
      input1.toString().toUpperCase() === input2.toString().toUpperCase()
    );
  }
  private static countryEquals(input1: Country, input2: Country): boolean {
    if (!input1 && !input2) {
      return true;
    }
    return Party.equals(input1 ? input1.name : '', input2 ? input2.name : '');
  }
  static hasSameAddress(party1: Party, party2: Party): boolean {
    if (isNullOrUndefined(party1) || isNullOrUndefined(party2)) {
      return false;
    }

    // if both objects are empty consider them not equals since they have not had any values set.
    // skip the country since it is defaulted to US
    if (
      Party.emptyValue(party1.address) &&
      Party.emptyValue(party2.address) &&
      Party.emptyValue(party1.city) &&
      Party.emptyValue(party2.city) &&
      Party.emptyValue(party1.state) &&
      Party.emptyValue(party2.state) &&
      Party.emptyValue(party1.zip) &&
      Party.emptyValue(party2.zip) &&
      Party.emptyValue(party1.zip4) &&
      Party.emptyValue(party2.zip4)
    ) {
      return false;
    }

    return (
      Party.equals(party1.address, party2.address) &&
      Party.equals(party1.city, party2.city) &&
      Party.equals(party1.state, party2.state) &&
      Party.countryEquals(party1.country, party2.country) &&
      Party.equals(party1.zip, party2.zip) &&
      Party.equals(party1.zip4, party2.zip4)
    );
  }
  private static emptyValue(input: string): boolean {
    return !input || input.length === 0;
  }
  static toBolPartyTypeCd(partyTypeId: PARTY_TYPE_ID): BolPartyTypeCd {
    let value: BolPartyTypeCd;

    switch (partyTypeId) {
      case PARTY_TYPE_ID.Shipper:
        value = BolPartyTypeCd.SHPR;
        break;
      case PARTY_TYPE_ID.Consignee:
        value = BolPartyTypeCd.CONS;
        break;
      case PARTY_TYPE_ID.BillTo:
        value = BolPartyTypeCd.BILL_TO;
        break;
      default:
        value = null;
    }

    return value;
  }

  static toMatchedPartyTypeCd(partyTypeId: PARTY_TYPE_ID, chargeToCode?: ChargeToCd): MatchedPartyTypeCd {
    let value: MatchedPartyTypeCd;

    switch (partyTypeId) {
      case PARTY_TYPE_ID.Shipper:
        value = MatchedPartyTypeCd.SHPR;
        break;
      case PARTY_TYPE_ID.Consignee:
        value = MatchedPartyTypeCd.CONS;
        break;
      case PARTY_TYPE_ID.BillTo:
        if (chargeToCode === ChargeToCd.BOTH || chargeToCode === ChargeToCd.PPD) {
          value = MatchedPartyTypeCd.BILL_TO_OTB;
        } else {
          value = MatchedPartyTypeCd.BILL_TO_INB;
        }
        break;
      default:
        value = null;
    }

    return value;
  }
  static toPartyTypeId(typeCd: string): PARTY_TYPE_ID {
    let value: PARTY_TYPE_ID = -1;
    switch (typeCd.toUpperCase()) {
      case 'SHPR':
        value = PARTY_TYPE_ID.Shipper;
        break;
      case 'CONS':
        value = PARTY_TYPE_ID.Consignee;
        break;
      case 'BILLTO':
      case 'BILLTOINB':
      case 'BILLTOOTB':
        value = PARTY_TYPE_ID.BillTo;
        break;
    }
    return value;
  }

  /**
   * Peforms a juggling check of values for: name1, address, city, state, and zip.
   * If all values are present this method returns true.
   * @returns {boolean}
   */
  static notEmpty(party: Party): boolean {
    return (
      Party.validValue(party.name1) &&
      Party.validValue(party.address) &&
      Party.validValue(party.city) &&
      Party.validValue(party.state) &&
      (Party.validValue(party.zipJoined) || Party.validValue(party.zip))
    );
  }

  /**
   * Peforms a juggling check of values for: name1, address, city, state, and zip.
   * If any required value is present this method returns true.
   * @returns {boolean}
   */
  static hasValue(party: Party): boolean {
    return (
      Party.validValue(party.name1) ||
      Party.validValue(party.address) ||
      Party.validValue(party.city) ||
      Party.validValue(party.state) ||
      Party.validValue(party.zipJoined)
    );
  }

  static empty(party: Party): boolean {
    return !Party.notEmpty(party);
  }

  static compareRequiredValues = (x: Party, y: Party) => {
    return (
      x &&
      y &&
      x.name1 === y.name1 &&
      x.address === y.address &&
      x.city === y.city &&
      x.state === y.state &&
      x.zipJoined === y.zipJoined &&
      ((!x.country && !y.country) || // shipper and consignee have disabled countries, so if available, check if they are the same.
        Lookup.compare(x.country, y.country))
    );
  };

  /**
   * Checks if value is truthy
   * @param value
   */
  private static validValue(value: string) {
    if (!value) {
      return false;
    }
    return `${value}`.trim().length > 0;
  }

  static getCombinedZipComponents(party: Party): string {
    let returnValue = '';

    if (party.zip) {
      returnValue += party.zip;
    }
    if (party.zip4) {
      if (returnValue) {
        returnValue += '-';
      }
      returnValue += party.zip4;
    }

    return returnValue;
  }

  constructor(partyTypeId: PARTY_TYPE_ID) {
    super();
    this.partyTypeId = partyTypeId;
    this.partyTypeName = this.getPartyTypeName(partyTypeId);
    this.country = Country.find('US');
  }

  getPartyTypeName(partyTypeId: PARTY_TYPE_ID): string {
    let v: string;

    switch (partyTypeId) {
      case PARTY_TYPE_ID.Shipper:
        v = 'Shipper';
        break;
      case PARTY_TYPE_ID.Consignee:
        v = 'Consignee';
        break;
      case PARTY_TYPE_ID.BillTo:
        v = 'Bill To';
        break;
      default:
        v = 'N/A';
    }

    return v;
  }
}

export enum CMKModalOptions {
  MatchAnyway = 'MATCH_ANYWAY',
  UseCmk = 'USE_CMK',
  Cancel = 'CANCEL',
}
