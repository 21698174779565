export enum SpecialInstructionsFormFields {
  AfterHoursPickUp = 'afterHoursPickUp',
  CodInd = 'codInd',
  ExcessiveValueInd = 'excessiveValueInd',
  ExclusiveUseInd = 'exclusiveUseInd',
  FreezableInd = 'freezableInd',
  GuaranteedInd = 'guaranteedInd',
  HazmatInd = 'hazmatInd',
  MexicoDoorToDoorInd = 'mexicoDoorToDoorInd',
  RapidRemoteServiceInd = 'rapidRemoteServiceInd',
  G12Ind = 'g12Ind'
}
