import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { inspect } from 'util';
import { take } from 'rxjs/operators';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';

@Injectable()
export class GlobalErrorHandlingLogger extends ErrorHandler {
  private loggingApiService: LoggingApiService;
  private logErrors = false;

  constructor(private injector: Injector, private configManager: ConfigManagerService) {
    super();
    this.logErrors = this.configManager.getSetting(ConfigManagerProperties.logUnhandledErrors);
  }

  public override handleError(error) {
    let logMessage: string;

    if (!this.loggingApiService) {
      this.loggingApiService = this.injector.get<LoggingApiService>(LoggingApiService);
    }

    if (this.logErrors && this.loggingApiService) {
      try {
        logMessage = inspect(error);
      } catch (err) {
        if (error['rejection'] && error['rejection']['stack']) {
          logMessage = error.rejection.stack;
        } else {
          logMessage = 'Unknown unhandled error occurred!';
        }
      }

      this.loggingApiService.fatal(logMessage);
      super.handleError(error);
    }
  }
}
