import { ExplosiveClassification } from './explosive-classification';
import { MarinePollutantClassification } from './marine-pollutant-classification';
import { RadioactiveClassification } from './radioactive-classification';
import { UnNaNumber } from './unna-number';

export interface HazmatDetails {
  unNaNumber: UnNaNumber;
  technicalName: string;
  dotPermit: string;
  isResidue: boolean;
  isLimitedQty: boolean;
  isReportableQty: boolean;
  marinePollutantClassification: MarinePollutantClassification;
  radioactiveClassification: RadioactiveClassification;
  explosiveClassification: ExplosiveClassification;
  hazmatDescription: string;
  hazmatDescriptionEdited: boolean;
}

export enum HazmatDetailsFormFields {
  UnNaNumber = 'unNaNumber',
  TechnicalName = 'technicalName',
  DotPermit = 'dotPermit',
  IsResidue = 'isResidue',
  IsLimitedQty = 'isLimitedQty',
  IsReportableQty = 'isReportableQty',
  MarinePollutantClassification = 'marinePollutantClassification',
  RadioactiveClassification = 'radioactiveClassification',
  ExplosiveClassification = 'explosiveClassification',
  HazmatDescription = 'hazmatDescription',
  HazmatDescriptionEdited = 'hazmatDescriptionEdited',
}
