import moment from 'moment-timezone';

export function DateTimeReviver(key: any, value: any): any {
  if (typeof value === 'string') {
    if (/[0-9]{4}[-][0-9]{1,2}[-][0-9]{1,2}[T]{1}[0-9]{1,2}[:][0-9]{1,2}[:][0-9]{1,2}[.][0-9]{1,3}[Z]/.test(value)) {
      return moment.utc(value).toDate();
    }
  }
  return value;
}
