import {
  ActionCd,
  AdvanceBeyondTypeCd,
  BolPartyTypeCd,
  MiscLineItemCd,
  MiscLineItemPaymentMethodCdHelper,
} from '@xpo-ltl/sdk-common';
import {
  AdvanceBeyondCarrier,
  AsEnteredBolParty,
  BolAttachment,
  CustomsBond,
  GetShipmentResp,
  MiscLineItem,
  Shipment,
  UpsertShipmentRqst,
} from '@xpo-ltl/sdk-shipment';
import { startCase } from 'lodash';
import { isNullOrUndefined } from '../../../../../shared/services/tools/tools.service';
import { AdditionalInformation } from '../../../../bill-entry/model/additional-information';
import { DateTimeHelper } from '../../../util/date-time-helper';

export class AdditionalInformationTransformer {
  constructor() {}

  /**
   * Populate UpsertShipmentRequest based on Additional Information
   * @param additionalInfo
   * @param rqst
   */
  static toUpsertShipment(additionalInformation: AdditionalInformation, rqst: UpsertShipmentRqst) {
    if (additionalInformation) {
      // Documents
      if (!rqst.bolAttachment) {
        rqst.bolAttachment = new BolAttachment();
      }
      rqst.bolAttachment.custInvoiceInd = additionalInformation.hasCustomsInvoiceDocument;
      rqst.bolAttachment.customsCdpFormInd = additionalInformation.hasCBPInbondDocument;
      rqst.bolAttachment.packingListInd = additionalInformation.hasPackingListDocument;
      rqst.bolAttachment.supplementalPagesInd = additionalInformation.hasSupplementalPagesDocument;

      // true blind
      if (additionalInformation.isTrueBlind || additionalInformation.isBlind) {
        if (!rqst.asEnteredBolParty) {
          rqst.asEnteredBolParty = [];
        }
        const blindParty: AsEnteredBolParty = new AsEnteredBolParty();
        blindParty.partyTypeCd = additionalInformation.isTrueBlind
          ? BolPartyTypeCd.TRUE_BLIND_SHPR
          : BolPartyTypeCd.BLIND_SHPR;
        blindParty.name1 = additionalInformation.shipperName;
        blindParty.address = additionalInformation.shipperAddress;
        blindParty.city = additionalInformation.shipperCity;
        blindParty.stateCd = additionalInformation.shipperState;
        blindParty.zip6 = additionalInformation.shipperZip;
        blindParty.zip4RestUs = additionalInformation.shipperZip4;
        blindParty.countryCd = additionalInformation.shipperCountryCd;
        blindParty.listActionCd = ActionCd.ADD;
        rqst.asEnteredBolParty.push(blindParty);
      }

      // Advanced Carrier
      if (additionalInformation.advancedCarrierSCAC && additionalInformation.advancedCarrierSCAC.trim().length > 0) {
        if (!rqst.advanceBeyondCarrier) {
          rqst.advanceBeyondCarrier = [];
        }
        const advancedCarrier: AdvanceBeyondCarrier = new AdvanceBeyondCarrier();
        advancedCarrier.typeCd = AdvanceBeyondTypeCd.ADV_CARR;
        advancedCarrier.listActionCd = ActionCd.ADD;
        advancedCarrier.sequenceNbr = '-1';
        advancedCarrier.carrierScacCd = additionalInformation.advancedCarrierSCAC;
        advancedCarrier.carrierProNbr = additionalInformation.advancedCarrierTrackingNumber;
        advancedCarrier.carrierPickupDate = DateTimeHelper.conditionDateToServer(
          additionalInformation.advancedCarrierPickupDate
        );

        if (additionalInformation.advancedCarrierSequenceNbr && additionalInformation.advancedCarrierSequenceNbr > 0) {
          advancedCarrier.sequenceNbr = `${additionalInformation.advancedCarrierSequenceNbr}`;
          advancedCarrier.listActionCd = ActionCd.UPDATE;
        }
        rqst.advanceBeyondCarrier.push(advancedCarrier);
      }

      // Beyond Carrier
      if (additionalInformation.beyondCarrierSCAC && additionalInformation.beyondCarrierSCAC.trim().length > 0) {
        if (!rqst.advanceBeyondCarrier) {
          rqst.advanceBeyondCarrier = [];
        }
        const beyondCarrier: AdvanceBeyondCarrier = new AdvanceBeyondCarrier();
        beyondCarrier.typeCd = AdvanceBeyondTypeCd.BYD_CARR;
        beyondCarrier.listActionCd = ActionCd.ADD;
        beyondCarrier.sequenceNbr = '-1';
        beyondCarrier.carrierScacCd = additionalInformation.beyondCarrierSCAC;
        beyondCarrier.carrierProNbr = additionalInformation.beyondCarrierTrackingNumber;
        beyondCarrier.carrierPickupDate = DateTimeHelper.conditionDateToServer(
          additionalInformation.beyondCarrierPickupDate
        );
        if (additionalInformation.beyondCarrierSequenceNbr && additionalInformation.beyondCarrierSequenceNbr > 0) {
          beyondCarrier.sequenceNbr = `${additionalInformation.beyondCarrierSequenceNbr}`;
          beyondCarrier.listActionCd = ActionCd.UPDATE;
        }
        rqst.advanceBeyondCarrier.push(beyondCarrier);
      }

      // Cod
      if (additionalInformation.codAmount && additionalInformation.codAmount > 0) {
        if (!rqst.miscLineItem) {
          rqst.miscLineItem = [];
        }

        const miscLineItem: MiscLineItem = new MiscLineItem();
        miscLineItem.listActionCd = ActionCd.ADD;
        miscLineItem.sequenceNbr = `-1`;
        miscLineItem.lineTypeCd = MiscLineItemCd.COD_AMT;
        miscLineItem.amount = additionalInformation.codAmount;
        miscLineItem.paymentMethodCd = MiscLineItemPaymentMethodCdHelper.toEnum(
          additionalInformation.codPaymentTypeCode
        );
        miscLineItem.description = startCase(additionalInformation.codPaymentTypeCode);
        if (additionalInformation.codSequenceNbr && additionalInformation.codSequenceNbr > 0) {
          miscLineItem.sequenceNbr = `${additionalInformation.codSequenceNbr}`;
          miscLineItem.listActionCd = ActionCd.UPDATE;
        }

        rqst.miscLineItem.push(miscLineItem);
      }

      // Driver Cash
      if (additionalInformation.driverCash && additionalInformation.driverCash > 0) {
        if (!rqst.miscLineItem) {
          rqst.miscLineItem = [];
        }

        const miscLineItem: MiscLineItem = new MiscLineItem();
        miscLineItem.listActionCd = ActionCd.ADD;
        miscLineItem.sequenceNbr = `-1`;
        miscLineItem.lineTypeCd = MiscLineItemCd.CASH_PPD_LN;
        miscLineItem.amount = additionalInformation.driverCash;
        if (additionalInformation.checkNumber && additionalInformation.checkNumber.length > 0) {
          miscLineItem.checkNbr = additionalInformation.checkNumber;
        }
        if (additionalInformation.driverCashSequenceNbr && additionalInformation.driverCashSequenceNbr > 0) {
          miscLineItem.sequenceNbr = `${additionalInformation.driverCashSequenceNbr}`;
          miscLineItem.listActionCd = ActionCd.UPDATE;
        }

        rqst.miscLineItem.push(miscLineItem);
      }

      // Part Prepaid remarks
      if (additionalInformation.partPrepaidRemarks && additionalInformation.partPrepaidRemarks.trim().length > 0) {
        if (!rqst.miscLineItem) {
          rqst.miscLineItem = [];
        }

        const miscLineItem: MiscLineItem = new MiscLineItem();
        miscLineItem.listActionCd = ActionCd.ADD;
        miscLineItem.sequenceNbr = `-1`;
        miscLineItem.lineTypeCd = MiscLineItemCd.PART_PPD_LN;
        miscLineItem.amount = additionalInformation.partPrepaidAmount;
        miscLineItem.description = additionalInformation.partPrepaidRemarks;
        if (additionalInformation.partPrepaidSequenceNbr && additionalInformation.partPrepaidSequenceNbr > 0) {
          miscLineItem.sequenceNbr = `${additionalInformation.partPrepaidSequenceNbr}`;
          miscLineItem.listActionCd = ActionCd.UPDATE;
        }

        rqst.miscLineItem.push(miscLineItem);
      }

      // Part Collect Remarks
      if (additionalInformation.partCollectRemarks && additionalInformation.partCollectRemarks.trim().length > 0) {
        if (!rqst.miscLineItem) {
          rqst.miscLineItem = [];
        }

        const miscLineItem: MiscLineItem = new MiscLineItem();
        miscLineItem.listActionCd = ActionCd.ADD;
        miscLineItem.sequenceNbr = `-1`;
        miscLineItem.lineTypeCd = MiscLineItemCd.PART_COLL_LN;
        miscLineItem.amount = additionalInformation.partCollectAmount;
        miscLineItem.description = additionalInformation.partCollectRemarks;
        if (additionalInformation.partCollectSequenceNbr && additionalInformation.partCollectSequenceNbr > 0) {
          miscLineItem.sequenceNbr = `${additionalInformation.partCollectSequenceNbr}`;
          miscLineItem.listActionCd = ActionCd.UPDATE;
        }

        rqst.miscLineItem.push(miscLineItem);
      }

      // Customs Bond
      if (additionalInformation.inbondCity && additionalInformation.inbondCity.length > 0) {
        const customsBond: CustomsBond = new CustomsBond();
        if (!rqst.customsBond) {
          rqst.customsBond = [];
        }
        customsBond.listActionCd = ActionCd.ADD;
        customsBond.city = additionalInformation.inbondCity;
        customsBond.stateCd = additionalInformation.inbondStateCode;
        customsBond.bondNbr = additionalInformation.inbondNumber;
        customsBond.sequenceNbr = '-1';

        if (additionalInformation.inbondSequenceNbr && additionalInformation.inbondSequenceNbr > 0) {
          customsBond.sequenceNbr = `${additionalInformation.inbondSequenceNbr}`;
          customsBond.listActionCd = ActionCd.UPDATE;
        }
        rqst.customsBond.push(customsBond);
      }

      if (!rqst.shipment) {
        rqst.shipment = new Shipment();
      }

      if (additionalInformation.linealFoot && additionalInformation.linealFoot > 0) {
        rqst.shipment.linealFootTotalNbr = `${additionalInformation.linealFoot}`;
      }

      if (additionalInformation.declaredValueAmount && additionalInformation.declaredValueAmount > 0) {
        rqst.shipment.declaredValueAmount = additionalInformation.declaredValueAmount;
      }
    }
  }

  static parseMiscLineItems(
    miscLineItems: MiscLineItem[],
    additionalInformation: AdditionalInformation
  ): AdditionalInformation {
    miscLineItems.forEach((respMiscLineItem) => {
      switch (respMiscLineItem.lineTypeCd) {
        case MiscLineItemCd.COD_AMT: {
          additionalInformation.codAmount = respMiscLineItem.amount || undefined;
          additionalInformation.codPaymentTypeCode = `${respMiscLineItem.paymentMethodCd}`;
          additionalInformation.codSequenceNbr = Number.parseInt(respMiscLineItem.sequenceNbr) || undefined;
          break;
        }
        case MiscLineItemCd.CASH_PPD_LN: {
          additionalInformation.driverCash = respMiscLineItem.amount || undefined;
          additionalInformation.checkNumber = respMiscLineItem.checkNbr;
          additionalInformation.driverCashSequenceNbr = Number.parseInt(respMiscLineItem.sequenceNbr) || undefined;
          break;
        }
        case MiscLineItemCd.PART_PPD_LN: {
          additionalInformation.partPrepaidAmount = !isNullOrUndefined(respMiscLineItem.amount)
            ? respMiscLineItem.amount
            : undefined;
          additionalInformation.partPrepaidRemarks = respMiscLineItem.description;
          additionalInformation.partPrepaidSequenceNbr = Number.parseInt(respMiscLineItem.sequenceNbr) || undefined;
          break;
        }
        case MiscLineItemCd.PART_COLL_LN: {
          additionalInformation.partCollectAmount = !isNullOrUndefined(respMiscLineItem.amount)
            ? respMiscLineItem.amount
            : undefined;
          additionalInformation.partCollectRemarks = respMiscLineItem.description;
          additionalInformation.partCollectSequenceNbr = Number.parseInt(respMiscLineItem.sequenceNbr) || undefined;
          break;
        }
        default: {
          break;
        }
      }
    });
    return additionalInformation;
  }
  static parseBolAttachment(bolAttachment: BolAttachment, additionalInformation: AdditionalInformation) {
    if (bolAttachment) {
      additionalInformation.hasCustomsInvoiceDocument = bolAttachment.custInvoiceInd;
      additionalInformation.hasCBPInbondDocument = bolAttachment.customsCdpFormInd;
      additionalInformation.hasPackingListDocument = bolAttachment.packingListInd;
      additionalInformation.hasSupplementalPagesDocument = bolAttachment.supplementalPagesInd;
    } else {
      additionalInformation.hasCustomsInvoiceDocument = false;
      additionalInformation.hasCBPInbondDocument = false;
      additionalInformation.hasPackingListDocument = false;
      additionalInformation.hasSupplementalPagesDocument = false;
    }
  }
  static parseCustomsBond(customsBond: CustomsBond[], additionalInformation: AdditionalInformation) {
    if (customsBond && customsBond.length === 1) {
      additionalInformation.inbondCity = customsBond[0].city;
      additionalInformation.inbondStateCode = customsBond[0].stateCd;
      additionalInformation.inbondNumber = customsBond[0].bondNbr;
      additionalInformation.inbondSequenceNbr = Number.parseInt(customsBond[0].sequenceNbr) || undefined;
    }
  }
  static parseOtherShipmentData(shipment: Shipment, additionalInformation: AdditionalInformation) {
    additionalInformation.linealFoot = Number.parseInt(shipment.linealFootTotalNbr) || undefined;
    additionalInformation.declaredValueAmount = shipment.declaredValueAmount || undefined;
  }

  static parseAdvanceBeyondCarrier(
    advanceBeyondCarrier: AdvanceBeyondCarrier[],
    additionalInformation: AdditionalInformation
  ) {
    if (advanceBeyondCarrier) {
      advanceBeyondCarrier.forEach((respCarrier) => {
        if (respCarrier.typeCd === AdvanceBeyondTypeCd.ADV_CARR) {
          additionalInformation.advancedCarrierPickupDate = DateTimeHelper.conditionDateFromServer(
            new Date(respCarrier.carrierPickupDate)
          );
          additionalInformation.advancedCarrierTrackingNumber = respCarrier.carrierProNbr;
          additionalInformation.advancedCarrierSCAC = respCarrier.carrierScacCd;
          additionalInformation.advancedCarrierSequenceNbr = Number.parseInt(respCarrier.sequenceNbr) || undefined;
        } else if (respCarrier.typeCd === AdvanceBeyondTypeCd.BYD_CARR) {
          additionalInformation.beyondCarrierPickupDate = DateTimeHelper.conditionDateFromServer(
            new Date(respCarrier.carrierPickupDate)
          );
          additionalInformation.beyondCarrierTrackingNumber = respCarrier.carrierProNbr;
          additionalInformation.beyondCarrierSCAC = respCarrier.carrierScacCd;
          additionalInformation.beyondCarrierSequenceNbr = Number.parseInt(respCarrier.sequenceNbr) || undefined;
        }
      });
    }
  }

  /**
   * Create Additional Information from shipment response
   * @param resp
   * @returns {AdditionalInformation}
   */
  static toBill(resp: GetShipmentResp): AdditionalInformation {
    const additionalInformation = new AdditionalInformation();

    AdditionalInformationTransformer.parseBolAttachment(resp.bolAttachment, additionalInformation);

    // --- BLIND PARTY ---
    if (resp.asEnteredBolParty) {
      const blindPartyIndex = resp.asEnteredBolParty.findIndex(
        (party) => party.partyTypeCd === BolPartyTypeCd.BLIND_SHPR
      );
      if (blindPartyIndex > -1) {
        additionalInformation.isBlind = true;
        additionalInformation.shipperName = resp.asEnteredBolParty[blindPartyIndex].name1;
        additionalInformation.shipperAddress = resp.asEnteredBolParty[blindPartyIndex].address;
        additionalInformation.shipperCity = resp.asEnteredBolParty[blindPartyIndex].city;
        additionalInformation.shipperState = resp.asEnteredBolParty[blindPartyIndex].stateCd;
        additionalInformation.shipperZip = resp.asEnteredBolParty[blindPartyIndex].zip6;
        additionalInformation.shipperCountryCd = resp.asEnteredBolParty[blindPartyIndex].countryCd;
      }
    }

    // --- TRUE BLIND PARTY ---
    if (resp.asEnteredBolParty) {
      const trueBlindPartyIndex = resp.asEnteredBolParty.findIndex(
        (party) => party.partyTypeCd === BolPartyTypeCd.TRUE_BLIND_SHPR
      );
      if (trueBlindPartyIndex > -1) {
        additionalInformation.isTrueBlind = true;
        additionalInformation.shipperName = resp.asEnteredBolParty[trueBlindPartyIndex].name1;
        additionalInformation.shipperAddress = resp.asEnteredBolParty[trueBlindPartyIndex].address;
        additionalInformation.shipperCity = resp.asEnteredBolParty[trueBlindPartyIndex].city;
        additionalInformation.shipperState = resp.asEnteredBolParty[trueBlindPartyIndex].stateCd;
        additionalInformation.shipperZip = resp.asEnteredBolParty[trueBlindPartyIndex].zip6;
        additionalInformation.shipperCountryCd = resp.asEnteredBolParty[trueBlindPartyIndex].countryCd;
      }
    }

    // Advanced Beyond Carrier
    AdditionalInformationTransformer.parseAdvanceBeyondCarrier(resp.advanceBeyondCarrier, additionalInformation);

    // Misc Line Item
    if (resp.miscLineItem) {
      AdditionalInformationTransformer.parseMiscLineItems(resp.miscLineItem, additionalInformation);

      // Customs Bond
      AdditionalInformationTransformer.parseCustomsBond(resp.customsBond, additionalInformation);
      AdditionalInformationTransformer.parseOtherShipmentData(resp.shipment, additionalInformation);
    }

    return additionalInformation;
  }
}
