import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { SearchDmsDocumentResp } from '@xpo-ltl/sdk-documentmanagement';
import { get as _get } from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { DocumentResolverPackage } from '../model/document-resolver-package';
import { DocType } from '../services/sdk-dms/classes';
import { BillEntryService } from './../../bill-entry/shared/services/bill-entry.service';
import { DocumentService } from './../services/document-service/document.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../core/model/config-manager-properties.enum';

@Injectable()
export class DocumentResolver implements Resolve<DocumentResolverPackage> {
  constructor(
    private documentService: DocumentService,
    private billEntryService: BillEntryService,
    protected configManager: ConfigManagerService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<DocumentResolverPackage> {
    const proNumber = _get(this.billEntryService, 'bill.orderHeader.proNumber');
    if (proNumber) {
      return this.documentService.listAvailableDocuments(proNumber, this.configManager.getSetting(ConfigManagerProperties.imageCorpCode)).pipe(
        map(
          (response: SearchDmsDocumentResp) =>
            <DocumentResolverPackage>{
              hasCustomsDocument: response.documentInfo.some((info) => info.cdt.docClass === DocType.Customs),
              hasBOLDocument: response.documentInfo.some((info) => info.cdt.docClass === DocType.BillOfLading),
            }
        ),
        catchError(() => {
          return of(undefined);
        })
      );
    }
  }
}
