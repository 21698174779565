import { Injectable } from '@angular/core';
import { ActionCd, CommodityClassCd, CommodityClassCdHelper, CommodityPackageCdHelper } from '@xpo-ltl/sdk-common';
import { Commodity, HazMat } from '@xpo-ltl/sdk-shipment';
import { Observable, Subject, ReplaySubject } from 'rxjs';
import { isNullOrUndefined } from '../../../../shared/services/tools/tools.service';

import { XrtSearchRequest, XrtSortExpressions } from '../../../../shared/model/xrt';
import { BolCommodityDetail } from '../../../../shared/services/bol-api/model/bol-commodity-detail';
import { ElasticsearchService } from '../../../../shared/services/elasticsearch/elasticsearch.service';
import { NmfcItemSearchResponse } from '../../../../shared/services/elasticsearch/model/nmfc-item-search-response';
import { Commodity as UICommodity } from '../../../bill-entry/model';
import { HazmatDetails, MarinePollutantClassificationHelper, UnNaNumber, } from '../../../hazmat-details/model';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';

@Injectable()
export class CommoditiesTransformerService {
  private xrtSearchRequest: XrtSearchRequest;

  constructor(private loggingApiService: LoggingApiService, private elasticSearchService: ElasticsearchService) {
    this.xrtSearchRequest = new XrtSearchRequest();
    this.xrtSearchRequest.page = 1;
    this.xrtSearchRequest.pageSize = 1;
    this.xrtSearchRequest.sortExpressions = [new XrtSortExpressions('id', true)];
    this.xrtSearchRequest.type = 'nmfcitemIdSearch';
  }

  /**
   * Populate UpsertShipmentRequest based on Commodities Information
   *
   * @param {UICommodity[]} commodities
   * @param {string} proNumber
   * @returns {Commodity[]}
   * @memberof CommoditiesTransformerService
   */
  toUpsertShipment(commodities: UICommodity[], proNumber: string): Commodity[] {
    const theCommodities = [];

    commodities.forEach((item) => {
      if (UICommodity.hasValue(item)) {
        const aCommodity = new Commodity();

        aCommodity.chrgToCd = item.chargeToCode;
        aCommodity.sequenceNbr = `${item.sequenceNbr}`;
        aCommodity.classType = this.conditionUpsertClass(item.class, item.actionCd); // TODO: enum
        aCommodity.description = item.description;
        aCommodity.hazardousMtInd = item.hazmatInd;
        aCommodity.nmfcItemCd = item.nmfc;
        aCommodity.packageCd = CommodityPackageCdHelper.toEnum(item.packaging);
        aCommodity.piecesCount = item.pieces;
        aCommodity.weightLbs = item.weight ? item.weight : 0;
        aCommodity.listActionCd = item.actionCd;
        aCommodity.originalDescription = item.originalDescription || item.description;

        if (item.hazmatDetails && aCommodity.hazardousMtInd) {
          aCommodity.hazMat = this.mapHazMat(item.hazmatDetails, proNumber);
        }

        theCommodities.push(aCommodity);
      }
    });

    return theCommodities;
  }

  mapHazMat(hazmatDetails: HazmatDetails, proNumber: string): HazMat  {
    const item = new HazMat();

    item.hazmatUnna = hazmatDetails.unNaNumber.unnaCd;
    item.zoneCd = hazmatDetails.unNaNumber.zoneCd;
    item.unnaInstId = hazmatDetails.unNaNumber.unnaInstId;
    item.packingGroupCd = hazmatDetails.unNaNumber.packageGroup;
    item.technicalName = hazmatDetails.technicalName;
    item.dotSpecialPermit = hazmatDetails.dotPermit;
    item.hazmatResidueInd = hazmatDetails.isResidue;
    item.reportedQuantityInd = hazmatDetails.isReportableQty;
    item.limitedQuantityInd = hazmatDetails.isLimitedQty;

    // Marine Pollutant
    item.marinePollutantInd = MarinePollutantClassificationHelper.hasValue(hazmatDetails.marinePollutantClassification);

    if (item.marinePollutantInd) {
      item.marinePollutantChemical = hazmatDetails.marinePollutantClassification.chemicalName;
    }

    // Radioactive
    item.radioactiveInd = hazmatDetails.unNaNumber.radioactiveInd;

    if (item.radioactiveInd) {
      item.radioactivityNbr = hazmatDetails.radioactiveClassification.radioactivity;
      item.transportIndex = hazmatDetails.radioactiveClassification.transportIndex;
      item.radioactivityUnitOfMeasure = hazmatDetails.radioactiveClassification.uom;
      item.radioactiveChemicalName = hazmatDetails.radioactiveClassification.radioactiveChemical;
      item.fissileExceptedInd = hazmatDetails.radioactiveClassification.fissileExpected;
   }
    // Disabling this feature for now jira -> LCS-17545
    // Explosive
    // item.explosiveInd = hazmatDetails.unNaNumber.explosiveInd;

    // if (item.explosiveInd) {
    //   item.netExplosiveMassNbr = hazmatDetails.explosiveClassification.nem;
    //   item.netExplosiveMassUnitOfMeasure = hazmatDetails.explosiveClassification.uom;
    // }

    // Logging if techName entered and not required
    if (
      hazmatDetails.technicalName
      && hazmatDetails.technicalName.trim().length
      && !hazmatDetails.unNaNumber.techNameRequiredInd
    ) {
      this.loggingApiService.info(`Tech Name Entered, not required. Pro: ${proNumber}, UNNA: ${hazmatDetails.unNaNumber.unnaCd}`);
    }

    item.listActionCd = ActionCd.ADD;

    return item;
  }

  conditionUpsertClass(classCode: string, actionCd: ActionCd): CommodityClassCd {
    if (classCode) {
      return CommodityClassCdHelper.toEnum('Clss' + classCode);
    } else if (actionCd === ActionCd.DELETE) {
      return undefined;
    }

    throw new TypeError('Commodity Class Must Have Value');
  }

  /**
   * Modifies any nmfc values that have -0[0-9] to -[0-9]
   * @param {string} nmfc
   * @returns {string}
   */
  conditionNmfcCode(nmfc: string): string {
    if (nmfc && nmfc.trim().length > 0 && nmfc.match(/-0[0-9]$/)) {
      nmfc = nmfc.substring(0, nmfc.length - 2) + nmfc.substring(nmfc.length - 1);
    }
    if (nmfc && nmfc[0] === '0') {
      nmfc = nmfc.substring(1);
    }
    return nmfc;
  }

  /**
   * Modifieds class code to convert items such as 77.5 to 775 and 92.5 to 925
   * @param {string} classCode
   * @returns {string}
   */
  conditionClassCode(classCode: string): string {
    let convertedClassCode = classCode;

    if (classCode.endsWith('77.5')) {
      convertedClassCode = classCode.replace('77.5', '775');
    } else if (classCode.endsWith('92.5')) {
      convertedClassCode = classCode.replace('92.5', '925');
    }

    return convertedClassCode;
  }


  private mapBolCommodities(commodities: BolCommodityDetail[]): UICommodity[] {
    const uiCommodities = [];

    commodities.forEach((bolCommodity) => {
      const commodity = new UICommodity();

      commodity.class = CommodityClassCdHelper.toEnum(this.conditionClassResponse(bolCommodity.classTxt));
      commodity.description = bolCommodity.description;
      commodity.originalDescription = bolCommodity.originalDescription;
      commodity.nmfc = this.conditionNmfcCode(bolCommodity.nmfcTxt);
      commodity.packaging = CommodityPackageCdHelper.toEnum(bolCommodity.pakageTxt);
      commodity.pieces = bolCommodity.quantity;
      commodity.weight = bolCommodity.weight;
      commodity.sequenceNbr = -1;
      commodity.hazmatInd = bolCommodity.hazamatInd;
      commodity.actionCd = ActionCd.ADD;

      uiCommodities.push(commodity);
    });

    return uiCommodities;
  }

  private mapPurCommodities(commodities: Commodity[]): UICommodity[] {
    const uiCommodities = [];

    commodities.forEach((commodityIn) => {
      const commodity = new UICommodity();

      commodity.chargeToCode = commodityIn.chrgToCd;
      commodity.class = commodityIn.classType;
      commodity.nmfc = this.conditionNmfcCode(commodityIn.nmfcItemCd);
      commodity.packaging = commodityIn.packageCd;
      commodity.pieces = commodityIn.piecesCount;
      commodity.weight = commodityIn.weightLbs;
      commodity.sequenceNbr = Number.parseInt(commodityIn.sequenceNbr);
      commodity.hazmatInd = commodityIn.hazardousMtInd;
      commodity.hazmatDetails = CommoditiesTransformerService.toHazmatDetails(commodityIn.hazMat);
      commodity.description = commodity.hazmatDetails && commodity.hazmatDetails.hazmatDescription
        ? commodity.hazmatDetails.hazmatDescription
        : commodityIn.description;
      commodity.originalDescription = commodityIn.originalDescription || commodityIn.description;
      commodity.actionCd = ( commodity.sequenceNbr >= 1 ) ? ActionCd.UPDATE : commodityIn.listActionCd;

      uiCommodities.push(commodity);
    });

    return uiCommodities;
  }

  // tslint:disable-next-line:member-ordering
  private static toHazmatDetails(hazmat: HazMat): HazmatDetails | undefined {
    if (hazmat) {
      return {
        unNaNumber: {
          unnaCd: hazmat.hazmatUnna,
          unnaInstId: hazmat.unnaInstId
        } as UnNaNumber,
        technicalName: hazmat.technicalName,
        dotPermit: hazmat.dotSpecialPermit,
        isResidue: hazmat.hazmatResidueInd,
        isLimitedQty: hazmat.limitedQuantityInd,
        isReportableQty: hazmat.reportedQuantityInd,
        marinePollutantClassification: {
          chemicalName: hazmat.marinePollutantChemical,
        },
        radioactiveClassification: {
          radioactiveChemical: hazmat.radioactiveChemicalName,
          radioactivity: hazmat.radioactivityNbr,
          uom: hazmat.radioactivityUnitOfMeasure,
          transportIndex: hazmat.transportIndex,
          fissileExpected: hazmat.fissileExceptedInd,
        },
        explosiveClassification: {
          nem: hazmat.netExplosiveMassNbr,
          uom: hazmat.netExplosiveMassUnitOfMeasure,
        },
        hazmatDescription: '',
      } as HazmatDetails;
    }
  }

  /**
   * Create UI Commodities from shipment response
   * @param Commodity[]
   * @returns {UICommodity[]}
   */
  mapCommodity(commodities: BolCommodityDetail[] | Commodity[], isBol: boolean,
                      validateCommodity: boolean): Observable<UICommodity[]> {
    let uiCommodities = [];
    const observableResults = new ReplaySubject<UICommodity[]>();
    const completeFunc = () => {
      observableResults.next(uiCommodities);
      observableResults.complete();
    };

    if (!commodities || commodities.length <= 0) {
      completeFunc();
    } else {

      if (isBol) {
        uiCommodities = this.mapBolCommodities(commodities as BolCommodityDetail[]);
      } else {
        uiCommodities = this.mapPurCommodities(commodities as Commodity[]);
      }
      if (validateCommodity && uiCommodities.length > 0 && uiCommodities.some(item => item.nmfc)) {
        this.validateNmfcValues(
          uiCommodities.filter(item => item.nmfc && item.nmfc.trim().length > 0).map(item => item.nmfc))
          .subscribe(
            results => {
              uiCommodities.forEach(commodity => {
                commodity.nmfc = (results && results[commodity.nmfc]) ? results[commodity.nmfc].id : '';
                if (results && results[commodity.nmfc] && results[commodity.nmfc].classType) {
                  commodity.class = CommodityClassCdHelper.toEnum(
                    this.conditionClassResponse(this.conditionClassCode(results[commodity.nmfc].classType))
                  );
                }
              });
            },
            error => {
              this.loggingApiService.error(`error while validating nmfc values: ${error.message}`);
            },
            () => {
              completeFunc();
            }
          );
      } else {
        completeFunc();
      }
    }
    return observableResults;
  }

  conditionClassResponse(response: string) {
    let conditionedResponse = response;
    if (response) {
      if (!response.startsWith('Clss')) {
        conditionedResponse = `Clss${response}`;
      }
    }
    return conditionedResponse;
  }

  /**
   * Remove leading zeros
   * @param {string[]} values
   * @returns {string[]}
   */
  private removeLeadingZeros(values: string[]): string[] {
    return values.map(item => (item && item[0] === '0') ? item.substring(1) : item);
  }

  /**
   * validates the nmfc value and returns its value or undefined if not valid.
   * @param {string} nmfcValue
   * @returns {Observable<string>}
   */
  validateNmfcValues(nmfcValues: string[]): Observable<any> {
    const searchObservable = new Subject();
    const workingValues = {};
    const completeFunc = () => {
      searchObservable.next(workingValues);
      searchObservable.complete();
    };

    if (isNullOrUndefined(nmfcValues) || nmfcValues.every(item => isNullOrUndefined(item))) {
      completeFunc();
    } else {

      this.xrtSearchRequest.filters['id'] = this.removeLeadingZeros(nmfcValues);
      this.xrtSearchRequest.pageSize = nmfcValues.length;
      this.elasticSearchService.xrtSearch(this.xrtSearchRequest, true, false).then(
        (response: NmfcItemSearchResponse[]) => {
          response.forEach(item => {
            workingValues[item.id] = {id: item.id, classType: `Clss${item.classType}`};
          });
          completeFunc();
        },
        (error) => {
          completeFunc();
        }
      );
    }
    return searchObservable;
  }
}
