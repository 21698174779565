import { Pipe, PipeTransform } from '@angular/core';
import { RuleOverrideReasonCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'ruleOverrideReasonCd'
})
export class RuleOverrideReasonCdPipe implements PipeTransform {
  transform(value: RuleOverrideReasonCd | string): string {
    let displayValue = '';
    switch (value) {
      case RuleOverrideReasonCd.SRN_NOT_AVAILABLE: {
        displayValue = 'Number not on BOL';
        break;
      }
      case RuleOverrideReasonCd.SRN_AVAILABLE_NOT_VALID: {
        displayValue = 'Format not on BOL';
        break;
      }
    }

    return displayValue;
  }
}
