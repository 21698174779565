import { Pipe, PipeTransform } from '@angular/core';
import { CommodityPackageCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'commodityPackageCd'
})
export class CommodityPackageCdPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let displayValue = '';
    switch (value) {
      case CommodityPackageCd.AMMO_PACK:
        displayValue = 'AMM - Ammo Pack';
        break;
      case CommodityPackageCd.ATTM:
        displayValue = 'ATH - Attachment';
        break;
      case CommodityPackageCd.BAG:
        displayValue = 'BAG - Bag';
        break;
      case CommodityPackageCd.BALE:
        displayValue = 'BAL - Bale';
        break;
      case CommodityPackageCd.BANDING:
        displayValue = 'BDG - Banding';
        break;
      case CommodityPackageCd.BARGE:
        displayValue = 'BRG - Barge';
        break;
      case CommodityPackageCd.BARREL:
        displayValue = 'BBL - Barrel';
        break;
      case CommodityPackageCd.BASKET:
        displayValue = 'BSK - Basket';
        break;
      case CommodityPackageCd.BEAM:
        displayValue = 'BEM - Beam';
        break;
      case CommodityPackageCd.BELTING:
        displayValue = 'BLT - Belting';
        break;
      case CommodityPackageCd.BIN:
        displayValue = 'BIN - Bin';
        break;
      case CommodityPackageCd.BING_CHEST:
        displayValue = 'BIC - Bing Chest';
        break;
      case CommodityPackageCd.BOBBIN:
        displayValue = 'BOB - Bobbin';
        break;
      case CommodityPackageCd.BOTTLE:
        displayValue = 'BTL - Bottle';
        break;
      case CommodityPackageCd.BOX:
        displayValue = 'BOX - Box';
        break;
      case CommodityPackageCd.BOX_WITH_CONTAINER:
        displayValue = 'BXI - Box W Cont';
        break;
      case CommodityPackageCd.BRACING:
        displayValue = 'BRC - Bracing';
        break;
      case CommodityPackageCd.BUCKET:
        displayValue = 'BXT - Bucket';
        break;
      case CommodityPackageCd.BULK:
        displayValue = 'BLK - Bulk';
        break;
      case CommodityPackageCd.BULK_CARGO:
        displayValue = 'CBC - Bulk Cargo';
        break;
      case CommodityPackageCd.BUNDLE:
        displayValue = 'BDL - Bundle';
        break;
      case CommodityPackageCd.CABINET:
        displayValue = 'CAB - Cabinet';
        break;
      case CommodityPackageCd.CAGE:
        displayValue = 'CAG - Cage';
        break;
      case CommodityPackageCd.CAN:
        displayValue = 'CAN - Can';
        break;
      case CommodityPackageCd.CAN_CASE:
        displayValue = 'CCS - Can Case';
        break;
      case CommodityPackageCd.CAR_LOAD_RAIL:
        displayValue = 'CLD - Carload Rail';
        break;
      case CommodityPackageCd.CARBOY:
        displayValue = 'CBY - Carby/Gaylrd';
        break;
      case CommodityPackageCd.CARR:
        displayValue = 'CAR - Carrier';
        break;
      case CommodityPackageCd.CARTON:
        displayValue = 'CTN - Carton';
        break;
      case CommodityPackageCd.CASE:
        displayValue = 'CAS - Case';
        break;
      case CommodityPackageCd.CASK:
        displayValue = 'CSK - Cask';
        break;
      case CommodityPackageCd.CHEESES:
        displayValue = 'CHE - Cheeses';
        break;
      case CommodityPackageCd.CHEST:
        displayValue = 'CHS - Chest';
        break;
      case CommodityPackageCd.COIL:
        displayValue = 'COL - Coil';
        break;
      case CommodityPackageCd.CONES:
        displayValue = 'CON - Cones';
        break;
      case CommodityPackageCd.CONTAINER:
        displayValue = 'CNT - Container';
        break;
      case CommodityPackageCd.CORE:
        displayValue = 'COR - Core';
        break;
      case CommodityPackageCd.CORNER_REINFORCEMENT:
        displayValue = 'CRF - Corner Reinf';
        break;
      case CommodityPackageCd.CRADLE:
        displayValue = 'CRD - Cradle';
        break;
      case CommodityPackageCd.CRATE:
        displayValue = 'CRT - Crate';
        break;
      case CommodityPackageCd.CYLINDER:
        displayValue = 'CYL - Cylinder';
        break;
      case CommodityPackageCd.DOUBLE_RACK:
        displayValue = 'DRK - Double Rack';
        break;
      case CommodityPackageCd.DOUBLE_SKID:
        displayValue = 'DSK - Double Skid';
        break;
      case CommodityPackageCd.DOUBLE_TOTE_BIN:
        displayValue = 'DTB - Dbl Tote Bin';
        break;
      case CommodityPackageCd.DRUM:
        displayValue = 'DRM - Drum';
        break;
      case CommodityPackageCd.DRY_BULK:
        displayValue = 'DBK - Dry Bulk';
        break;
      case CommodityPackageCd.EDGE_PROTECTION:
        displayValue = 'EPR - Edge Prot';
        break;
      case CommodityPackageCd.EGG_CRATING:
        displayValue = 'EGG - Egg Crating';
        break;
      case CommodityPackageCd.ENVELOPE:
        displayValue = 'ENV - Envelope';
        break;
      case CommodityPackageCd.FIRKIN:
        displayValue = 'FIR - Firkin';
        break;
      case CommodityPackageCd.FLASK:
        displayValue = 'FSK - Flask';
        break;
      case CommodityPackageCd.FLO_BIN:
        displayValue = 'FLO - FloBin';
        break;
      case CommodityPackageCd.FORWARD_REEL:
        displayValue = 'FWR - Forward Reel';
        break;
      case CommodityPackageCd.FRAME:
        displayValue = 'FRM - Frame';
        break;
      case CommodityPackageCd.HALF_RACK:
        displayValue = 'HRK - Half Rack';
        break;
      case CommodityPackageCd.HALF_TOTE_BIN:
        displayValue = 'HTB - Hlf Tote Bin';
        break;
      case CommodityPackageCd.HANGER_RACK:
        displayValue = 'HRB - Hanger Rack';
        break;
      case CommodityPackageCd.HEADS_BEEF:
        displayValue = 'HED - Heads Beef';
        break;
      case CommodityPackageCd.HOGSHEAD:
        displayValue = 'HGH - Hogshead';
        break;
      case CommodityPackageCd.HOPPER_TRUCK:
        displayValue = 'HPT - Hopper Truck';
        break;
      case CommodityPackageCd.INT_BULK_CTR:
        displayValue = 'IBC - Int Bulk Ctr';
        break;
      case CommodityPackageCd.JAR:
        displayValue = 'JAR - Jar';
        break;
      case CommodityPackageCd.JERRYCAN:
        displayValue = 'JER - Jerrycan';
        break;
      case CommodityPackageCd.JUG:
        displayValue = 'JUG - Jug';
        break;
      case CommodityPackageCd.KEG:
        displayValue = 'KEG - Keg';
        break;
      case CommodityPackageCd.KIT:
        displayValue = 'KIT - Kit';
        break;
      case CommodityPackageCd.KNOCKDOWN_RACK:
        displayValue = 'KRK - Knockd Rack';
        break;
      case CommodityPackageCd.KNOCKDOWN_TOTE_BIN:
        displayValue = 'KTB - Knockd Tbin';
        break;
      case CommodityPackageCd.LID_TOP:
        displayValue = 'LID - Lid/Top';
        break;
      case CommodityPackageCd.LIFT_VAN:
        displayValue = 'LVN - Lift Van';
        break;
      case CommodityPackageCd.LIFTS:
        displayValue = 'LIF - Lifts';
        break;
      case CommodityPackageCd.LINERS:
        displayValue = 'LNR - Liners';
        break;
      case CommodityPackageCd.LOG:
        displayValue = 'LOG - Log';
        break;
      case CommodityPackageCd.LOOSE:
        displayValue = 'LSE - Loose';
        break;
      case CommodityPackageCd.LQD_BULK:
        displayValue = 'LBK - Liquid Bulk';
        break;
      case CommodityPackageCd.LUG:
        displayValue = 'LUG - Lug';
        break;
      case CommodityPackageCd.MISCELLANEOUS:
        displayValue = 'MSC - Miscellaneous';
        break;
      case CommodityPackageCd.MIXED_CONTAINER:
        displayValue = 'MIX - Mixed Cont';
        break;
      case CommodityPackageCd.MULTI_RACK:
        displayValue = 'MRP - Multi Rack';
        break;
      case CommodityPackageCd.NOIL:
        displayValue = 'NOL - Noil';
        break;
      case CommodityPackageCd.ON_OWN_WHEEL:
        displayValue = 'WHE - On Own Wheel';
        break;
      case CommodityPackageCd.PACKED:
        displayValue = 'PCK - Packed';
        break;
      case CommodityPackageCd.PAIL:
        displayValue = 'PAL - Pail';
        break;
      case CommodityPackageCd.PALLET:
        displayValue = 'PLT - Pallet';
        break;
      case CommodityPackageCd.PALLET_2_WAY:
        displayValue = 'PAT - Pallet 2 Way';
        break;
      case CommodityPackageCd.PALLET_4_WAY:
        displayValue = 'PAF - Pallet 4 Way';
        break;
      case CommodityPackageCd.PARTIONING:
        displayValue = 'PRT - Partioning';
        break;
      case CommodityPackageCd.PCS:
        displayValue = 'PCS - Pieces';
        break;
      case CommodityPackageCd.PIMS:
        displayValue = 'PIR - Pims';
        break;
      case CommodityPackageCd.PIPE_RACK:
        displayValue = 'PRK - Pipe Rack';
        break;
      case CommodityPackageCd.PIPELINE:
        displayValue = 'PLN - Pipeline';
        break;
      case CommodityPackageCd.PKG:
        displayValue = 'PKG - Package';
        break;
      case CommodityPackageCd.PLATFORM:
        displayValue = 'PLF - Platform';
        break;
      case CommodityPackageCd.PRIMARY_LIFT_CONTAINER:
        displayValue = 'PLC - Prim Liftcn';
        break;
      case CommodityPackageCd.PRIVATE_VEHCLE:
        displayValue = 'POV - Priv Vehcle';
        break;
      case CommodityPackageCd.QUARTER_BEEF:
        displayValue = 'QTR - Quarter Beef';
        break;
      case CommodityPackageCd.RACK:
        displayValue = 'RCK - Rack';
        break;
      case CommodityPackageCd.RAIL_SEMICONDUCTOR:
        displayValue = 'RAL - Rail Semicon';
        break;
      case CommodityPackageCd.REEL:
        displayValue = 'REL - Reel';
        break;
      case CommodityPackageCd.REINFORCEMNT:
        displayValue = 'RFT - Reinforcemnt';
        break;
      case CommodityPackageCd.REVERSE_REEL:
        displayValue = 'RVR - Rev Reel';
        break;
      case CommodityPackageCd.ROLL:
        displayValue = 'ROL - Roll';
        break;
      case CommodityPackageCd.SACK:
        displayValue = 'SAK - Sack';
        break;
      case CommodityPackageCd.SEPARATOR:
        displayValue = 'SPR - Separator';
        break;
      case CommodityPackageCd.SHEET:
        displayValue = 'SHT - Sheet';
        break;
      case CommodityPackageCd.SHOOK:
        displayValue = 'SHK - Shook';
        break;
      case CommodityPackageCd.SHRINK_WRAP:
        displayValue = 'SHW - Shrink Wrap';
        break;
      case CommodityPackageCd.SIDE_OF_BEEF:
        displayValue = 'SID - Side Of Beef';
        break;
      case CommodityPackageCd.SKID:
        displayValue = 'SKD - Skid';
        break;
      case CommodityPackageCd.SKID_ELEVATE:
        displayValue = 'SKE - Skid Elevate';
        break;
      case CommodityPackageCd.SLEEVE:
        displayValue = 'SLV - Sleeve';
        break;
      case CommodityPackageCd.SLIP_SHEET:
        displayValue = 'SLP - Slip Sheet';
        break;
      case CommodityPackageCd.SPIN_CYLINDERS:
        displayValue = 'SPI - Spin Cyls';
        break;
      case CommodityPackageCd.SPOOL:
        displayValue = 'SPL - Spool';
        break;
      case CommodityPackageCd.TANK:
        displayValue = 'TNK - Tank';
        break;
      case CommodityPackageCd.TANK_CAR:
        displayValue = 'TKR - Tank Car';
        break;
      case CommodityPackageCd.TANK_TRUCK:
        displayValue = 'TKT - Tank Truck';
        break;
      case CommodityPackageCd.TIERCE:
        displayValue = 'TRC - Tierce';
        break;
      case CommodityPackageCd.TOTE_BIN:
        displayValue = 'TBN - Tote Bin';
        break;
      case CommodityPackageCd.TRAILER_CONTAINER_LOAD:
        displayValue = 'TLD - Trlr Cont Ld';
        break;
      case CommodityPackageCd.TRAY:
        displayValue = 'TRY - Tray';
        break;
      case CommodityPackageCd.TRUNK_CHEST:
        displayValue = 'TRK - Trunk Chest';
        break;
      case CommodityPackageCd.TRUNK_SAMPLE:
        displayValue = 'TSS - Trunk Sample';
        break;
      case CommodityPackageCd.TUB:
        displayValue = 'TUB - Tub';
        break;
      case CommodityPackageCd.TUBE:
        displayValue = 'TBE - Tube';
        break;
      case CommodityPackageCd.UNIT:
        displayValue = 'UNT - Unit';
        break;
      case CommodityPackageCd.UNPACKED:
        displayValue = 'UNP - Unpacked';
        break;
      case CommodityPackageCd.VAN_PACK:
        displayValue = 'VPK - Van pack';
        break;
      case CommodityPackageCd.VEHICLES:
        displayValue = 'VEH - Vehicles';
        break;
      case CommodityPackageCd.WHEELED_CAR:
        displayValue = 'WLC - Wheeled Car';
        break;
      case CommodityPackageCd.WRAPPED:
        displayValue = 'WRP - Wrapped';
        break;
      default:
        displayValue = value;
    }
    return displayValue;
  }
  untransform(value: any): any {
    switch (value) {
      case 'AMM - Ammo Pack':
        return CommodityPackageCd.AMMO_PACK;
      case 'ATH - Attachment':
        return CommodityPackageCd.ATTM;
      case 'BAG - Bag':
        return CommodityPackageCd.BAG;
      case 'BAL - Bale':
        return CommodityPackageCd.BALE;
      case 'BDG - Banding':
        return CommodityPackageCd.BANDING;
      case 'BRG - Barge':
        return CommodityPackageCd.BARGE;
      case 'BBL - Barrel':
        return CommodityPackageCd.BARREL;
      case 'BSK - Basket':
        return CommodityPackageCd.BASKET;
      case 'BEM - Beam':
        return CommodityPackageCd.BEAM;
      case 'BLT - Belting':
        return CommodityPackageCd.BELTING;
      case 'BIN - Bin':
        return CommodityPackageCd.BIN;
      case 'BIC - Bing Chest':
        return CommodityPackageCd.BING_CHEST;
      case 'BOB - Bobbin':
        return CommodityPackageCd.BOBBIN;
      case 'BTL - Bottle':
        return CommodityPackageCd.BOTTLE;
      case 'BOX - Box':
        return CommodityPackageCd.BOX;
      case 'BXI - Box W Cont':
        return CommodityPackageCd.BOX_WITH_CONTAINER;
      case 'BRC - Bracing':
        return CommodityPackageCd.BRACING;
      case 'BXT - Bucket':
        return CommodityPackageCd.BUCKET;
      case 'BLK - Bulk':
        return CommodityPackageCd.BULK;
      case 'CBC - Bulk Cargo':
        return CommodityPackageCd.BULK_CARGO;
      case 'BDL - Bundle':
        return CommodityPackageCd.BUNDLE;
      case 'CAB - Cabinet':
        return CommodityPackageCd.CABINET;
      case 'CAG - Cage':
        return CommodityPackageCd.CAGE;
      case 'CAN - Can':
        return CommodityPackageCd.CAN;
      case 'CCS - Can Case':
        return CommodityPackageCd.CAN_CASE;
      case 'CLD - Carload Rail':
        return CommodityPackageCd.CAR_LOAD_RAIL;
      case 'CBY - Carby/Gaylrd':
        return CommodityPackageCd.CARBOY;
      case 'CAR - Carrier':
        return CommodityPackageCd.CARR;
      case 'CTN - Carton':
        return CommodityPackageCd.CARTON;
      case 'CAS - Case':
        return CommodityPackageCd.CASE;
      case 'CSK - Cask':
        return CommodityPackageCd.CASK;
      case 'CHE - Cheeses':
        return CommodityPackageCd.CHEESES;
      case 'CHS - Chest':
        return CommodityPackageCd.CHEST;
      case 'COL - Coil':
        return CommodityPackageCd.COIL;
      case 'CON - Cones':
        return CommodityPackageCd.CONES;
      case 'CNT - Container':
        return CommodityPackageCd.CONTAINER;
      case 'COR - Core':
        return CommodityPackageCd.CORE;
      case 'CRF - Corner Reinf':
        return CommodityPackageCd.CORNER_REINFORCEMENT;
      case 'CRD - Cradle':
        return CommodityPackageCd.CRADLE;
      case 'CRT - Crate':
        return CommodityPackageCd.CRATE;
      case 'CYL - Cylinder':
        return CommodityPackageCd.CYLINDER;
      case 'DRK - Double Rack':
        return CommodityPackageCd.DOUBLE_RACK;
      case 'DSK - Double Skid':
        return CommodityPackageCd.DOUBLE_SKID;
      case 'DTB - Dbl Tote Bin':
        return CommodityPackageCd.DOUBLE_TOTE_BIN;
      case 'DRM - Drum':
        return CommodityPackageCd.DRUM;
      case 'DBK - Dry Bulk':
        return CommodityPackageCd.DRY_BULK;
      case 'EPR - Edge Prot':
        return CommodityPackageCd.EDGE_PROTECTION;
      case 'EGG - Egg Crating':
        return CommodityPackageCd.EGG_CRATING;
      case 'ENV - Envelope':
        return CommodityPackageCd.ENVELOPE;
      case 'FIR - Firkin':
        return CommodityPackageCd.FIRKIN;
      case 'FSK - Flask':
        return CommodityPackageCd.FLASK;
      case 'FLO - FloBin':
        return CommodityPackageCd.FLO_BIN;
      case 'FWR - Forward Reel':
        return CommodityPackageCd.FORWARD_REEL;
      case 'FRM - Frame':
        return CommodityPackageCd.FRAME;
      case 'HRK - Half Rack':
        return CommodityPackageCd.HALF_RACK;
      case 'HTB - Hlf Tote Bin':
        return CommodityPackageCd.HALF_TOTE_BIN;
      case 'HRB - Hanger Rack':
        return CommodityPackageCd.HANGER_RACK;
      case 'HED - Heads Beef':
        return CommodityPackageCd.HEADS_BEEF;
      case 'HGH - Hogshead':
        return CommodityPackageCd.HOGSHEAD;
      case 'HPT - Hopper Truck':
        return CommodityPackageCd.HOPPER_TRUCK;
      case 'IBC - Int Bulk Ctr':
        return CommodityPackageCd.INT_BULK_CTR;
      case 'JAR - Jar':
        return CommodityPackageCd.JAR;
      case 'JER - Jerrycan':
        return CommodityPackageCd.JERRYCAN;
      case 'JUG - Jug':
        return CommodityPackageCd.JUG;
      case 'KEG - Keg':
        return CommodityPackageCd.KEG;
      case 'KIT - Kit':
        return CommodityPackageCd.KIT;
      case 'KRK - Knockd Rack':
        return CommodityPackageCd.KNOCKDOWN_RACK;
      case 'KTB - Knockd Tbin':
        return CommodityPackageCd.KNOCKDOWN_TOTE_BIN;
      case 'LID - Lid/Top':
        return CommodityPackageCd.LID_TOP;
      case 'LVN - Lift Van':
        return CommodityPackageCd.LIFT_VAN;
      case 'LIF - Lifts':
        return CommodityPackageCd.LIFTS;
      case 'LNR - Liners':
        return CommodityPackageCd.LINERS;
      case 'LOG - Log':
        return CommodityPackageCd.LOG;
      case 'LSE - Loose':
        return CommodityPackageCd.LOOSE;
      case 'LBK - Liquid Bulk':
        return CommodityPackageCd.LQD_BULK;
      case 'LUG - Lug':
        return CommodityPackageCd.LUG;
      case 'MSC - Miscellaneous':
        return CommodityPackageCd.MISCELLANEOUS;
      case 'MIX - Mixed Cont':
        return CommodityPackageCd.MIXED_CONTAINER;
      case 'MRP - Multi Rack':
        return CommodityPackageCd.MULTI_RACK;
      case 'NOL - Noil':
        return CommodityPackageCd.NOIL;
      case 'WHE - On Own Wheel':
        return CommodityPackageCd.ON_OWN_WHEEL;
      case 'PCK - Packed':
        return CommodityPackageCd.PACKED;
      case 'PAL - Pail':
        return CommodityPackageCd.PAIL;
      case 'PLT - Pallet':
        return CommodityPackageCd.PALLET;
      case 'PAT - Pallet 2 Way':
        return CommodityPackageCd.PALLET_2_WAY;
      case 'PAF - Pallet 4 Way':
        return CommodityPackageCd.PALLET_4_WAY;
      case 'PRT - Partioning':
        return CommodityPackageCd.PARTIONING;
      case 'PCS - Pieces':
        return CommodityPackageCd.PCS;
      case 'PIR - Pims':
        return CommodityPackageCd.PIMS;
      case 'PRK - Pipe Rack':
        return CommodityPackageCd.PIPE_RACK;
      case 'PLN - Pipeline':
        return CommodityPackageCd.PIPELINE;
      case 'PKG - Package':
        return CommodityPackageCd.PKG;
      case 'PLF - Platform':
        return CommodityPackageCd.PLATFORM;
      case 'PLC - Prim Liftcn':
        return CommodityPackageCd.PRIMARY_LIFT_CONTAINER;
      case 'POV - Priv Vehcle':
        return CommodityPackageCd.PRIVATE_VEHCLE;
      case 'QTR - Quarter Beef':
        return CommodityPackageCd.QUARTER_BEEF;
      case 'RCK - Rack':
        return CommodityPackageCd.RACK;
      case 'RAL - Rail Semicon':
        return CommodityPackageCd.RAIL_SEMICONDUCTOR;
      case 'REL - Reel':
        return CommodityPackageCd.REEL;
      case 'RFT - Reinforcemnt':
        return CommodityPackageCd.REINFORCEMNT;
      case 'RVR - Rev Reel':
        return CommodityPackageCd.REVERSE_REEL;
      case 'ROL - Roll':
        return CommodityPackageCd.ROLL;
      case 'SAK - Sack':
        return CommodityPackageCd.SACK;
      case 'SPR - Separator':
        return CommodityPackageCd.SEPARATOR;
      case 'SHT - Sheet':
        return CommodityPackageCd.SHEET;
      case 'SHK - Shook':
        return CommodityPackageCd.SHOOK;
      case 'SHW - Shrink Wrap':
        return CommodityPackageCd.SHRINK_WRAP;
      case 'SID - Side Of Beef':
        return CommodityPackageCd.SIDE_OF_BEEF;
      case 'SKD - Skid':
        return CommodityPackageCd.SKID;
      case 'SKE - Skid Elevate':
        return CommodityPackageCd.SKID_ELEVATE;
      case 'SLV - Sleeve':
        return CommodityPackageCd.SLEEVE;
      case 'SLP - Slip Sheet':
        return CommodityPackageCd.SLIP_SHEET;
      case 'SPI - Spin Cyls':
        return CommodityPackageCd.SPIN_CYLINDERS;
      case 'SPL - Spool':
        return CommodityPackageCd.SPOOL;
      case 'TNK - Tank':
        return CommodityPackageCd.TANK;
      case 'TKR - Tank Car':
        return CommodityPackageCd.TANK_CAR;
      case 'TKT - Tank Truck':
        return CommodityPackageCd.TANK_TRUCK;
      case 'TRC - Tierce':
        return CommodityPackageCd.TIERCE;
      case 'TBN - Tote Bin':
        return CommodityPackageCd.TOTE_BIN;
      case 'TLD - Trlr Cont Ld':
        return CommodityPackageCd.TRAILER_CONTAINER_LOAD;
      case 'TRY - Tray':
        return CommodityPackageCd.TRAY;
      case 'TRK - Trunk Chest':
        return CommodityPackageCd.TRUNK_CHEST;
      case 'TSS - Trunk Sample':
        return CommodityPackageCd.TRUNK_SAMPLE;
      case 'TUB - Tub':
        return CommodityPackageCd.TUB;
      case 'TBE - Tube':
        return CommodityPackageCd.TUBE;
      case 'UNT - Unit':
        return CommodityPackageCd.UNIT;
      case 'UNP - Unpacked':
        return CommodityPackageCd.UNPACKED;
      case 'VPK - Van pack':
        return CommodityPackageCd.VAN_PACK;
      case 'VEH - Vehicles':
        return CommodityPackageCd.VEHICLES;
      case 'WLC - Wheeled Car':
        return CommodityPackageCd.WHEELED_CAR;
      case 'WRP - Wrapped':
        return CommodityPackageCd.WRAPPED;
      default:
        return value;
    }
  }
}
