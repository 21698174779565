import { Injectable } from '@angular/core';
import { DataOptions } from './data-api/data-options';
@Injectable({ providedIn: 'root' })
export abstract class BaseService {
  protected _dataOptions: DataOptions;

  constructor() {
    this._dataOptions = new DataOptions();
    this._dataOptions.loadingOverlayEnabled = true;
    this._dataOptions.toastOnError = true;
  }

  protected getUrl(uri) {
    let url = this.getEndPoint();
    if ( !url.endsWith('/') && !uri.startsWith('/')) {
      url += '/';
    } else if ( url.endsWith('/') && uri.startsWith('/')) {
      url = url.substr(0, url.length - 1);
    }
      return `${url}${uri}`;
  }
  protected abstract getEndPoint(): string;
}
