import { Component, AfterViewInit, OnDestroy, Input, ChangeDetectionStrategy, HostListener, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { XpoFeedbackFormComponent } from '../../feedback-form/feedback-form.component';
import { XpoFormPanelComponent } from '../form-panel/form-panel.component';
import { XpoFormPanelService } from '../form-panel/form-panel.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';
import { MatDialog } from '@angular/material/dialog';

@Component({
  moduleId: module.id,
  selector: 'xpo-form-header',
  templateUrl: 'form-header.component.html',
  styleUrls: ['form-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class XpoFormHeaderComponent implements OnInit, AfterViewInit, OnDestroy {
  /** Height of header. */
  private readonly HEADER_ROW_HEIGHT = 44;
  /** Buffer to switch to selected panel a little higher than the actual panel position. */
  private readonly HEADER_ROW_BUFFER = 44;

  private panelTops: number[] = [];
  private unsubscribe$: Subject<void> = new Subject();

  panels: XpoFormPanelComponent[] = [];
  selectedPanelIndex = 0;
  isProd = false;
  feedbackEmail = '';

  @Input() showFeedback = true;
  @Input() hideBadges = true;

  @Input() scrollListener: Observable<any>;

  /**
   * sets focus to first panels
   *
   * @type {Subject<void>}
   * @memberof XpoFormHeaderComponent
   */
  @Input() reset: Subject<void> = new Subject();

  @Input() containerSizeChanges: Observable<any>;

  constructor(
    public panelService: XpoFormPanelService,
    private config: ConfigManagerService,
    private dialog: MatDialog,
  ) {
    this.feedbackEmail = config.getSetting(ConfigManagerProperties.feedbackEmailAddress);
  }

  ngOnInit() {
    this.isProd = this.config.getSetting(ConfigManagerProperties.isProd) ? true : false;
  }

  ngAfterViewInit() {
    this.initSubscriptions();
  }

  ngOnDestroy() {
    this.panelService.clearPanels();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDownEvent(event: KeyboardEvent) {
    if (event.altKey) {
      const panelIndex = this.panels.findIndex(panel => {
        return (
          panel.altKey.toUpperCase() === event.key.toUpperCase() ||
          panel.macAltKey.toUpperCase() === event.key
        );
      });
      if (panelIndex >= 0 && panelIndex < this.panels.length) {
        this.focusPanel(panelIndex);
      }
    }
  }

  openFeedbackDialog(): void {
    this.dialog.open(XpoFeedbackFormComponent, {disableClose:true});
  }

  onScroll(event: any) {
    const topPx: number = event.target.scrollTop;

    for (let i = 0; i < this.panelTops.length - 1; i++) {
      if (topPx >= this.panelTops[i] && topPx <= this.panelTops[i + 1]) {
        this.selectedPanelIndex = i;
        break;
      } else {
        this.selectedPanelIndex = this.panelTops.length - 1; // set to last panel
      }
    }
  }

  focusPanel(index: number) {
    this.selectedPanelIndex = index;
    this.panels[index].setFocus();
  }


  altKeyMessage(panel: XpoFormPanelComponent): string {
    return panel.altKey ? panel.altKey : '';
  }

  getClasses(panel: XpoFormPanelComponent) {
    return {
      [`header__button__badge--${panel.formStatus}`]: true,
      ['header__button__badge--hidden']: this.hideBadges,
      ['header__button__badge--warning']: panel.hasWarning
    };
  }

  /**
   * Gets all the panels on the view and subscribes to the scroll event of the body
   */
  private initSubscriptions() {
    // Gets all the panels in the view and saves there position.
    this.panelService
      .getPanels()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        this.panels = v;
        this.updatePanelTops();
      });

    // When a panel is focused, update the selected panel in the header
    this.panelService.focusedPanel
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((v) => {
        const panelIndex = this.panels.indexOf(v);

        if (panelIndex !== -1) {
          this.selectedPanelIndex = panelIndex;
        }
      });

    // Sets focus to the first panel
    this.reset
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => this.panels[0].setFocus());

    // Updates the selected panel based on the the current position of the scroll container
    if (this.scrollListener) {
      this.scrollListener
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe((v) => this.onScroll(v));
    }

    // When the panels increases in size, update the positions of all panels.
    if (this.containerSizeChanges) {
      this.containerSizeChanges
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(() => this.updatePanelTops());
    }
  }

  /**
   * Saves the offset top postilion of all the panels in the view.
   */
  private updatePanelTops() {
    this.panelTops = this.panels.map((panel) => {
      return panel.offsetTop - (this.HEADER_ROW_HEIGHT + this.HEADER_ROW_BUFFER);
    });

    this.panelTops.sort((a, b) => a - b);
    this.panelTops[0] = 0; // first panel starts at 0 px
  }
}
