import { Injectable } from '@angular/core';
import { ConditioningService } from '@xpo-ltl/common-services';
import { ApiRequest } from '@xpo-ltl/data-api';
import {
  DocumentManagementApiService,
  GetDocumentPath,
  GetDocumentResp,
  RetrieveDmsAuthTokenResp,
  RetrieveDmsAuthTokenRqst,
  SearchDmsDocumentResp,
  SearchDmsDocumentRqst,
} from '@xpo-ltl/sdk-documentmanagement';
import { get as _get } from 'lodash';

import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

export const NAVIGATING_DOCUMENTS_KEY = 'NAVIGATING_DOCUMENTS_KEY';
export const CLOSE_ALL_DOCUMENT_KEY_VALUE = 'CLOSE_ALL_DOCUMENT_KEY_VALUE';

@Injectable()
export class DocumentService {
  constructor(
    private documentManagementService: DocumentManagementApiService,
    private conditioningService: ConditioningService
  ) {}

  private cachedToken: { tokenResponse: RetrieveDmsAuthTokenResp; expires: Date };

  cleanOpenDocumentLocalStorage() {
    localStorage.removeItem(NAVIGATING_DOCUMENTS_KEY);
  }

  closeAllWIndows() {
    localStorage.setItem(NAVIGATING_DOCUMENTS_KEY, CLOSE_ALL_DOCUMENT_KEY_VALUE);
  }

  listAvailableDocuments(
    searchString: string,
    corpCode: string,
    minDate?: Date,
    maxDate?: Date,
    searchTags?: string[]
  ): Observable<SearchDmsDocumentResp> {
    return this.getDmsAuthToken().pipe(
      switchMap((token: RetrieveDmsAuthTokenResp) => {
        const request = new SearchDmsDocumentRqst();
        request.corpCode = corpCode;
        request.dmsAuth = token.access_token;

        if (minDate) {
          request.minDateTime = minDate.toISOString();
        } else {
          const date = new Date();
          date.setMonth(date.getMonth() - 9);
          date.setHours(0, 0, 0, 0);

          request.minDateTime = date.toISOString();
        }

        if (maxDate) {
          request.maxDateTime = maxDate.toISOString();
        }
        if (searchTags) {
          request.searchTags = searchTags;
        }

        request.searchString = this.conditioningService.conditionProNumber(searchString, 9);

        return this.documentManagementService
          .searchDmsDocument(request, ApiRequest.concealedCall, { headers: { DMSAuth: request.dmsAuth } })
          .pipe(map((value) => (value['searchDmsDocumentResp'] ? value['searchDmsDocumentResp'] : value)));
      })
    );
  }

  getDocument(
    docTimestamp: string,
    docClass: string,
    corpCode: string = 'DEMO',
    docFormat: string = 'pdf'
  ): Observable<GetDocumentResp> {
    return this.getDmsAuthToken().pipe(
      switchMap((token: RetrieveDmsAuthTokenResp) => {
        const params = new GetDocumentPath();
        params.docArchiveTimestamp = docTimestamp;
        params.docClass = docClass;
        params.docFormat = docFormat;
        params.corpCode = corpCode;
        return this.documentManagementService.getDocument(
          params,
          undefined,
          { loadingOverlayEnabled: false },
          {
            headers: {
              dmsAuth: token.access_token,
            },
          }
        );
      })
    );
  }

  private getDmsAuthToken(): Observable<RetrieveDmsAuthTokenResp> {
    return this.cachedToken && this.cachedToken.expires.getTime() > Date.now()
      ? of(this.cachedToken.tokenResponse)
      : this.documentManagementService
          .retrieveDmsAuthToken({} as RetrieveDmsAuthTokenRqst, { loadingOverlayEnabled: false })
          .pipe(
            tap((resp) => {
              this.cachedToken = {
                tokenResponse: resp,
                expires: new Date(Date.now() + _get(resp, 'expires_in', 0) * 1000),
              };
            })
          );
  }
}
