import { Injectable } from '@angular/core';
import { GetAppMetadataResp, GetAppMetadataRqst } from '@xpo-ltl/api-appmetadata';
import { Observable,from as fromPromise} from 'rxjs';
import { BaseService } from '../base-service';
import { DataApiService } from '../data-api/data-api.service';
import { map } from 'rxjs/operators';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

@Injectable()
export class AppMetadataApiService extends BaseService {
  private majorMinorBuildRegex = new RegExp('^([0-9]+).([0-9]+).([0-9]+)$');
  private majorMinorRevisionBuildRegex = new RegExp('^([0-9]+).([0-9]+).([0-9]+).([0-9]+)$');
  private matchGroups = { Major: 1, Minor: 2, Revision: 3, Build: 4 };

  constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
    super();
  }

  getAppMetaData(request: GetAppMetadataRqst): Observable<GetAppMetadataResp> {
    return fromPromise(
      this.dataApiService.get(this.getUrl(`/apps/${request.appName}`), null, this._dataOptions)
    ).pipe(map((response) => response['data'] as GetAppMetadataResp));
  }

  meetsMinimumVersionRequirement(minVersion: string, version: string): boolean {
    let meets = false;
    if (
      minVersion &&
      version &&
      minVersion.trim().length > 0 &&
      version.trim().length > 0 &&
      minVersion.match(/./g).length >= 2 &&
      version.match(/./g).length >= 2
    ) {
      if (minVersion === version) {
        meets = true;
      } else {
        const versionDotsLength = (version.match(/\./g) || []).length;
        const minVersionDotsLength = (minVersion.match(/\./g) || []).length;

        if (versionDotsLength === 0 || minVersionDotsLength === 0) {
          return false;
        }

        const versionResults = (versionDotsLength === 2
          ? this.majorMinorBuildRegex
          : this.majorMinorRevisionBuildRegex
        ).exec(version);
        const minVersionResults = (minVersionDotsLength === 2
          ? this.majorMinorBuildRegex
          : this.majorMinorRevisionBuildRegex
        ).exec(minVersion);

        if (!versionResults || !minVersionResults) {
          return false;
        }

        while (versionResults.length < 5) {
          versionResults.push('0');
        }
        while (minVersionResults.length < 5) {
          minVersionResults.push('0');
        }

        if (
          Number.parseInt(versionResults[this.matchGroups.Major]) >
          Number.parseInt(minVersionResults[this.matchGroups.Major])
        ) {
          meets = true;
        } else if (
          Number.parseInt(versionResults[this.matchGroups.Major]) <
          Number.parseInt(minVersionResults[this.matchGroups.Major])
        ) {
          meets = false;
        } else if (
          Number.parseInt(versionResults[this.matchGroups.Minor]) >
          Number.parseInt(minVersionResults[this.matchGroups.Minor])
        ) {
          meets = true;
        } else if (
          Number.parseInt(versionResults[this.matchGroups.Minor]) <
          Number.parseInt(minVersionResults[this.matchGroups.Minor])
        ) {
          meets = false;
        } else if (
          Number.parseInt(versionResults[this.matchGroups.Revision]) >
          Number.parseInt(minVersionResults[this.matchGroups.Revision])
        ) {
          meets = true;
        } else if (
          Number.parseInt(versionResults[this.matchGroups.Revision]) <
          Number.parseInt(minVersionResults[this.matchGroups.Revision])
        ) {
          meets = false;
        } else if (
          Number.parseInt(versionResults[this.matchGroups.Build]) >
          Number.parseInt(minVersionResults[this.matchGroups.Build])
        ) {
          meets = true;
        } else if (
          Number.parseInt(versionResults[this.matchGroups.Build]) <
          Number.parseInt(minVersionResults[this.matchGroups.Build])
        ) {
          meets = false;
        }
      }
    }
    return meets;
  }
  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.appMetadataApiEndpoint);
  }
}
