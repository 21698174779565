import { MatchCustomerBillingDetail } from '@xpo-ltl/sdk-customer';

export class CustomerMatchAddressValidator {

  /**
   * Peforms a juggling check of values for: address, city, state, and zip.
   * If all values are present this method returns true.
   * @returns {boolean}
   */
  static hasValidInputs(input: MatchCustomerBillingDetail): boolean {
    return input &&
      this.validValue(input.nameLine1) &&
      this.validValue(input.address) &&
      this.validValue(input.city) &&
      this.validValue(input.stateCd) &&
      this.validValue(input.zip6);
  }

  private static validValue(value: string) {
    if (value == null) {
      return false;
    }
    return value.trim().length > 0;
  }

}
