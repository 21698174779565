import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DocumentService } from './services/document-service/document.service';
import { SdkShipmentModule, ShipmentApiService } from '@xpo-ltl/sdk-shipment';
import { BillEntryService } from '../bill-entry/shared/services/bill-entry.service';
import { SalvageCenterValidationService } from '../bill-entry/shared/services/salvage-center-validation/salvage-center-validation.service';
import { BillEntryTransformerService } from '../bill-entry/shared/services/transformers/bill-entry-transformer.service';
import { BillOfLadingTransformerService } from '../bill-entry/shared/services/transformers/bill-of-lading-transformer.service';
import { CommoditiesTransformerService } from '../bill-entry/shared/services/transformers/commodities-transformer.service';
import { RemarksTransformerService } from '../bill-entry/shared/services/transformers/remarks-transformer.service';
import { BillValidatorService } from '../bill-entry/shared/services/validators/bill-validator.service';

import { ConditioningService as XpoConditioningService } from '@xpo-ltl/common-services';
import { CityOperationsApiService as SdkCityOperationsApiService } from '@xpo-ltl/sdk-cityoperations';
import { CustomerApiService as SdkCustomerApiService } from '@xpo-ltl/sdk-customer';
import { XpoFormComponentsModule } from './form-components/form-components.module';
import { NotificationComponent } from './notification/notification.component';
import { XpoPipeModule } from './pipes/pipe.module';
import { XpoProdStylesModule } from './prod-styles/prod-styles.module';
import { LoggedInUserResolver } from './resolvers/logged-in-user-resolver';
import { AppMetadataApiService } from './services/app-metadata-api/app-metadata-api.service';
import { BolApiService } from './services/bol-api/bol-api.service';
import { ConditioningService } from './services/conditioning-service/conditioning.service';
import { CustomerApiService } from './services/customer-api/customer-api-service';

import { ShipmentApiService as V2ShipmentApiService } from '@xpo-ltl-2.0/sdk-shipment';
import { DocumentManagementApiService } from '@xpo-ltl/sdk-documentmanagement';
import { PricingApiService as SdkPricingApiService } from '@xpo-ltl/sdk-pricing';
import { DocumentResolver } from './resolvers/document-resolver';
import { CityOperationsApiService } from './services/cityoperations-api/city-operations-api.service';
import { DataApiService } from './services/data-api/data-api.service';
import { ElasticsearchService } from './services/elasticsearch/elasticsearch.service';
import { FormatValidationService } from './services/format-validation/format-validation.service';
import { HumanResourceApiService } from './services/human-resource-api/human-resource-api.service';
import { LocationApiService } from './services/location-api/location-api.service';
import { NotificationService } from './services/notification/notification.service';
import { PricingApiService } from './services/pricing-api/pricing-api.service';
import { BillEntryShipmentApiService } from './services/shipment-api/shipment-api.service';
import { UserApiService } from './services/user-api/user-api.service';
import { WindowService } from './services/window-service/window.service';
import { ToolsService } from './services/tools/tools.service';
import { XpoSnackBarModule } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    XpoSnackBarModule,
    XpoProdStylesModule,
    XpoFormComponentsModule,
    XpoPipeModule,
    SdkShipmentModule,
    MatProgressSpinnerModule
  ],
  exports: [NotificationComponent, XpoProdStylesModule, XpoFormComponentsModule, XpoPipeModule],
  declarations: [NotificationComponent],
  providers: [
    ShipmentApiService,
    V2ShipmentApiService,
    SdkCustomerApiService,
    SdkCityOperationsApiService,
    RemarksTransformerService,
    CommoditiesTransformerService,
    BillOfLadingTransformerService,
    SalvageCenterValidationService,
    BillEntryTransformerService,
    BillEntryService,
    BillValidatorService,
    BillEntryShipmentApiService,
    BolApiService,
    ElasticsearchService,
    UserApiService,
    DataApiService,
    NotificationService,
    LocationApiService,
    PricingApiService,
    SdkPricingApiService,
    LoggingApiService,
    CustomerApiService,
    CityOperationsApiService,

    ConditioningService,
    FormatValidationService,
    LoggedInUserResolver,
    DocumentResolver,
    DocumentService,
    DocumentManagementApiService,
    XpoConditioningService,
    AppMetadataApiService,
    WindowService,
    HumanResourceApiService,
    ToolsService
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {}
