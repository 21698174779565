import { RuleOverrideReasonCd } from '@xpo-ltl/sdk-common';
import { Actionable } from './actionable';

export class SupplementalReferenceNumberCollection {
  referenceNumbers: SupplementalReferenceNumber[] = [];
  rulesAndInstructions: SupplementalReferenceNumber[] = [];
}

export enum SupplementalReferenceNumberCollectionFormFields {
  ReferenceNumbers = 'referenceNumbers',
  RulesAndInstructions = 'rulesAndInstructions',
}

export class SupplementalReferenceNumber extends Actionable {
  referenceType: string = undefined;
  number: string = undefined;
  formats: string[] = [];
  specialInstructions: string = undefined;
  hasWarning = false;
  required: boolean = null;
  statusReason: RuleOverrideReasonCd = null; // Default to N/A
  ruleInstanceId: number = undefined;
  conditionalValidatorsAdded = false;
  cisCustomerTypeCd: string = undefined;
  cisCustomerMadCd: string = undefined;

  constructor() {
    super();
  }

  static hasValue(srn: SupplementalReferenceNumber): boolean {
    return !!srn.referenceType || !!srn.number;
  }
}

export enum SupplementalReferenceNumberFormFields {
  ReferenceType = 'referenceType',
  Number = 'number',
  Formats = 'formats',
  SpecialInstructions = 'specialInstructions',
  HasWarning = 'hasWarning',
  Required = 'required',
  StatusReason = 'statusReason',
  RuleInstanceId = 'ruleInstanceId',
  ConditionalValidatorsAdded = 'conditionalValidatorsAdded',
  SequenceNbr = 'sequenceNbr',
  CisCustomerTypeCd = 'cisCustomerTypeCd',
  ActionCd = 'actionCd',
  CisCustomerMadCd = 'cisCustomerMadCd',
}
