import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { NotificationMessage } from './notification-message';
import { NotificationTypeEnum } from './notification-type-enum';
import { SnackbarConfig } from './snackbar-config';

@Injectable()
export class NotificationService {
  private subject = new Subject<NotificationMessage>();
  private message: string;
  private referenceCount = 0;

  constructor() {
    this.message = 'Loading...';
  }

  getSubscriber(): Observable<NotificationMessage> {
    return this.subject.asObservable();
  }

  /**
   * Sets the message that will be displayed, by default 'Loading...' will be used
   * @param message
   */
  setOverlayMessage(message) {
    this.message = message;
  }
  /**
   * Shows the loading overlay
   */
  showOverlayMessage(message: string = 'Loading...') {
    this.referenceCount++;
    this.message = message;
    this.sendMessageToSubscribers(new NotificationMessage(NotificationTypeEnum.ShowOverlay, this.message, null));
  }

  /**
   * Hides the loading overlay
   */
  hideOverlayMessage() {
    this.referenceCount--;
    if (this.referenceCount <= 0) {
      this.referenceCount = 0;
      this.sendMessageToSubscribers(new NotificationMessage(NotificationTypeEnum.HideOverlay, null, null));
      this.message = '';
    }
  }

  /**
   * Forces hiding of the overlay
   */
  clearOverlayMessage() {
    this.referenceCount = 0;
    this.hideOverlayMessage();
  }

  showSnackBarMessage(message: string, snackbarConfig: SnackbarConfig = new SnackbarConfig()) {
    this.sendMessageToSubscribers(new NotificationMessage(NotificationTypeEnum.ShowSnackbar, message, snackbarConfig));
  }

  private sendMessageToSubscribers(message: NotificationMessage) {
    this.subject.next(message);
  }
}
