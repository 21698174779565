import { Accessorial } from './accessorial';
import { AdditionalInformation } from './additional-information';
import { BillSource } from './bill-source.enum';
import { BillingInstructions } from './billing-instructions';
import { OrderDetails } from './order-details';
import { OrderHeader } from './order-header';
import { Party, PARTY_TYPE_ID } from './party';
import { Remarks } from './remarks';
import { SpecialInstructions } from './special-instructions';
import { SupplementalReferenceNumberCollection } from './supplemental-reference-number';
import { TimeDateCritical } from './time-date-critical';

export class Bill {
  source: BillSource;
  billEntryUserId: string;
  billEntryDate: Date = undefined;
  orderHeader: OrderHeader = undefined;
  asEnteredBolParties: Party[] = [
    new Party(PARTY_TYPE_ID.Shipper),
    new Party(PARTY_TYPE_ID.Consignee),
    new Party(PARTY_TYPE_ID.BillTo),
  ];
  asMatchedParties: Party[] = [
    new Party(PARTY_TYPE_ID.Shipper),
    new Party(PARTY_TYPE_ID.Consignee),
    new Party(PARTY_TYPE_ID.BillTo),
  ];
  supplementalReferenceNumberCollection: SupplementalReferenceNumberCollection = undefined;
  orderDetails: OrderDetails = undefined;
  accessorials: Accessorial[] = [];
  remarks: Remarks = undefined;
  billingInstructions: BillingInstructions = undefined;
  timeDateCritical: TimeDateCritical = undefined;
  specialInstructions: SpecialInstructions = undefined;
  additionalInformation: AdditionalInformation = undefined;
  sessionStartTime: number = undefined;
  sessionEndTime: number = undefined;
  isFromCache: boolean;
  isSrnCorrection: boolean;

  constructor() {}
}
