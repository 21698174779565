import { InjectionToken } from '@angular/core';

export interface XpoFeedbackFormConfig {
  disableFromNameInput?: boolean;
  disableSubjectInput?: boolean;
  fromEmail?: string;
  fromName?: string;
  invalidCharacterPattern?: string;
  message?: string;
  subject?: string;
  title?: string;
  toEmail?: string;
}

export const FEEDBACK_FORM_CONFIG = new InjectionToken<XpoFeedbackFormConfig>('feedback.config');
