import { TimeDateCritical, Shipment } from '@xpo-ltl/sdk-shipment';
import { Bill, TimeDateCritical as UITimeDateCritical } from '../../../../bill-entry/model';
import { DateTimeHelper } from '../../../util/date-time-helper';

export class TimeDateCriticalTransformer {
  static toUpsertShipmentRqst(bill: Bill): TimeDateCritical {
    let tdc;
    if (bill.timeDateCritical) {
      if (bill.timeDateCritical.dateTypeCode && bill.timeDateCritical.dateTypeCode.trim().length > 0) {
        tdc = new TimeDateCritical();
        tdc.tdcDateTypeCd = bill.timeDateCritical.dateTypeCode;
        tdc.tdcDate1 = DateTimeHelper.conditionDateToServer(bill.timeDateCritical.date1);
        if (bill.timeDateCritical.dateTypeCode === 'RNG') {
          tdc.tdcDate2 = DateTimeHelper.conditionDateToServer(bill.timeDateCritical.date2);
        }
        if (bill.timeDateCritical.timeTypeCode && bill.timeDateCritical.timeTypeCode.trim().length > 0) {
          tdc.tdcTimeTypeCd = bill.timeDateCritical.timeTypeCode;
          const timeDate1 = new Date();
          let time = [];
          time = bill.timeDateCritical.time1.split(/\:|\-/g);
          timeDate1.setUTCHours(Number.parseInt(time[0]), Number.parseInt(time[1]), 0, 0);
          tdc.tdcTime1 = DateTimeHelper.conditionTimeToServer(timeDate1);
          if (bill.timeDateCritical.timeTypeCode === 'RNG') {
            const timeDate2 = new Date();
            time = bill.timeDateCritical.time2.split(/\:|\-/g);
            timeDate2.setUTCHours(Number.parseInt(time[0]), Number.parseInt(time[1]), 0, 0);
            tdc.tdcTime2 = DateTimeHelper.conditionTimeToServer(timeDate2);
          }
        }
      }
    }
    return tdc;
  }

  static toBill(tdc: TimeDateCritical, estimatedDeliveryDate: Date, shipment: Shipment): UITimeDateCritical {
    const tdcReturn = new UITimeDateCritical();
    tdcReturn.estimatedDeliveryDate = estimatedDeliveryDate;
    tdcReturn.notifyOnArrival = shipment.destinationNotifyInd;
    tdcReturn.appointmentRequired = shipment.appointmentRequiredInd;
    tdcReturn.tdc = shipment.callForAppointmentInd;

    if (tdc) {
      if (tdc.tdcDateTypeCd) {
        tdcReturn.dateTypeCode = tdc.tdcDateTypeCd;
        tdcReturn.date1 = DateTimeHelper.conditionDateFromServer(new Date(tdc.tdcDate1));
        if (tdcReturn.dateTypeCode === 'RNG') {
          tdcReturn.date2 = DateTimeHelper.conditionDateFromServer(new Date(tdc.tdcDate2));
        }
      }

      if (tdc.tdcTimeTypeCd) {
        tdcReturn.timeTypeCode = tdc.tdcTimeTypeCd;
        if (tdc.tdcTime1) {
          const tdcDate1Time = DateTimeHelper.conditionTimeFromServer(new Date(tdc.tdcTime1));
          tdcReturn.time1 = `${tdcDate1Time.getHours()}:${DateTimeHelper.padTime(tdcDate1Time.getMinutes())}`;
        }

        if (tdcReturn.timeTypeCode === 'RNG' && tdc.tdcTime2) {
          const tdcDate2Time = DateTimeHelper.conditionTimeFromServer(new Date(tdc.tdcTime2));
          tdcReturn.time2 = `${tdcDate2Time.getHours()}:${DateTimeHelper.padTime(tdcDate2Time.getMinutes())}`;
        }
      }
    }
    return tdcReturn;
  }
}
