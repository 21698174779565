import { Country } from '../../../../bill-entry/model/country';

export class CountryTransformer {

  static toBill(abbreviation: string): Country {
    let country: Country = Country.US;

    if (abbreviation) {
      switch (abbreviation.toUpperCase()) {
        case 'MX':
        case 'MEX':
        case 'MEXICO':
          country = Country.MX;
          break;
        case 'CA':
        case 'CN':
        case 'CAN':
        case 'CANADA':
          country = Country.CA;
          break;
      }
    }

    return country;
  }

  static toUpsertShipment(country: Country): string {
    let abbreviation = 'US';

    if (country && country.code) {
      switch (country.code) {
        case Country.MX.code:
          abbreviation = 'MX';
          break;
        case Country.CA.code:
          abbreviation = 'CA';
          break;
      }
    }

    return abbreviation;
  }
}
