import { Injectable } from '@angular/core';
import { NotificationService } from '../notification/notification.service';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Injectable()
export class WindowService {
  private window;

  constructor(private notificationService: NotificationService, private snackBar: XpoSnackBar) {
    this.window = window;
  }

  openWindow(url: string, target?: string) {
    if (url) {
      this.window.open(url, target || '_blank');
    } else {
      this.snackBar.open({
        message: 'Invalid URL',
        matConfig:{
          duration:5000,
          verticalPosition: 'bottom',
        },
        status:'error'
      })
    }
  }

  generateDownloadFile(mimeType: string, data: any, fileName: string) {
    const blob = new Blob([data], { type: mimeType });

    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
      (window.navigator as any).msSaveOrOpenBlob(blob, fileName);
    } else {
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.href = URL.createObjectURL(blob);
      a.download = fileName;
      a.click();
      a.remove();
    }
  }
}
