import { Pipe, PipeTransform } from '@angular/core';

@Pipe ({
  name: 'round'
})
export class RoundPipe implements PipeTransform {
  transform(value: number): number {
    let returnValue = value;
    if (typeof value === 'number') {
      returnValue = Math.round(value);
    }
    return returnValue;
  }
}
