export enum OrderHeaderFormFields {
  BolVersionNumber = 'bolVersionNumber',
  BolInstanceId = 'bolInstanceId',
  RelatedProNumber = 'relatedProNumber',
  ProNumber = 'proNumber',
  DebtorTypeCode = 'debtorTypeCode',
  PickupDate = 'pickupDate',
  BillClassCode = 'billClassCode',
  ShipmentInstanceId = 'shipmentInstanceId',
  ShipmentSourceCd = 'shipmentSourceCd',
  SpotQuote = 'spotQuote',
  ParentInstanceId = 'parentInstanceId',
  DebtorTermFlipInd = 'debtorTermFlipInd'
}
