import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DataApiModule } from '@xpo-ltl/data-api';
import { FEEDBACK_FORM_CONFIG } from '../shared/feedback-form/feedback-form.config';
import { UserApiService } from '../shared/services/user-api/user-api.service';
import { feedbackFormFactory } from './feedback-form.config';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { ConfirmDialogComponent } from './components/dialog/confirm-dialog/confirm-dialog.component';
import { ConfirmSalvageDialogComponent } from './components/dialog/multiple-option-dialog/confirm-salvage-dialog.component';
import { XpoChromeVersionGuardModule } from './guards/chrome-version-guard/chrome-version-guard.module';
import { LogPageDwellService } from './log-page-dwell/log-page-dwell.service';
import { DialogService } from './services/dialog/dialog.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';

@NgModule({
  declarations: [ConfirmDialogComponent, ConfirmSalvageDialogComponent],
  imports: [
    CommonModule,
    RouterModule,
    XpoChromeVersionGuardModule,
    XpoCardModule,
    XpoButtonModule,
    DataApiModule,
  ],
  providers: [
    LogPageDwellService,
    DialogService,
    { provide: FEEDBACK_FORM_CONFIG, useFactory: feedbackFormFactory, deps: [ConfigManagerService, UserApiService] },
  ],
  exports: [ConfirmDialogComponent],
  entryComponents: [ConfirmDialogComponent, ConfirmSalvageDialogComponent],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, logPageDwell: LogPageDwellService) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    logPageDwell.start();
  }
}
