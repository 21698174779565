import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { take, tap, map } from 'rxjs/operators';
import { AppMetadataApiService } from '../../../shared/services/app-metadata-api/app-metadata-api.service';
import { APPLICATION_MINIMUM_VERSION_CONFIG, IAppMinimumVersionConfig } from './application-minimum-version-config';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../model/config-manager-properties.enum';

export function ApplicationMinimumVersionGuard(): CanActivateFn {
  return () => {
    const config: IAppMinimumVersionConfig = inject(APPLICATION_MINIMUM_VERSION_CONFIG);
    const appMetadataApiService: AppMetadataApiService = inject(AppMetadataApiService);
    const configManager: ConfigManagerService = inject(ConfigManagerService);
    const router: Router = inject(Router);

    let currentVersion = configManager.getSetting<string>(ConfigManagerProperties.buildVersion);
    if (
      config.bypassValues &&
      config.bypassValues.length > 0 &&
      config.bypassValues.some((value) => value === currentVersion)
    ) {
      return true;
    }

    if (currentVersion.indexOf('-') >= 0) {
      currentVersion = currentVersion.substring(0, currentVersion.indexOf('-'));
    }

    let minimumVersion;
    return appMetadataApiService
      .getAppMetaData({ appName: configManager.getSetting(ConfigManagerProperties.appMetadataAppName) }).
      pipe(take(1),
        tap((results) => (minimumVersion = results.minAppVersion))
        , map((results) =>
          appMetadataApiService.meetsMinimumVersionRequirement(results.minAppVersion, currentVersion)
        )
        , tap((results) => {
          if (!results) {
            router.navigate([config.versionMismatchRoute], {
              replaceUrl: true,
              queryParams: {
                currentVersion: configManager.getSetting(ConfigManagerProperties.buildVersion),
                minimumVersion: minimumVersion,
              },
            });
          }
        }))
  }
}