import { BaseValidation } from './base-validation';
import { Severity } from './severity.enum';

export class ProFormatValidator extends BaseValidation<string> {
  private nineDigitPro = '^[0-9]{9}$';  // 123456782
  private tenDigitPro = '^[0-9]{3}[-][0-9]{6}$';  // 123-456782
  private elevenDigitPro = '^[0][0-9]{3}[0][0-9]{6}$';  // 01230456782

  constructor(errorCode?: string, errorDescription?: string, fieldName?: string, severity?: Severity) {
    super(errorCode, errorDescription, fieldName, severity);
  }

  isValid(value: string): boolean {
    return new RegExp(this.nineDigitPro).test(value) ||
      new RegExp(this.tenDigitPro).test(value) ||
      new RegExp(this.elevenDigitPro).test(value);
  }
}
