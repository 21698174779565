export class LoggingConstants {
  static readonly ActivityNames = {
    BillEntryNavigation: 'Bill Entry Navigation',
    VersionInfo: 'Version Info',
    CacheOperation: 'CacheOperation'
  };
  static ActionNames = {
    Open: 'Open',
    Close: 'Close',
    Apply: 'Apply',
    Cancel: 'Cancel',
    Timeout: 'Timeout',
    CacheReload: 'ReloadingFromLocalCache',
    BillEntryStart: 'BillEntryStart',
    BillEntryReview: 'BillEntryReview',
    BillEntrySubmit: 'BillEntrySubmit',
  };
  static TransactionType = {
    CustomerMatchDialog: 'Customer Match Dialog',
    SelectCommodities: 'Select Commodities',
    BillEntryForm: 'Bill Entry Form',
    BillSubmit: 'Bill Submit',
    SessionTimestamps: 'SessionTimestamps'
  };
}
