import { Injectable } from '@angular/core';
import { ActionCd, ShipmentRemarkTypeCd } from '@xpo-ltl/sdk-common';
import { Remark } from '@xpo-ltl/sdk-shipment';
import { Remarks } from '../../../bill-entry/model/remarks';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../../core/model/config-manager-properties.enum';

@Injectable()
export class RemarksTransformerService {
  constructor(private configManager: ConfigManagerService) {}

  toUpsertShipment(remarks: Remarks): Remark[] {
    const remarkArray: Remark[] = [];
    if (remarks) {
      let aRemark = this.getUpsertRemark(remarks.hazmatEmergencyContactInfo, ShipmentRemarkTypeCd.ADHOC_HZ_MATL_RMK);
      if (aRemark && aRemark !== null) {
        remarkArray.push(aRemark);
      }

      aRemark = this.getUpsertRemark(remarks.operationalRemarks, ShipmentRemarkTypeCd.SHIPPING_RMK);
      if (aRemark && aRemark !== null) {
        remarkArray.push(aRemark);
      }

      aRemark = this.getUpsertRemark(remarks.pieceCount, ShipmentRemarkTypeCd.PIECE_CNT_RMK);
      if (aRemark && aRemark !== null) {
        remarkArray.push(aRemark);
      }

      aRemark = this.getUpsertRemark(remarks.osdContact, ShipmentRemarkTypeCd.OSD_CNTCT);
      if (aRemark && aRemark !== null) {
        remarkArray.push(aRemark);
      }
      return remarkArray.length > 0 ? remarkArray : undefined;
    }
  }

  private getUpsertRemark(remark: string, typeCode: ShipmentRemarkTypeCd): Remark {
    if (remark && remark.trim().length > 0) {
      const aRemark: Remark = new Remark();
      aRemark.typeCd = typeCode;
      if (remark) {
        const tempRemark = remark
          .replace(new RegExp(this.configManager.getSetting(ConfigManagerProperties.remarksSpaceReplacePattern), 'g'), ' ')
          .trim();
        aRemark.remark = tempRemark.length > 1000 ? tempRemark.trim().slice(0, 1000) : tempRemark;
      }
      aRemark.listActionCd = ActionCd.ADD;
      return aRemark;
    }
    return null;
  }
}
