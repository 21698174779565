import { Injectable } from '@angular/core';
import { Party } from '../../../bill-entry/model';
import { PARTY_TYPE_ID } from '../../../bill-entry/model/party';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../../core/model/config-manager-properties.enum';

@Injectable()
export class SalvageCenterValidationService {
  private salvageSic: string;
  private salvageMadcode: string;
  private salvageStreetAddress: string;
  private salvageZipCode: string;

  constructor(private configManager: ConfigManagerService) {
    this.salvageSic = this.configManager.getSetting(ConfigManagerProperties.salvageCenterSicCode);
    this.salvageMadcode = this.configManager.getSetting(ConfigManagerProperties.salvageCenterMadcode);
    this.salvageStreetAddress = this.configManager.getSetting(ConfigManagerProperties.salvageCenterStreeAddress);
    this.salvageZipCode = this.configManager.getSetting(ConfigManagerProperties.salvageCenterZipCode);
  }


  containsSalvageCenterParty(parties: Party[]) {

    return !!parties
      && !!parties.length
      && (this.isSalvageCenterParty(parties[PARTY_TYPE_ID.Shipper]) || this.isSalvageCenterParty(parties[PARTY_TYPE_ID.Consignee]));
  }

  isSalvageCenterParty(party: Party): boolean {
    return !!party
      && (this.isSalvageCenterAddress(party.address, party.zip) || this.isSalvageCenterMadcode(party.madCode));
  }

  isSalvageCenterAddress(address: string, zipCode: string): boolean {
    return (address && zipCode)
      ? (address.toUpperCase().startsWith(this.salvageStreetAddress.toUpperCase()) && zipCode === this.salvageZipCode)
      : false;
  }

  isSalvageCenterMadcode(madCode: string): boolean {
      return madCode ? (madCode.toUpperCase() === this.salvageMadcode.toUpperCase()) : false;
  }

  salvageCenterSic(): string {
    return this.salvageSic;
  }
}
