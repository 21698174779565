import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MatchCustomerBillingRqst,
  MatchCustomerBillingResp,
  ListCustCommonBolsRqst,
  ListCustCommonBolsResp,
  GetRelatedBillToForCustomerResp,
  GetRelatedBillToForCustomerQuery,
  ListCustCommonBolsQuery,
  CustomerApiService as SdkCustomerApiService,
  GetCustomerProfilePath,
  GetCustomerProfileResp,
  GetCustomerProfileQuery,
} from '@xpo-ltl/sdk-customer';
import moment from 'moment-timezone';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

@Injectable({providedIn:'root'})
export class CustomerApiService {
  _proceedSearching = new BehaviorSubject<boolean>(false);
  proceedSearching$ = this._proceedSearching.asObservable();
  
  constructor(private customerApiService: SdkCustomerApiService, private configManager: ConfigManagerService, private _http: HttpClient) {}

  customerLocationMatch(request: MatchCustomerBillingRqst): Observable<MatchCustomerBillingResp> {
    return this.customerApiService.matchCustomerBilling(request);
  }


  getCustomerBol(request: ListCustCommonBolsRqst): Observable<ListCustCommonBolsResp> {
    const queryParams: ListCustCommonBolsQuery = {
      ...new ListCustCommonBolsQuery(),
    };

    if (request.shipperCustNbr !== 0) {
      queryParams['shipperCustNbr'] = request.shipperCustNbr;
    }
    if (request.consigneeCustNbr !== 0) {
      queryParams['consigneeCustNbr'] = request.consigneeCustNbr;
    }
    if (request.billToCustNbr !== 0) {
      queryParams['billToCustNbr'] = request.billToCustNbr;
    }

    if (request.chargeToCd) {
      queryParams['chargeToCd'] = request.chargeToCd;
    }

    if (request.effectiveDate) {
      queryParams['effectiveDate'] = moment(request.effectiveDate)
        .tz('UTC')
        .format('MM/DD/YYYY');
    }

    return this.customerApiService.listCustCommonBols(queryParams);
  }

  getRelatedBillToForCustomer(request: GetRelatedBillToForCustomerQuery): Observable<GetRelatedBillToForCustomerResp> {
    return this.customerApiService.getRelatedBillToForCustomer(request);
  }

  getCustomerProfile(custAcctNbr: number): Observable<GetCustomerProfileResp> {
    const path: GetCustomerProfilePath = {
      ...new GetCustomerProfilePath(),
      custAcctNbr,
    };

    const query: GetCustomerProfileQuery = {
      ...new GetCustomerProfileQuery(),
    };

    return this.customerApiService.getCustomerProfile(path, query);
  }

  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.customerLocationApiEndpoint);
  }

  // Lotus API Calling strat here
  // Get rsponse from customer 2.0
  matchCustomers(data) {
    return this._http.post(`${this.configManager.getSetting(ConfigManagerProperties.apiUrl)}${this.configManager.getSetting(ConfigManagerProperties.customerLotusMatchEndPoint)}`, data).pipe(timeout(75000), catchError((error) => {
      throw error;
    }));
  }

  // Get billTo response accoring to customer 2.o calling
  getBillTo(customerNbr) {
    return this._http.get(`${this.configManager.getSetting(ConfigManagerProperties.apiUrl)}${this.configManager.getSetting(ConfigManagerProperties.invoicingInstructionsEndPoint)}/${customerNbr}`).pipe(timeout(75000), catchError((error) => {
      throw error;
    }));
  }

  generateURL(url) {
    return url.startsWith('/') ? url.substring(1) : url;
  }

   // Get billTo response accoring to customer 2.o calling
   verifyCustomer(cisNo) {
    return this._http.get(`${this.configManager.getSetting(ConfigManagerProperties.apiUrl)}${this.configManager.getSetting(ConfigManagerProperties.madCodeMatchEndPoint)}/${cisNo}?customerIdTypeCd=LegacyCisCustomerNumber`).pipe(timeout(75000), catchError((error) => {
      throw error;
    }));
  }
}
