import { Injectable } from '@angular/core';

import { ActionCd, CommodityPackageCd, ShipmentSourceCd, ChargeToCdHelper, BillStatusCd } from '@xpo-ltl/sdk-common';

import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { BillOfLading } from '../../../../shared/services/bol-api/model/bill-of-lading';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import {
  AdditionalInformation,
  Bill,
  BillingInstructions,
  Commodity,
  OrderDetails,
  OrderHeader,
  Party,
  PARTY_TYPE_ID,
  Remarks,
  SpecialInstructions,
  SupplementalReferenceNumber,
  SupplementalReferenceNumberCollection,
} from '../../../bill-entry/model';
import { BillSource } from '../../../bill-entry/model/bill-source.enum';
import { DateTimeHelper } from '../../util/date-time-helper';

import { CommoditiesTransformerService } from './commodities-transformer.service';

import { AccessorialTransformer } from './components/accessorial-transformer';
import { CountryTransformer } from './components/country-transformer';

@Injectable()
export class BillOfLadingTransformerService {
  constructor(
    private commoditiesTransformerService: CommoditiesTransformerService,
    private loggingApiService: LoggingApiService
  ) {}

  private PALLET_COUNT_TYPES: CommodityPackageCd[] = [CommodityPackageCd.PALLET, CommodityPackageCd.SKID];

  toBill(bol: BillOfLading): Observable<Bill> {
    const billObservable = new ReplaySubject<Bill>(1);
    const bill: Bill = new Bill();
    bill.source = BillSource.BOL;

    // Order Header
    bill.orderHeader = new OrderHeader();
    bill.orderHeader.debtorTypeCode = ChargeToCdHelper.toEnum(bol.bolPrimaryDetails.bolDoc.chargeCd);
    bill.orderHeader.pickupDate = DateTimeHelper.conditionDateFromServer(
      new Date(bol.bolPrimaryDetails.bolpickLst.pkupDt)
    );
    bill.orderHeader.proNumber = bol.bolPrimaryDetails.bolDoc.proNumberTxt;
    bill.orderHeader.relatedProNumber = null; // TODO, bol.bolPrimaryDetails.bolDoc.refNumberTxt?
    bill.orderHeader.bolInstanceId = bol.bolPrimaryDetails.bolInstanceId;
    bill.orderHeader.bolVersionNumber = bol.bolPrimaryDetails.bolDoc.versionNbr;
    bill.orderHeader.shipmentInstanceId = bol.bolPrimaryDetails.bolDoc.shpInstanceId;
    bill.orderHeader.shipmentSourceCd = this.convertSourceCd(bol.bolPrimaryDetails.bolDoc.sourcecd);

    // Parties
    bill.asEnteredBolParties[PARTY_TYPE_ID.Shipper] = this.mapParty(
      bol.bolPrimaryDetails.shipper,
      PARTY_TYPE_ID.Shipper,
      bol.bolPrimaryDetails.bolDoc.origTrmnlSic,
      bol.bolPrimaryDetails.bolDoc.shpInstanceId
    );

    bill.asEnteredBolParties[PARTY_TYPE_ID.Consignee] = this.mapParty(
      bol.bolPrimaryDetails.consignee,
      PARTY_TYPE_ID.Consignee,
      bol.bolPrimaryDetails.bolDoc.destTrmnlSic,
      bol.bolPrimaryDetails.bolDoc.shpInstanceId
    );

    bill.asEnteredBolParties[PARTY_TYPE_ID.BillTo] = this.mapParty(
      bol.bolPrimaryDetails.billTo,
      PARTY_TYPE_ID.BillTo,
      undefined,
      bol.bolPrimaryDetails.bolDoc.shpInstanceId
    );

    // SRNs
    bill.supplementalReferenceNumberCollection = new SupplementalReferenceNumberCollection();
    bill.supplementalReferenceNumberCollection.referenceNumbers = [];

    if (bol.bolSupplementalRefDetails) {
      bol.bolSupplementalRefDetails.forEach((bolSRN) => {
        const srn = new SupplementalReferenceNumber();
        srn.number = bolSRN.refNumberTxt;
        srn.referenceType = bolSRN.refCd;
        srn.sequenceNbr = -1;
        srn.actionCd = ActionCd.ADD;
        bill.supplementalReferenceNumberCollection.referenceNumbers.push(srn);
      });
    }
    // Special Services
    bill.specialInstructions = new SpecialInstructions();
    bill.specialInstructions.freezableInd = bol.bolPrimaryDetails.bolDoc.freezableInd;
    bill.specialInstructions.guaranteedInd = bol.bolPrimaryDetails.bolDoc.guaranteedInd;
    bill.specialInstructions.hazmatInd = bol.bolPrimaryDetails.bolDoc.hazmatInd;
    bill.specialInstructions.codInd = bol.bolPrimaryDetails.bolCodCustomer.codAmount !== 0;

    // Accessorial
    bill.accessorials = AccessorialTransformer.toBill(bol.bolAccessorialDetails);

    if (AccessorialTransformer.hasRRSAccessorial(bol.bolAccessorialDetails)) {
      bill.specialInstructions.rapidRemoteServiceInd = true;
    }

    if (AccessorialTransformer.hasG12Accessorial(bol.bolAccessorialDetails)) {
      bill.specialInstructions.g12Ind = true;
    }

    if (AccessorialTransformer.hasExcessiveValueAccessorial(bol.bolAccessorialDetails)) {
      bill.specialInstructions.excessiveValueInd = true;
    }
    // Add flag if accessorial exists or accessorial if flag exists
    if (AccessorialTransformer.hasGuaranteedAccessorial(bol.bolAccessorialDetails)) {
      bill.specialInstructions.guaranteedInd = true;
    } else if (bill.specialInstructions.guaranteedInd) {
      // May not have accessorials yet
      if (!bill.accessorials) {
        bill.accessorials = [];
      }
      bill.accessorials.push(AccessorialTransformer.createAccessorial(AccessorialTransformer.GurCode));
    } else if (bol.bolPrimaryDetails.bolCodCustomer.codAmount) {
      if (!bill.accessorials) {
        bill.accessorials = [];
      }
      bill.accessorials.push(AccessorialTransformer.createAccessorial(AccessorialTransformer.CodCode));
    }

    // --- ADDITIONAL INFORMATION ---
    if (bol.bolPrimaryDetails.bolCodCustomer.codAmount) {
      bill.additionalInformation = new AdditionalInformation();
      bill.additionalInformation.codPaymentTypeCode = bol.bolPrimaryDetails.bolCodCustomer.paymentTypeCode;
      bill.additionalInformation.codAmount = bol.bolPrimaryDetails.bolCodCustomer.codAmount;
      bill.additionalInformation.codSequenceNbr = bol.bolPrimaryDetails.bolCodCustomer.codSequenceNumber;
    }

    // --- REMARKS ---
    bill.remarks = new Remarks();
    if (bol.bolPrimaryDetails) {
      if (bol.bolPrimaryDetails.shpRemarkTxt && bol.bolPrimaryDetails.shpRemarkTxt.length > 0) {
        bill.remarks.operationalRemarks = bol.bolPrimaryDetails.shpRemarkTxt;
      }

      if (
        bol.bolPrimaryDetails.hazmatRemarkTxt &&
        bol.bolPrimaryDetails.hazmatRemarkTxt.length > 0 &&
        'AdhocHzMatlRmk' === bol.bolPrimaryDetails.hazmatRemarkTypeCd
      ) {
        bill.remarks.hazmatEmergencyContactInfo = bol.bolPrimaryDetails.hazmatRemarkTxt;
      }
    }

    bill.orderDetails = new OrderDetails();
    bill.orderDetails.totalPieces = bol.bolPrimaryDetails.totalPiecesCnt;
    bill.orderDetails.initialTotalPieces = bol.bolPrimaryDetails.totalPiecesCnt;
    bill.orderDetails.totalWeight = bol.bolPrimaryDetails.totalWeightLbs;
    bill.orderDetails.initialTotalWeight = bol.bolPrimaryDetails.totalWeightLbs;

    bill.billingInstructions = new BillingInstructions();

    this.commoditiesTransformerService
      .mapCommodity(bol.bolCommodityDetails, true, bill.orderHeader.billStatusCode !== BillStatusCd.BILLED)
      .pipe(take(1))
      .subscribe(
        (results: Commodity[]) => {
          const totalPalletCount = results
            .filter((currentItem) => currentItem.packaging && this.PALLET_COUNT_TYPES.includes(currentItem.packaging))
            .map((item) => item.pieces)
            .reduce((accumulator, currentItem) => (accumulator += currentItem), 0);

          bill.orderDetails.totalPalletCount = totalPalletCount;
          bill.orderDetails.commodities = results;
          billObservable.next(bill);
          billObservable.complete();
        },
        (error) => {
          this.loggingApiService.error(`Error while parsing commodity information.  ${error.message}`);
        }
      );

    return billObservable;
  }

  private mapParty(bolShipper, partyId: PARTY_TYPE_ID, sicCd: string, shipmentInstanceId: number): Party {
    const ret: Party = new Party(partyId);
    ret.address = bolShipper.addressLine1 + bolShipper.addressLine2;
    ret.city = bolShipper.city;
    ret.country = CountryTransformer.toBill(bolShipper.countryCode);
    ret.customerNumber = bolShipper.cisCustomerNumber;
    ret.extension = bolShipper.phonrextensionNumber;
    ret.madCode = bolShipper.cisMadCode;
    ret.name1 = bolShipper.customerName1;
    ret.name2 = bolShipper.customerName2;
    ret.sicCd = sicCd;
    ret.phone = `${bolShipper.pacdNumber && bolShipper.pacdNumber.trim().length > 0 ? bolShipper.pacdNumber : ''}${
      bolShipper.phonNumber
    }`;
    ret.state = bolShipper.stateCode;
    ret.zip = bolShipper.zip6;
    ret.zip4 = bolShipper.zip4;
    ret.actionCd = !shipmentInstanceId || partyId === PARTY_TYPE_ID.BillTo ? ActionCd.ADD : ActionCd.NO_ACTION;
    ret.sequenceNbr = partyId + 1;
    return ret;
  }

  private convertSourceCd(sourceCd: string): ShipmentSourceCd {
    if (sourceCd) {
      switch (sourceCd.toUpperCase()) {
        case 'X':
        case 'B':
        case 'T':
          return ShipmentSourceCd.WEB;
        case 'E':
          return ShipmentSourceCd.EDI;
        case 'F':
          return ShipmentSourceCd.OCR;
      }
    }
    return undefined;
  }
}
