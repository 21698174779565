export enum AdditionalInformationFormFields {
  IsTrueBlind = 'isTrueBlind',
  IsBlind = 'isBlind',
  ShipperName = 'shipperName',
  ShipperAddress = 'shipperAddress',
  ShipperCity = 'shipperCity',
  ShipperState = 'shipperState',
  ShipperZip = 'shipperZip',
  ShipperZip4 = 'shipperZip4',
  ShipperCountryCd = 'shipperCountryCd',
  CodAmount = 'codAmount',
  CodPaymentTypeCode = 'codPaymentTypeCode',
  PartPrepaidAmount = 'partPrepaidAmount',
  PartPrepaidRemarks = 'partPrepaidRemarks',
  PartCollectAmount = 'partCollectAmount',
  PartCollectRemarks = 'partCollectRemarks',
  DriverCash = 'driverCash',
  CheckNumber = 'checkNumber',
  HasCustomsInvoiceDocument = 'hasCustomsInvoiceDocument',
  HasPackingListDocument = 'hasPackingListDocument',
  HasSupplementalPagesDocument = 'hasSupplementalPagesDocument',
  HasCBPInbondDocument = 'hasCBPInbondDocument',
  InbondNumber = 'inbondNumber',
  InbondStateCode = 'inbondStateCode',
  InbondCity = 'inbondCity',
  LinealFoot = 'linealFoot',
  DeclaredValueAmount = 'declaredValueAmount',
  AdvancedCarrierSCAC = 'advancedCarrierSCAC',
  AdvancedCarrierPickupDate = 'advancedCarrierPickupDate',
  AdvancedCarrierTrackingNumber =  'advancedCarrierTrackingNumber',
  BeyondCarrierSCAC = 'beyondCarrierSCAC',
  BeyondCarrierPickupDate = 'beyondCarrierPickupDate',
  BeyondCarrierTrackingNumber =  'beyondCarrierTrackingNumber'
}
