export interface MarinePollutantClassification {
  chemicalName: string;
}

export enum MarinePollutantFormFields {
  ChemicalName = 'chemicalName',
}

export class MarinePollutantClassificationHelper {
  static hasValue(value: MarinePollutantClassification): boolean {
    return !!value && !!value.chemicalName;
  }
}
