import { ActionCd } from '@xpo-ltl/sdk-common';
import { AccessorialService } from '@xpo-ltl/sdk-shipment';
import { BolAccessorialDetail } from '../../../../../shared/services/bol-api/model/bol-accessorial-detail';
import { Accessorial } from '../../../../bill-entry/model/accessorial';

export class AccessorialTransformer {
  static readonly EvcCode = 'EVC';
  static readonly GurCode = 'GUR';
  static readonly CodCode = 'COD';
  static readonly RrsCode = 'RRS';
  static readonly G12Code = 'G12';

  static toUpsertShipment(accessorials: Accessorial[]): AccessorialService[] {
    if (!accessorials || accessorials.length <= 0) {
      return;
    }
    const accessorialServices: AccessorialService[] = [];
    accessorials.forEach((accessorial) => {
      if (Accessorial.hasValue(accessorial) && accessorial.accessorialCd.trim().length === 3) {
        const accessorialService = new AccessorialService();
        accessorialService.chrgToCd = accessorial.chargeToCode;
        accessorialService.accessorialCd = accessorial.accessorialCd;
        accessorialService.description = accessorial.accessorialDesc;
        accessorialService.amount = accessorial.amount && accessorial.amount >= 0 ? accessorial.amount : 0;
        accessorialService.accessorialQuantity = accessorial.quantity;
        accessorialService.accessorialUnitOfMeasure = accessorial.unitOfMeasure;
        accessorialService.listActionCd = accessorial.actionCd;
        accessorialService.sequenceNbr = `${accessorial.sequenceNbr}`;
        accessorialServices.push(accessorialService);
      }
    });
    return accessorialServices;
  }

  static toBill(bolAccessorials: BolAccessorialDetail[]): Accessorial[] {
    if (!bolAccessorials || bolAccessorials.length <= 0) {
      return;
    }

    return bolAccessorials.map((bolAccesorial) => AccessorialTransformer.createAccessorial(bolAccesorial.accsrlcd));
  }
  static toAccessorial(accessorialServices: AccessorialService[]): Accessorial[] {
    let accessorials = [];
    if (accessorialServices && accessorialServices.length > 0) {
      accessorials = accessorialServices.map((accSvc) => {
        const accessorial = new Accessorial();
        accessorial.accessorialDesc = accSvc.description;
        accessorial.amount = accSvc.amount;
        accessorial.accessorialCd = accSvc.accessorialCd;
        accessorial.quantity = accSvc.accessorialQuantity;
        accessorial.unitOfMeasure = accSvc.accessorialUnitOfMeasure;
        accessorial.actionCd = accSvc.listActionCd;
        return accessorial;
      });
    }
    return accessorials;
  }

  static createAccessorial(accessorialCode: string): Accessorial {
    const accessorial = new Accessorial();
    accessorial.accessorialCd = accessorialCode;
    accessorial.sequenceNbr = -1;
    accessorial.actionCd = ActionCd.ADD;
    return accessorial;
  }

  static hasGuaranteedAccessorial(bolAccessorials: BolAccessorialDetail[]): boolean {
    return this.hasCode(bolAccessorials, this.GurCode);
  }

  static hasExcessiveValueAccessorial(bolAccessorials: BolAccessorialDetail[]): boolean {
    return this.hasCode(bolAccessorials, this.EvcCode);
  }

  static hasRRSAccessorial(bolAccessorials: BolAccessorialDetail[]): boolean {
    return this.hasCode(bolAccessorials, this.RrsCode);
  }

  static hasG12Accessorial(bolAccessorials: BolAccessorialDetail[]): boolean {
    return this.hasCode(bolAccessorials, this.G12Code);
  }

  private static hasCode(bolAccessorials: BolAccessorialDetail[], code: string): boolean {
    let hasCode = false;
    if (bolAccessorials && bolAccessorials.length > 0) {
      hasCode = bolAccessorials.some((a) => a.accsrlcd.toUpperCase() === code);
    }
    return hasCode;
  }
}
