import { Pipe, PipeTransform } from '@angular/core';
import { MatchedPartySourceCd, MatchedPartyStatusCd } from '@xpo-ltl/sdk-common';
import { Party } from '../../bill-entry/bill-entry/model';

@Pipe({
  name: 'matchInfo',
})
export class MatchInfoPipe implements PipeTransform {
  private statusText = {
    [MatchedPartyStatusCd.DERIVED_BILL_TO]: 'DERIVED BILL TO',
    [MatchedPartyStatusCd.INVALID_ADDRESS]: 'INVALID ADDRESS',
    [MatchedPartyStatusCd.MCH]: '',
    [MatchedPartyStatusCd.NO_DERIVED_BILL_TO]: 'NO DERIVED BILL TO',
    [MatchedPartyStatusCd.NO_DERIVED_BILL_TO_SELF_INV]: 'NO DERIVED BILL TO SELF INVOICING',
    [MatchedPartyStatusCd.POSSIBLES_FOUND]: 'POSSIBLES FOUND',
    [MatchedPartyStatusCd.UN_MCH]: '',
  };

  private sourceText = {
    [MatchedPartySourceCd.NOT_MATCHED]: 'NOT MATCHED',
    [MatchedPartySourceCd.NOT_MATCHED_INVALID_ADDRESS_OVERRIDE]: 'NOT MATCHED INVALID ADDRESS',
    [MatchedPartySourceCd.SYSTEM_MATCHED]: 'SYSTEM MATCHED',
    [MatchedPartySourceCd.USER_SELECTED]: 'USER SELECTED',
    [MatchedPartySourceCd.USER_SELECTED_SEARCH]: 'USER SELECTED SEARCH RESULT',
  };

  transform(value: Party, args?: any): any {
    let returnValue = '';
    if (!!value) {
      returnValue = `${value.matchedSourceCd ? this.sourceText[value.matchedSourceCd] : ''}`;
      if (value.matchedSourceCd === MatchedPartySourceCd.NOT_MATCHED) {
        returnValue = `${returnValue} ${value.matchedStatusCd ? this.statusText[value.matchedStatusCd] : ''}`;
      }
    }
    return returnValue.trim();
  }
}
