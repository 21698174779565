<xpo-card xpoDialog>
  <xpo-card-header>
    <xpo-card-title>
      Browser Version
    </xpo-card-title>
  </xpo-card-header>
  <xpo-card-content>
    <p>Edge Order Entry requires the <b>latest version of Chrome.</b></p>
    <p>
      Please locate the <b>Edge Order Entry</b> icon on your desktop/Citrix to
      access the application.
    </p>
    <hr />
    <p>
      Current Version: {{ data.currentVersion }}<br />
      Minimum Version: {{ data.minVersion }}
    </p>
  </xpo-card-content>
</xpo-card>
