import { Injectable } from '@angular/core';
import { UpsertShipmentRqst } from '@xpo-ltl/sdk-shipment';
import * as _ from 'lodash';
import { PARTY_TYPE_ID } from '../../../bill-entry/model/party';
import { AsEnteredBolPartyValidator } from './validator/as-entered-bol-party-validator';
import { AsMatchedPartyValidator } from './validator/as-matched-party-validator';
import { BaseValidation } from './validator/base-validation';
import { CommodityElementsNotBlank } from './validator/commodity-elements-not-blank';
import { DateRangeValidator } from './validator/date-range-validator';
import { ProFormatValidator } from './validator/pro-format-validator';
import { Severity } from './validator/severity.enum';
import { TdcValidator } from './validator/tdc-validator';
import { XpoValidationError } from './xpo-validation-error';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../../core/model/config-manager-properties.enum';

@Injectable()
export class BillValidatorService {
  private validators: Array<BaseValidation<any>> = [];

  constructor(private configManager: ConfigManagerService) {
    this.initializeValidators();
  }

  validate(request: UpsertShipmentRqst): XpoValidationError[] {
    let validationErrors: XpoValidationError[] = [];
    this.validators.forEach((validator) => {
      const value: any = _.get(request, validator.fieldName);
      if (!validator.isValid(value)) {
        validationErrors = validationErrors.concat(validator.getValidationErrors());
        validator.resetValidationErrors();
      }
    });
    return validationErrors;
  }

  private initializeValidators() {
    this.validators.push(new ProFormatValidator('1', 'Invalid PRO number format', 'shipment.proNbr', Severity.Error));

    this.validators.push(new CommodityElementsNotBlank('2', 'Commodity data invalid', 'commodity', Severity.Error));
    this.validators.push(
      new DateRangeValidator(
        this.configManager.getSetting(ConfigManagerProperties.minPickupDateDays),
        30,
        '4',
        'Pickup date out of range',
        'shipment.pickupDate',
        Severity.Error
      )
    );
    // We are disabling this validation to resolve the ticket LCS-17194
    // this.validators.push(new TdcValidator(30, 30, '5', 'Tdc values invalid', 'timeDateCritical', Severity.Error));

    this.validators.push(
      new AsEnteredBolPartyValidator(
        PARTY_TYPE_ID.Shipper,
        '6',
        'As entered shipper party invalid',
        'asEnteredBolParty[0]',
        Severity.Error
      )
    );

    this.validators.push(
      new AsEnteredBolPartyValidator(
        PARTY_TYPE_ID.Consignee,
        '7',
        'As entered consignee party invalid',
        'asEnteredBolParty[1]',
        Severity.Error
      )
    );

    this.validators.push(
      new AsEnteredBolPartyValidator(
        PARTY_TYPE_ID.BillTo,
        '8',
        'As entered bill-to party invalid',
        'asEnteredBolParty[2]',
        Severity.Error
      )
    );

    this.validators.push(
      new AsMatchedPartyValidator(
        PARTY_TYPE_ID.Shipper,
        '9',
        'As entered shipper party invalid',
        'asMatchedParty[0]',
        Severity.Error
      )
    );

    this.validators.push(
      new AsMatchedPartyValidator(
        PARTY_TYPE_ID.Consignee,
        '10',
        'As entered consignee party invalid',
        'asMatchedParty[1]',
        Severity.Error
      )
    );

    this.validators.push(
      new AsMatchedPartyValidator(
        PARTY_TYPE_ID.BillTo,
        '11',
        'As entered bill-to party invalid',
        'asMatchedParty[2]',
        Severity.Error
      )
    );
  }
}
