import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SendEmailRqst } from '@xpo-ltl/api-infrastructure';
import { BehaviorSubject, Subject } from 'rxjs';

import { User } from '../model/user';
import { InfrastructureApiService } from '../services/infrastructure-api/infrastructure-api.service';
import { NotificationService } from '../services/notification/notification.service';
import { UserApiService } from '../services/user-api/user-api.service';
import { FEEDBACK_FORM_CONFIG, XpoFeedbackFormConfig } from './feedback-form.config';
import { takeUntil } from 'rxjs/operators';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { MatDialogRef } from '@angular/material/dialog';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';

enum FeedbackFormFields {
  FromName = 'fromName',
  Subject = 'subject',
  Message = 'message',
}

@Component({
  selector: 'xpo-feedback-form',
  templateUrl: './feedback-form.component.html',
  styleUrls: ['./feedback-form.component.scss']
})
export class XpoFeedbackFormComponent implements OnInit {
  feedbackFormGroup: FormGroup;
  FeedbackFormFields = FeedbackFormFields;
  feedbackFormConfig: XpoFeedbackFormConfig;
  disableSend$ = new BehaviorSubject<boolean>(false);
  private loggedInUser: User;
  private _unsubscribe = new Subject<boolean>(); 
  @ViewChild('autosize') autosize: CdkTextareaAutosize;

  constructor(
    @Inject(FEEDBACK_FORM_CONFIG) config: XpoFeedbackFormConfig,
    private infrastructureApi: InfrastructureApiService,
    private dialogRef: MatDialogRef<XpoFeedbackFormComponent>,
    private notificationService: NotificationService,
    private userService: UserApiService,
    private snackBar: XpoSnackBar
  ) {
    this.feedbackFormConfig = this.mapConfig(config);
    this.userService.loggedInUserData$.pipe(takeUntil(this._unsubscribe)).subscribe((response:any) =>{
      this.loggedInUser = response;
    })
  }

  ngOnInit() {
    this.initializeForm();
  }

  cancel(): void {
    this.dialogRef.close(false);
  }

  send(): void {
    this.disableSend$.next(true);
    this.infrastructureApi.sendEmail(this.createEmail()).then(
      (resolve) => {
        this.disableSend$.next(false);
        this.dialogRef.close(true);
        this.snackBar.open({
          message: 'Feedback was successfully sent.',
          matConfig:{
            duration:3000,
            verticalPosition: 'bottom',
          },
          status:'success'
        })
      },
      (reject) => {
        this.disableSend$.next(false);
        this.snackBar.open({
          message: 'Feedback Failed to send.',
          matConfig:{
            duration:3000,
            verticalPosition: 'bottom',
          },
          status:'error'
        })
      }
    );
  }

  private mapConfig(config: XpoFeedbackFormConfig): XpoFeedbackFormConfig {
    return {
      disableFromNameInput: config.disableFromNameInput || false,
      disableSubjectInput: config.disableSubjectInput || false,
      fromEmail: config.fromEmail || '',
      fromName: config.fromName || '',
      invalidCharacterPattern: config.invalidCharacterPattern || '',
      message: config.message || '',
      subject: config.subject || '',
      title: config.title || 'Feedback',
      toEmail: config.toEmail || '',
    };
  }

  private initializeForm(): void {
    this.feedbackFormGroup = new FormGroup({
      [FeedbackFormFields.FromName]: new FormControl(
        {
          value: this.feedbackFormConfig.fromName,
          disabled: this.feedbackFormConfig.disableFromNameInput
        },
        [Validators.required]
      ),
      [FeedbackFormFields.Subject]: new FormControl(
        {
          value: this.feedbackFormConfig.subject,
          disabled: this.feedbackFormConfig.disableSubjectInput,
        },
        [Validators.required, Validators.pattern(this.feedbackFormConfig.invalidCharacterPattern)]
      ),
      [FeedbackFormFields.Message]: new FormControl(
        this.feedbackFormConfig.message,
        [Validators.required, Validators.pattern(this.feedbackFormConfig.invalidCharacterPattern)]
      ),
    });
  }

  private createEmail(): SendEmailRqst {
    const formValue = this.feedbackFormGroup.getRawValue();

    return {
      emailMessage: {
        from: this.feedbackFormConfig.fromEmail,
        to: this.feedbackFormConfig.toEmail,
        fromName: `${this.loggedInUser.givenName} ${this.loggedInUser.lastName}<${this.feedbackFormConfig.fromEmail}>`,
        subject: formValue[FeedbackFormFields.Subject],
        messageBody: `${formValue[FeedbackFormFields.Message]} - ${formValue[FeedbackFormFields.FromName]}`,
      }
    } as SendEmailRqst;
  }

  ngOnDestroy(): void{
    this._unsubscribe.next(true);
    this._unsubscribe.complete();
  }
}
