import { Pipe, PipeTransform } from '@angular/core';
import { MatchedPartyStatusCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'matchPartyStatusCd'
})
export class MatchedPartyStatusCdPipe implements PipeTransform {

  transform(matchedStatus: MatchedPartyStatusCd, args?: any): any {
      let returnLabel = 'NOT YET MATCHED';
      if (matchedStatus) {
        switch (matchedStatus) {
          case MatchedPartyStatusCd.MCH:
            returnLabel = 'MATCHED';
            break;
          case MatchedPartyStatusCd.INVALID_ADDRESS:
            returnLabel = 'INVALID ADDRESS';
            break;
          case MatchedPartyStatusCd.POSSIBLES_FOUND:
          case MatchedPartyStatusCd.NO_DERIVED_BILL_TO:
          case MatchedPartyStatusCd.UN_MCH:
            returnLabel = 'UNMATCHED';
            break;
          case MatchedPartyStatusCd.DERIVED_BILL_TO:
            returnLabel = 'MATCHED DERIVED';
            break;
          case MatchedPartyStatusCd.NO_DERIVED_BILL_TO_SELF_INV:
            returnLabel = 'N/A';
            break;
          default:
            returnLabel = 'UNKNOWN';
            break;
        }
      }
      return returnLabel;
  }
}
