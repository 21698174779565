import { NotificationTypeEnum } from './notification-type-enum';
import { SnackbarConfig } from './snackbar-config';

export class NotificationMessage {
  type: NotificationTypeEnum;
  text: string;
  snackbarConfig: SnackbarConfig = null;
  status = 'success';

  constructor(type: NotificationTypeEnum, text: string, snackbarConfig?: SnackbarConfig) {
    this.type = type;
    this.text = text;
    this.snackbarConfig = snackbarConfig;
  }
}
