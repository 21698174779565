import { Pipe, PipeTransform } from '@angular/core';
import { Party } from '../../bill-entry/bill-entry/model/party';
import { CustomerMatchViewModel } from '../../bill-entry/customer-match/customer-match-view-model';

@Pipe({
  name: 'partyFormatter',
})
export class PartyFormatterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    let formattedParty = '';
    if (value) {
      if (value instanceof CustomerMatchViewModel) {
        const temp = value as CustomerMatchViewModel;
        if (temp.name) {
          formattedParty += `${temp.name ? temp.name.toLowerCase() : ''} • `;
        }
        if (temp.name2) {
          formattedParty += `${temp.name2 ? temp.name2.toLowerCase() : ''} • `;
        }
        if (temp.address) {
          formattedParty += `${temp.address ? temp.address.toLowerCase() : ''}, `;
        }
        if (temp.city) {
          formattedParty += `${temp.city ? temp.city.toLowerCase() : ''}, `;
        }
        if (temp.state) {
          formattedParty += `${temp.state} `;
        }
        if (temp.zip) {
          formattedParty += `${temp.zip}`;
        }
        if (temp.zip4) {
          formattedParty += `-${temp.zip4}`;
        }
        formattedParty += ' • ';
        if (temp.country) {
          formattedParty += `${temp.country}`;
        }
      } else {
        const temp = value as Party;
        if (temp.name1) {
          formattedParty += `${temp.name1 ? temp.name1.toLowerCase() : ''} • `;
        }
        if (temp.name2) {
          formattedParty += `${temp.name2 ? temp.name2.toLowerCase() : ''} • `;
        }
        if (temp.address) {
          formattedParty += `${temp.address ? temp.address.toLowerCase() : ''}, `;
        }
        if (temp.city) {
          formattedParty += `${temp.city ? temp.city.toLowerCase() : ''}, `;
        }
        if (temp.state) {
          formattedParty += `${temp.state} `;
        }
        if (temp.zip) {
          formattedParty += `${temp.zip}`;
        }
        if (temp.zip4) {
          formattedParty += `-${temp.zip4}`;
        }
        formattedParty += ' • ';
        if (temp.country && temp.country.name) {
          formattedParty += `${temp.country.name}`;
        }
      }
    }
    return formattedParty;
  }
}
