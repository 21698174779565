import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';


import { ConfirmDialogData } from './confirm-dialog-data';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'xpo-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ConfirmDialogComponent implements ConfirmDialogData {
  private _title: string;
  private _message: string;
  private _confirmText: string;
  private _cancelText: string;
  private _showCancelButton: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: any,
    private dialogRef: MatDialogRef<ConfirmDialogComponent>
  ) {
    this._title = dialogData.title;
    this._message = dialogData.message;
    this._confirmText = !!dialogData.confirmText ? dialogData.confirmText : 'Confirm';
    this._cancelText = !!dialogData.cancelText ? dialogData.cancelText : 'Cancel';
    this._showCancelButton = dialogData.showCancelButton !== undefined ? dialogData.showCancelButton : true;
  }

  get title(): string {
    return this._title;
  }

  get message(): string {
    return this._message;
  }

  get confirmText(): string {
    return this._confirmText;
  }

  get cancelText(): string {
    return this._cancelText;
  }

  get showCancelButton(): boolean {
    return this._showCancelButton;
  }

  handleCancelClicked() {
    this.dialogRef.close(false);
  }

  handleConfirmClicked() {
    this.dialogRef.close(true);
  }
}
