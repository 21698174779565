import { OnInit, HostBinding, Directive } from '@angular/core';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../core/model/config-manager-properties.enum';

@Directive({
  selector: '[xpoProdStyles]',
})
export class XpoProdStylesDirective implements OnInit {
  private prodColor = '#DFDFDF';
  private nonProdColor = '#616161';

  @HostBinding('style.background-color')
  backgroundColor;

  constructor(private config: ConfigManagerService) {}

  ngOnInit() {
    this.backgroundColor = this.config.getSetting(ConfigManagerProperties.isProd) ? this.prodColor : this.nonProdColor;
  }
}
