import { AsMatchedParty } from '@xpo-ltl/sdk-shipment';
import { isNullOrUndefined } from '../../../../../shared/services/tools/tools.service';
import { PARTY_TYPE_ID } from '../../../../bill-entry/model/party';
import { XpoValidationError } from '../xpo-validation-error';
import { BaseValidation } from './base-validation';
import { Severity } from './severity.enum';

export class AsMatchedPartyValidator extends BaseValidation<AsMatchedParty> {
  private validationErrors: XpoValidationError[] = [];

  constructor(
    private partyTypeId: PARTY_TYPE_ID,
    errorCode?: string,
    errorDescription?: string,
    fieldName?: string,
    severity?: Severity
  ) {
    super(errorCode, errorDescription, fieldName, severity);
  }

  isValid(value: AsMatchedParty): boolean {
    this.validationErrors.length = 0;

    if (isNullOrUndefined(value)) {
      if (this.partyTypeId !== PARTY_TYPE_ID.BillTo) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing matched bill party information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}]`,
        });
      }
    } else {
      if (!this.hasValue(value.name1)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing name information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].name1`,
        });
      }
      if (!this.hasValue(value.address)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing address information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].address`,
        });
      }
      if (!this.hasValue(value.city)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing city information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].city`,
        });
      }
      if (!this.hasValue(value.stateCd)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing state information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].stateCd`,
        });
      }
      if (!this.hasValue(value.zip6)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing zip information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].zip6`,
        });
      }
      if (!this.hasValue(value.countryCd)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing country code information from ${this.getPartyName()}`,
          errorField: `AsEnteredBolParty[${this.partyTypeId}].countryCd`,
        });
      }
      if (!this.hasValue(value.listActionCd)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing action code information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].listActionCd`,
        });
      }
      if (!this.hasValue(value.matchedSourceCd)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing match source code information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].matchedSourceCd`,
        });
      }
      if (!this.hasValue(value.matchedStatusCd)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing match status code information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].matchedStatusCd`,
        });
      }
      if (!this.hasValue(value.sequenceNbr)) {
        this.validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: `Missing sequence number information from ${this.getPartyName()}`,
          errorField: `AsMatchedParty[${this.partyTypeId}].sequenceNbr`,
        });
      }
    }
    return this.validationErrors.length <= 0;
  }
  private getPartyName(): string {
    return this.partyTypeId === PARTY_TYPE_ID.Shipper
      ? 'shipper'
      : this.partyTypeId === PARTY_TYPE_ID.Consignee
      ? 'consignee'
      : 'billto';
  }
  private hasValue(value: string): boolean {
    return !isNullOrUndefined(value) && value.trim().length > 0;
  }

  public override resetValidationErrors() {
    this.validationErrors.length = 0;
  }

  public override getValidationErrors(): XpoValidationError[] {
    return this.validationErrors;
  }
}
