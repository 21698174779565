import { ActionCd } from '@xpo-ltl/sdk-common';
import { Commodity } from '@xpo-ltl/sdk-shipment';
import { XpoValidationError } from '../xpo-validation-error';
import { BaseValidation } from './base-validation';
import { Severity } from './severity.enum';

export class CommodityElementsNotBlank extends BaseValidation<any> {
  private _validationErrors: XpoValidationError[] = [];

  constructor(errorCode?: string, errorDescription?: string, fieldName?: string, severity?: Severity) {
    super(errorCode, errorDescription, fieldName, severity);
  }

  isValid(value: Commodity[]): boolean {
    if (!this.checkExists(value)) {
      this._validationErrors.push({
        severity: this.severity,
        errorCode: this.errorCode,
        errorMessage: 'No Commodities Found',
        errorField: 'commodity',
      });
      return false;
    }

    for (let i = 0; i < value.length; i++) {
      this.validateCommodity(value[i]);
    }

    return this._validationErrors.length === 0;
  }

  public override getValidationErrors(): XpoValidationError[] {
    return this._validationErrors;
  }

  public override resetValidationErrors() {
    this._validationErrors = [];
  }

  /**
   * Do we have at least one actionable commodity
   * @param {Commodity[]} commodities
   * @returns {boolean}
   */
  private checkExists(commodities: Commodity[]): boolean {
    if (!commodities || commodities.length === 0) {
      return false;
    }

    for (let i = 0; i < commodities.length; i++) {
      if (commodities[i].listActionCd !== ActionCd.DELETE) {
        return true;
      }
    }
  }

  /**
   * Make sure required elements have entries
   * @param {Commodity} aCommodity
   */
  private validateCommodity(aCommodity: Commodity) {
    if (!aCommodity.sequenceNbr || aCommodity.sequenceNbr.trim().length === 0) {
      this._validationErrors.push({
        severity: this.severity,
        errorCode: this.errorCode,
        errorMessage: 'Commodity Sequence Number Must Exist',
        errorField: 'commodity.sequenceNbr',
      });
    }

    if (!aCommodity.listActionCd || aCommodity.listActionCd.trim().length === 0) {
      this._validationErrors.push({
        severity: this.severity,
        errorCode: this.errorCode,
        errorMessage: 'Commodity Action Code Must Exist',
        errorField: 'commodity.listActionCd',
      });
    }

    if (aCommodity.listActionCd !== ActionCd.DELETE && aCommodity.listActionCd !== ActionCd.NO_ACTION) {
      if (!aCommodity.description || aCommodity.description.trim().length === 0) {
        this._validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: 'Commodity Description Must Be Entered',
          errorField: 'commodity.description',
        });
      }

      if (!aCommodity.classType || aCommodity.classType.trim().length === 0) {
        this._validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: 'Commodity Class Type Must Be Entered',
          errorField: 'commodity.classType',
        });
      }

      if (!aCommodity.packageCd || aCommodity.packageCd.trim().length === 0) {
        this._validationErrors.push({
          severity: this.severity,
          errorCode: this.errorCode,
          errorMessage: 'Commodity Package Code Must Be Entered',
          errorField: 'commodity.packagedCd',
        });
      }
    }
  }
}
