export enum ConfigManagerProperties {
  animations = "animations",
  apiUrl = "apiUrl",
  appName = "appName",
  appMetadataAppName = "appMetadataAppName",
  appMetadataApiEndpoint = "appMetadataApiEndpoint",
  billStorageEnabled = "billStorageEnabled",
  billStorageInterval = "billStorageInterval",
  bolApiEndpoint = "bolApiEndpoint",
  buildVersion = "buildVersion",
  customerLocationApiEndpoint = "customerLocationApiEndpoint",
  debounceTime = "debounceTime",
  elasticSearchApiEndpoint = "elasticSearchApiEndpoint",
  esLogLevel = "esLogLevel",
  freightFlowEndpoint = "freightFlowEndpoint",
  hideAdvancedSearchAndNewOrder = "hideAdvancedSearchAndNewOrder",
  idleTimeout = "idleTimeout",
  csrIdleTimeout = "csrIdleTimeout",
  hazMatIdleTimeout = "hazMatIdleTimeout",
  hazMatRoles = "hazMatRoles",
  csrRoles = "csrRoles",
  billEntryEnabledRoles = "billEntryEnabledRoles",
  orderEntryGeneralBillerRoles = "orderEntryGeneralBillerRoles",
  correctionRoles = "correctionRoles",
  infrastructureEndpoint = "infrastructureEndpoint",
  isProd = "isProd",
  locationApiEndpoint = "locationApiEndpoint",
  locationV2ApiEndpoint = "locationV2ApiEndpoint",
  logAppName = "logAppName",
  loggingApiEndpoint = "loggingApiEndpoint",
  logLayer = "logLayer",
  logOrganization = "logOrganization",
  logPageDwell = "logPageDwell",
  logProjectName = "logProjectName",
  logServicePerformance = "logServicePerformance",
  logUnhandledErrors = "logUnhandledErrors",
  matchScoreThresholdPercentage = "matchScoreThresholdPercentage",
  pricingApiEndpoint = "pricingApiEndpoint",
  region = "region",
  scopeOptions = "scopeOptions",
  secretToken = "secretToken",
  shipmentApiEndpoint = "shipmentApiEndpoint",
  userApiEndpoint = "userApiEndpoint",
  feedbackEmailAddress = "feedbackEmailAddress",
  feedbackEmailSubject = "feedbackEmailSubject",
  illegalCharacterDetectionPattern = "illegalCharacterDetectionPattern",
  remarksSpaceReplacePattern = "remarksSpaceReplacePattern",
  bypassProInputString = "bypassProInputString",
  warehouseKeywords = "warehouseKeywords",
  salvageCenterSicCode = "salvageCenterSicCode",
  salvageCenterMadcode = "salvageCenterMadcode",
  salvageCenterStreeAddress = "salvageCenterStreeAddress",
  salvageCenterZipCode = "salvageCenterZipCode",
  minPickupDateDays = "minPickupDateDays",
  performElasticCustomerMatch = "performElasticCustomerMatch",
  imageCorpCode = "imageCorpCode",
  dmsApiEndpoint = "dmsApiEndpoint",
  imagingApiEndpoint = "imagingApiEndpoint",
  humanResourceApiEndpoint = "humanResourceApiEndpoint",
  legacyCorrectionCutoverDateInclusive = "legacyCorrectionCutoverDateInclusive",
  rrsPickupThreshold = "rrsPickupThreshold",
  g12PickupThreshold = "g12PickupThreshold",
  billEntryCmkName = "billEntryCmkName",
  billEntryCmkAddress = "billEntryCmkAddress",
  billEntryCmkCity = "billEntryCmkCity",
  billEntryCmkState = "billEntryCmkState",
  billEntryCmkPhone = "billEntryCmkPhone",
  billEntryCmkZip = "billEntryCmkZip",
  billEntryCmkSicCd = "billEntryCmkSicCd",
  billEntryCmkMadCd = "billEntryCmkMadCd",
  xfzStartDateMmDd = "xfzStartDateMmDd",
  xfzEndDateMmDd = "xfzEndDateMmDd",
  accuracyThreshold = "accuracyThreshold",
  customerLotusMatchEndPoint = "customerLotusMatchEndPoint",
  invoicingInstructionsEndPoint = "invoicingInstructionsEndPoint",
  pioltApiEndPoint = "pioltApiEndPoint",
  madCodeMatchEndPoint = "madCodeMatchEndPoint",
  matchingThreshold = "matchingThreshold",
  loggedInUserRoot = 'loggedInUserRoot',
  userContextPath = 'userContextPath',
  logStatsForPaths = 'logStatsForPaths',
  logRequestBodyForPaths = 'logRequestBodyForPaths',
  logResponseBodyForPaths = 'logResponseBodyForPaths',
  logExclusionForPaths = 'logExclusionForPaths'
}