import { Lookup } from '../../../shared/model/lookup';

export class Country extends Lookup {

  static countries: Country[] = [
    new Country('US', 'USA'),
    new Country('CA', 'CAN'),
    new Country('MX', 'MEX'),
  ];
  static US: Country = Country.countries[0];
  static CA: Country = Country.countries[1];
  static MX: Country = Country.countries[2];

  static find(country: string): Country {
    if ( !country ) {
      return Country.US;
    }
    return Country.countries.find( c => c.name.toUpperCase() === country.toUpperCase() || c.code.toUpperCase() === country.toUpperCase() );
  }

  private constructor(public override code: string, public override name: string) {
    super(code, name);
  }
}
