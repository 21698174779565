import moment from 'moment-timezone';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

export class DateTimeHelper {
  static SERVER_TIME_ZONE = 'America/Los_Angeles';
  /**
   * condition time to two digit time
   * @param value
   * @returns {string}
   */
  public;

  static padTime(value: number): string {
    if (value !== undefined && value !== null && value % 1 === 0 && value < 60 && value >= 0) {
      const retValue: string = '0' + value;
      return retValue.slice(-2);
    }
    return undefined;
  }

  /**
   * Will respect DST if you add across a DST boundary
   * @param {Date} date
   * @param {number} days
   * @returns {number | Date}
   */
  static addDaysToDate(date: Date, days: number): Date {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  /**
   * Date received is presumed to be in hosts timezone.  Need to replace the time
   * with intended time from server side timezone.
   * @param date
   * @returns {Date}
   */
  static conditionTimeFromServer(date: Date): Date {
    if (!date) {
      return date;
    }

    const newDate = moment(date).tz(DateTimeHelper.SERVER_TIME_ZONE);
    return new Date(
      newDate.year(),
      newDate.month(),
      newDate.date(),
      newDate.hour(),
      newDate.minute(),
      newDate.second(),
      0
    );
  }

  /**
   * Date received is presumed to be in server timezone.  Need to condition date to be UTC date,
   * with intended date from server side timezone.
   * @param date
   * @returns {Date}
   */
  static conditionDateFromServer(date: Date): Date {
    if (!date) {
      return date;
    }

    const newDate = moment(date).tz(DateTimeHelper.SERVER_TIME_ZONE);

    const aDate = new Date(newDate.toDate());
    aDate.setUTCMonth(newDate.month(), newDate.date());
    aDate.setUTCFullYear(newDate.year());
    aDate.setUTCHours(0, 0, 0, 0);
    return aDate;
  }

  /**
   * Date from input type date is UTC based. The UTC date is the date we want
   * in ( SERVER_TIME_ZONE ) in the payload.
   * @param date
   * @returns {Date}
   */
  static conditionDateToServer(date: Date): Date {
    if (!date) {
      return date;
    }

    const offset = moment(date)
      .tz(DateTimeHelper.SERVER_TIME_ZONE)
      .utcOffset();
    const aDate = new Date(date.getTime());
    aDate.setUTCHours(0, 0, 0, 0);
    return new Date(aDate.getTime() - offset * 60000);
  }

  /**
   * Date from input type date is UTC based. The UTC date is the date we want
   * in PST/PSD ( SERVER_TIME_ZONE ) in the payload. For passing time please
   * set the desired time in the UTCHours, UTCMinutes, UTCSeconds.
   * @param date
   * @returns {Date}
   */
  static conditionTimeToServer(date: Date): Date {
    if (!date) {
      return date;
    }

    const offset = moment(date)
      .tz(DateTimeHelper.SERVER_TIME_ZONE)
      .utcOffset();
    return new Date(date.getTime() - offset * 60000);
  }

  /**
   * Check to see if a date fall on weekend. If day of week=6 then Saturday, 0=Sunday
   * @param {Date} date
   * @returns {boolean}
   */
  static isWeekend(date: Date): boolean {
    return !!date ? date.getDay() === 6 || date.getDay() === 0 : false;
  }

  static freezableDateEnabled(date: Date, configManager: ConfigManagerService): boolean {
    if (!date) {
      return false;
    }
    const validStartMonth = +configManager.getSetting<string>(ConfigManagerProperties.xfzStartDateMmDd).split('-')[0];
    const validEndMonth = +configManager.getSetting<string>(ConfigManagerProperties.xfzStartDateMmDd).split('-')[0];

    date = DateTimeHelper.conditionDateToServer(date);

    let startYearRatio = 0;
    let endYearRatio = 0;
    if (date.getMonth() + 1 <= validEndMonth) {
      startYearRatio = -1;
    } else if (date.getMonth() + 1 >= validStartMonth) {
      endYearRatio = 1;
    } else {
      return false;
    }

    const startDate = new Date(`${configManager.getSetting(ConfigManagerProperties.xfzStartDateMmDd)}-${date.getFullYear() + startYearRatio}`);
    const endDate = new Date(`${configManager.getSetting(ConfigManagerProperties.xfzEndDateMmDd)}-${date.getFullYear() + endYearRatio}`);
    endDate.setHours(23, 59, 59);

    return date >= startDate && date <= endDate;
  }
}
