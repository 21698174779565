import { Injectable } from '@angular/core';
import { XrtSearchRequest } from '../../model/xrt';
import { BaseService } from '../base-service';
import { DataApiService } from '../data-api/data-api.service';

import { HttpHeaders } from '@angular/common/http';
import { Envelope } from '@xpo-ltl/api-common';
import { XrtSearchCustomerRequest } from '../../model/xrt/xrt-search-customer-request';
import { XrtSearchCustomerResponse } from '../../model/xrt/xrt-search-customer-response';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

@Injectable({providedIn:'root'})
export class ElasticsearchService extends BaseService {

  constructor(
      private configManager: ConfigManagerService,
      private dateApiService: DataApiService) {
    super();
  }

  xrtSearchCustomers(request: XrtSearchCustomerRequest,
                            showOverlay?: boolean,
                            showErrorToast?: boolean): Promise<XrtSearchCustomerResponse> {
    this._dataOptions.loadingOverlayEnabled = ( showOverlay === undefined ) ? true : showOverlay;
    this._dataOptions.toastOnError = ( showErrorToast === undefined ) ? true : showErrorToast;

    return new Promise(
      (resolve, reject) => {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        this.dateApiService.post(
          this.getUrl('/xrt-search/customers'),
          JSON.stringify(request),
          null,
          this._dataOptions,
          {headers: headers})
          .then(
            (response: Envelope) => {
              resolve(response as XrtSearchCustomerResponse);
            },
            error => {
              reject(error);
            }
          );
      }
    );
  }
  xrtSearch(searchRequest: XrtSearchRequest, showOverlay?: boolean, showErrorToast?: boolean): Promise<any[]> {
    this._dataOptions.loadingOverlayEnabled = ( showOverlay === undefined ) ? true : showOverlay;
    this._dataOptions.toastOnError = ( showErrorToast === undefined ) ? true : showErrorToast;

    return new Promise(
      (resolve, reject) => {
        const headers = new HttpHeaders().set('Content-Type', 'application/json');
        this.dateApiService.post(
          this.getUrl('/xrt-search'),
          JSON.stringify(searchRequest),
          null,
          this._dataOptions,
          {headers: headers})
        .then(
          (response: Envelope) => {
            resolve(response['data']['result']);
          },
          error => {
            reject(error);
          }
        );
      }
    );
  }

  /**
   * Returns the xrt-search endpoint
   */
  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.elasticSearchApiEndpoint);
  }
}


