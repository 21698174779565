import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToolsService {

  constructor() { }
}

export function isNullOrUndefined(value: any) {
  return value === null || value === undefined;
}