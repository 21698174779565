import { NetExplosiveMassUomCd } from '@xpo-ltl/sdk-common';

export interface ExplosiveClassification {
  nem: number;
  uom: NetExplosiveMassUomCd;
}

export enum ExplosiveFormFields {
  Nem = 'nem',
  Uom = 'uom'
}

export class ExplosiveClassificationHelper {
  static hasValue(value: ExplosiveClassification): boolean {
    return !!value && (!!value.nem || !!value.uom);
  }
}
