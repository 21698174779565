export class DataOptions {
  static readonly ConcealedCall: DataOptions = {
    loadingOverlayEnabled: false,
    toastOnSuccess: false,
    toastMessage: '',
    toastOnError: false,
    errorMessageMap: undefined,
  };

  static readonly LoadingOverlayOnlyCall: DataOptions = {
    loadingOverlayEnabled: true,
    toastOnSuccess: false,
    toastMessage: '',
    toastOnError: false,
    errorMessageMap: undefined,
  };

  loadingOverlayEnabled: boolean;
  toastOnSuccess: boolean;
  toastMessage: string;
  toastOnError: boolean;
  errorMessageMap: any;
}
