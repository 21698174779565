import { Pipe, PipeTransform } from '@angular/core';
import { Accessorial } from '../../bill-entry/bill-entry/model/accessorial';

@Pipe({
  name: 'accessorial'
})
export class AccessorialPipe implements PipeTransform {

  transform(value: Accessorial | any, args?: any): any {
    if (value) {
      return `${value.accessorialCd} - ${value.accessorialDesc}`;
    }
    return null;
  }
}
