import { Component, OnInit } from '@angular/core';
import { invoke as _invoke, isEmpty as _isEmpty } from 'lodash';
import { interval } from 'rxjs';
import { skipWhile, take, tap } from 'rxjs/operators';
import { User } from './shared/model/user';
import { UserApiService } from './shared/services/user-api/user-api.service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from './core/model/config-manager-properties.enum';
import { RegionInfo, XpoLtlAuthenticationService } from '@xpo-ltl/ngx-auth';
import { XpoLtlLoggedInUserService } from '@xpo-ltl/ngx-ltl';
import { BillEntryService } from './bill-entry/shared/services/bill-entry.service';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent{
  animationsEnabled: boolean;
  LOTUS_USER_KEY = '.IsLotusUser';
  constructor(
    private config: ConfigManagerService,
    private userService: UserApiService,
    private authService: XpoLtlAuthenticationService,
    private loginService: XpoLtlLoggedInUserService,
    private billEntryService: BillEntryService,
    private snackBar: XpoSnackBar
  ) {
    this.animationsEnabled = this.config.getSetting(ConfigManagerProperties.animations);
    const region = this.config.getSetting<string>(ConfigManagerProperties.region);
      this.authService.initAuthSetup$(region).subscribe((info: RegionInfo) => {
        if(info){
          this.userLogin();
        }
    });
  }

  userLogin(){
    this.loginService.getLoggedInUser(this.config.getSetting(ConfigManagerProperties.loggedInUserRoot))
      .subscribe((userInfo) => {
        const user = userInfo;
        this.billEntryService.currentUserId = user.userId;
        this.userService._cachedUser = user;
        this.userService._loggedInUserData.next(user);
        this.getPiotUser(user);
        this.setDynatraceUserIdentity(user);
        this.userService.hasRoles(this.config.getSetting(ConfigManagerProperties.hazMatRoles)).then((hasRoles) => {
          this.billEntryService.hazMatCapable = hasRoles;
        });
    
        this.userService.hasRoles(this.config.getSetting(ConfigManagerProperties.billEntryEnabledRoles)).then((hasRoles) => {
          this.billEntryService.isShipmentCapable = hasRoles;
        });
    
        this.userService.hasRoles(this.config.getSetting(ConfigManagerProperties.correctionRoles)).then((hasRoles) => {
          this.billEntryService._srnCorrectionCapable.next(hasRoles);
        });
    
        this.userService.hasRoles(this.config.getSetting(ConfigManagerProperties.orderEntryGeneralBillerRoles)).then((hasRoles) => {
          this.billEntryService.hasOrderEntryGeneralBillerRoles = hasRoles;
        });
      }, error => {
        this.snackBar.open({
          message: 'Login failed please try again later.',
          matConfig:{
            duration:3000,
            verticalPosition: 'bottom',
          },
          status:'error'
        })
      });
  }

  private setDynatraceUserIdentity(user: User): void {
    const setUser = (): void =>
      _invoke(
        window['dtrum'],
        'identifyUser',
        !_isEmpty(user.emailAddress) ? user.emailAddress : !_isEmpty(user.userId) ? user.userId : 'BILL_ENTRY_USER'
      );

    if ((window['dtrum'] || {}).identifyUser) {
      setUser();
    } else {
      let retryCount: number = 0;
      interval(1000)
        .pipe(
          tap(() => retryCount++),
          skipWhile(() => !(window['dtrum'] || {}).identifyUser && retryCount <= 60),
          take(1)
        )
        .subscribe(() => {
          setUser();
        });
    }
  }
  // Get piolt user for customer 2.0 functionality
  getPiotUser(user) {
    localStorage.setItem('bill-entry.IsLotusUser', btoa('Yes'));
    return;
    // this.userService.getPioltUser().subscribe((res: any) => {
    //   const response = res.data;
    //   if (response.locationFeatures.length) {
    //     const pilotUser = response.locationFeatures.find(item => user.employeeId === item.employeeId)
    //     if (pilotUser !== undefined) {
    //       localStorage.setItem('bill-entry.IsLotusUser', btoa('Yes'));
    //     }
    //   } else {
    //     localStorage.setItem('bill-entry.IsLotusUser', btoa('No'));
    //   }
    // }, error => {
    //   console.log(error);
    // })
  }
}
