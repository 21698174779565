import { Injectable } from '@angular/core';

import { DeleteShipmentPath, GetShipmentFromDb2Query, ValidateProPath } from '@xpo-ltl/sdk-shipment';
import {
  ShipmentApiService,
  LockShipmentForBillEntryResp,
  LockShipmentForBillEntryRqst,
  LockShipmentForBillEntryQuery,
  CancelBillEntryLockPath,
  GetShipmentQuery,
  GetShipmentResp,
  UpsertShipmentRqst,
  UpsertShipmentResp,
  GetShipmentFromDb2Resp,
  ValidateParentChildShipmentQuery,
  CalculateEstimatedDeliveryDateRqst,
  UpsertShipmentSuppRefNbrsRqst,
  ValidateParentChildShipmentResp,
  ValidateProResp,
} from '@xpo-ltl/sdk-shipment';

import {
  ShipmentApiService as ShipmentV2ApiService,
  UpdateAppointmentRequiredIndRqst,
} from '@xpo-ltl-2.0/sdk-shipment';

import { Observable } from 'rxjs';
import { BaseService } from '../base-service';
import { ConditioningService } from '../conditioning-service/conditioning.service';
import { DataOptions } from '../data-api/data-options';
import { FormatValidationService } from '../format-validation/format-validation.service';

import { ParentShipmentValidationType } from './parent-shipment-validation-type.enum';

import { toString as _toString } from 'lodash';
import { map } from 'rxjs/operators';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';
@Injectable({ providedIn: 'root' })
export class BillEntryShipmentApiService extends BaseService {
  private validateProTimeoutCancelTokens = {};
  cacheProValidations = true;
  private parentShipmentsMapper = {
    [ParentShipmentValidationType.PARENT]: 'parentProNbr',
    [ParentShipmentValidationType.MOVR]: 'movrProNbr',
    [ParentShipmentValidationType.PSEG]: 'psegProNbr',
  };

  constructor(
    private configManager: ConfigManagerService,
    private formatValidator: FormatValidationService,
    private formatter: ConditioningService,
    private shipmentApiService: ShipmentApiService,
    private shipmentV2ApiService: ShipmentV2ApiService
  ) {
    super();
  }

  getShipment(proNumber: string, dataOptions?: DataOptions): Observable<GetShipmentResp> {
    if (!dataOptions) {
      dataOptions = this._dataOptions;
    }

    if (!proNumber) {
      throw new Error('Pro Number Required');
    }

    const proNumber11 = this.formatter.conditionProNumber(proNumber, 11);
    interface ShipmentQuery extends GetShipmentQuery {
      orderEntryInd: boolean;
    }
    const queryParams: ShipmentQuery = {
      ...new GetShipmentQuery(),
      proNbr: proNumber11,
      orderEntryInd: true
    };

    return this.shipmentApiService.getShipment(queryParams);
  }

  cancelBillEntryLock(request: CancelBillEntryLockPath): Observable<void> {
    return this.shipmentApiService.cancelBillEntryLock(request);
  }

  lockShipmentForBillEntry(request: LockShipmentForBillEntryRqst): Observable<LockShipmentForBillEntryResp> {
    const queryParams: LockShipmentForBillEntryQuery = new LockShipmentForBillEntryQuery();
    return this.shipmentApiService.lockShipmentForBillEntry(request, queryParams);
  }

  getShipmentFromDB2(proNumber: string, dataOptions?: DataOptions): Observable<GetShipmentFromDb2Resp> {
    if (!dataOptions) {
      dataOptions = this._dataOptions;
    }

    if (!proNumber) {
      throw new Error('Pro Number Required');
    }

    const proNumber11 = this.formatter.conditionProNumber(proNumber, 11);
    const queryParams = {
      ...new GetShipmentFromDb2Query(),
      proNbr: proNumber11,
    };

    return this.shipmentApiService.getShipmentFromDb2(queryParams);
  }

  upsertShipment(request: UpsertShipmentRqst): Observable<UpsertShipmentResp> {
    if (!request) {
      throw new Error('Bill Entry Information Required');
    }

    return this.shipmentApiService.upsertShipment(request);
  }

  upsertShipmentSuppRefNbrs(request: UpsertShipmentSuppRefNbrsRqst): Observable<void> {
    if (!request) {
      throw new Error('SuppRefNbrs Information Required');
    }

    return this.shipmentApiService.upsertShipmentSuppRefNbrs(request);
  }

  updateAppointmentRequiredInd(shipmentInstId: number, appointmentRequiredInd: boolean): Observable<void> {
    const request: UpdateAppointmentRequiredIndRqst = {
      shipmentInstId,
      appointmentRequiredInd,
    };

    return this.shipmentV2ApiService.updateAppointmentRequiredInd(request);
  }

  deleteShipment(shipmentInstanceId?: number): Observable<void> {
    if (!shipmentInstanceId) {
      throw new Error('Shipment Instance Id Required');
    }

    const path: DeleteShipmentPath = {
      ...new DeleteShipmentPath(),
      shipmentInstId: _toString(shipmentInstanceId),
    };

    return this.shipmentApiService.deleteShipment(path);
  }

  /**
   * Implementation Notes
   * Perform the following validations for the import Parent shipment, based on whether PSEG shipment or MOVR shipment
   * is supplied as the child shipment. If the Parent shipment is valid, return all shipment details for the import
   * Parent. If Import pseg_shipment Pro_number is supplied
   *
   *  Parent SHM_SHIPMENT must be NORM or PTLT
   *  Parent SHM_SHIPMENT cannot be at the destination SIC on the dock
   *  Parent SHM_SHIPMENT cannot be out for delivery
   *  Parent SHM_SHIPMENT cannot be in any interim or final delivery status
   *  Return the sum of the pieces and sum of the weights for each of the Parent's child (PSEG) shipments
   *  If Import movr_shipment Pro_number is supplied
   *
   *  Parent SHM_SHIPMENT must be NORM or PSEG
   *  Parent SHM_SHIPMENT must be final delivered with an interim delivery qualifier of All Short or Part Short.
   *  Inputs
   *
   *  Parent Shipment pro number or instance id is required
   *  One of PSEG Shipment instance id or MOVR shipment instance id is required
   *  Outputs
   *
   *  Return all Parent Shipment details. If import PSEG shipment is supplied,
   *  return the sum of the pieces and sum of the wieghts for each of the Parent's other child (PSEG) shipments.
   *
   * @param {string} proNumber
   * @param {DataOptions} dataOptions
   * @returns {Promise<ValidateParentChildShipmentResp>}
   */
  validateParentShipment(
    pro: string,
    type: ParentShipmentValidationType,
    relatedPro: string,
    parentInstanceId?: string,
    dataOptions: DataOptions = DataOptions.ConcealedCall
  ): Observable<ValidateParentChildShipmentResp> {
    if (!pro && this.formatValidator.isValidProNumber(pro.trim())) {
      throw new Error('A valid Pronumber is Required');
    }
    if (!relatedPro && this.formatValidator.isValidProNumber(relatedPro.trim())) {
      throw new Error('A valid relatedPro is Required');
    }
    pro = this.formatter.conditionProNumber(pro, 11);
    relatedPro = this.formatter.conditionProNumber(relatedPro, 11);

    const query: ValidateParentChildShipmentQuery = {
      ...new ValidateParentChildShipmentQuery(),
      [this.parentShipmentsMapper[type]]: pro,
      [this.parentShipmentsMapper[ParentShipmentValidationType.PARENT]]: relatedPro,
      parentShipmentInstId: Number.parseInt(parentInstanceId),
    };

    return this.shipmentApiService.validateParentChildShipment(query);
  }

  /**
   * https://tctsr-api.ltl.xpo.com/shipment/1.0/pronumbers/{proNbr}
   */
  validateProNumber(value: string): Observable<ValidateProResp> {
    // turn off notification so that we can check pro after failure
    const dataOptions = new DataOptions();
    dataOptions.loadingOverlayEnabled = false;
    dataOptions.toastOnSuccess = false;
    dataOptions.toastOnError = false;

    if (!value && this.formatValidator.isValidProNumber(value.trim())) {
      throw new Error('A valid Pronumber is Required');
    }

    const path: ValidateProPath = {
      ...new ValidateProPath(),
      proNbr: this.formatter.conditionProNumber(value, 11),
    };

    return this.shipmentApiService.validatePro(path);
  }

  /**
   * Given the origin sic, destination sic, pickup date and lat tender code calculate and
   * return the estimated delivery date
   * @param {CalculateEstimatedDeliveryDateRqst} request
   * @returns {Observable<Date>}
   */
  calculateEstimatedDeliveryDate(request: CalculateEstimatedDeliveryDateRqst): Observable<Date> {
    return this.shipmentApiService
      .calculateEstimatedDeliveryDate(request)
      .pipe(map((response) => response.estimatedDeliveryDate));
  }

  resetProCache() {
    if (this.validateProTimeoutCancelTokens) {
      Object.keys(this.validateProTimeoutCancelTokens).forEach((key) => {
        clearTimeout(this.validateProTimeoutCancelTokens[key]);
        delete this.validateProTimeoutCancelTokens[key];
      });
    }
  }

  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.shipmentApiEndpoint);
  }
}
