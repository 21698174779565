import { Lookup } from '../../../shared/model/lookup';

export class TimeDateCritical {
  dateTypeCode: string = undefined;
  date1: Date = undefined;
  date2: Date = undefined;
  timeTypeCode: string = undefined;
  time1: string = undefined;
  time2: string = undefined;
  estimatedDeliveryDate: Date = null;
  notifyOnArrival = false;
  appointmentRequired = true;
  tdc = false;
  
  constructor() {}

  static hasValue(tdc: TimeDateCritical): boolean {
    return !!tdc.dateTypeCode || !!tdc.timeTypeCode || !!tdc.tdc;
  }

  static hasNotifyOnArrival(tdc: TimeDateCritical): boolean {
    return !!tdc.notifyOnArrival;
  }
}

export class DateType extends Lookup {
  constructor(code: string, name: string) {
    super(code, name);
  }
}

export class TimeType extends Lookup {
  constructor(code: string, name: string) {
    super(code, name);
  }
}

// TODO: remove when @xpo-ltl package is updated
export const DATE_TYPES: DateType[] = [new DateType('ON', 'On'), new DateType('BY', 'By'), new DateType('RNG', 'Range')];

// TODO: remove when @xpo-ltl package is updated
export const TIME_TYPES: TimeType[] = [
  new TimeType('AT', 'At'),
  new TimeType('BEF', 'Before'),
  new TimeType('AFT', 'After'),
  new TimeType('RNG', 'Range'),
];
