import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { XpoFormPanelComponent } from './form-panel.component';

@Injectable()
export class XpoFormPanelService {
  private focuedPanel$: BehaviorSubject<XpoFormPanelComponent> = new BehaviorSubject(null);
  private panels$: BehaviorSubject<XpoFormPanelComponent[]> = new BehaviorSubject([]);
  private panels: XpoFormPanelComponent[] = [];

  constructor() {
    this.panels$.subscribe(v => this.panels = v);
  }

  /**
   * Returns true if any of the panels has a warning, used to display a snackbar on submit;
   *
   * @readonly
   * @type {boolean}
   * @memberof XpoFormPanelService
   */
  get panelHasWarnings(): boolean {
    return this.panels.findIndex(v => v.hasWarning === true ) !== -1;
  }

  /**
   * Returns observable of the currently focused panel
   *
   * @readonly
   * @type {Observable<XpoFormPanelComponent>}
   * @memberof XpoFormPanelService
   */
  get focusedPanel(): Observable<XpoFormPanelComponent> {
    return this.focuedPanel$.asObservable();
  }

  /**
   * Returns observable of all the panels in the view.
   *
   * @returns {Observable<XpoFormPanelComponent[]>}
   * @memberof XpoFormPanelService
   */
  getPanels(): Observable<XpoFormPanelComponent[]> {
    return this.panels$.asObservable();
  }

  /**
   * Sets focused panel
   *
   * @param {XpoFormPanelComponent} panel
   * @memberof XpoFormPanelService
   */
  setFocusedPanel(panel: XpoFormPanelComponent) {
    this.focuedPanel$.next(panel);
  }

  /**
   * Removes all panels
   */
  clearPanels(): void {
    this.panels$.next([]);
    this.focuedPanel$.next(null);
  }

  addPanel(panel: XpoFormPanelComponent) {
    this.panels.push(panel);
    this.panels.sort((x, y) => x.offsetTop - y.offsetTop);
    this.panels$.next(this.panels);
  }
}
