import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../../components/dialog/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }

  showMessage(
    title: string,
    message: string,
    confirmText: string,
    cancelText: string,
  ): Observable<any> {
    return this.dialog.open(ConfirmDialogComponent, {
      disableClose:true,
      data: {
        title,
        message,
        confirmText,
        cancelText
      }
    }).afterClosed();
  }

}
