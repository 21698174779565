import { Injectable } from '@angular/core';
import {
  Router,
  NavigationStart,
  NavigationEnd,
  RouterEvent,
} from '@angular/router';
import { LoggingConstants } from '../../bill-entry/shared/models/logging-constants';
// import { ConfigManager } from '../config-manager';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';
import { take, filter } from 'rxjs/operators';

@Injectable()
export class LogPageDwellService {
  private lastEvent: { key: string; startTime?: Date; endTime?: Date };

  constructor(
    private router: Router,
    private loggingApiService: LoggingApiService,
    // private configManager: ConfigManager
  ) {}

  start(): void {
    // this.configManager.settings$.pipe(take(1)).subscribe((settings) => {
    //   if (settings && settings.logPageDwell) {
    //     this.router.events
    //       .pipe(
    //         filter((event: RouterEvent) => {
    //           return (
    //             event instanceof NavigationEnd ||
    //             event instanceof NavigationStart
    //           );
    //         })
    //       )
    //       .subscribe((event: RouterEvent) => {
    //         if (event instanceof NavigationEnd) {
    //           this.lastEvent = {
    //             key: event.url.replace('/', ''),
    //             startTime: new Date(),
    //           };
    //         } else if (this.lastEvent) {
    //           this.lastEvent.endTime = new Date();
    //           this.logLastNavigation(this.lastEvent);
    //         }
    //       });
    //   }
    // });
  }

  private logLastNavigation(lastEvent: {
    key: string;
    startTime?: Date;
    endTime?: Date;
  }) {
    if (lastEvent && lastEvent.startTime && lastEvent.endTime) {
      this.loggingApiService.logAppNavigation(
        lastEvent.key,
        lastEvent.endTime.getTime() - lastEvent.startTime.getTime(),
        LoggingConstants.ActivityNames.BillEntryNavigation
      );
    }
  }
}
