import { Pipe, PipeTransform } from '@angular/core';
import { ChargeToCd } from '@xpo-ltl/sdk-common';
@Pipe({
  name: 'chargeToCd'
})
export class ChargeToCdPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    let displayValue = '';
    switch (value) {
      case 'BOTH': // key as a string
      case ChargeToCd.BOTH:
        displayValue = 'Both';
        break;
      case 'COLL': // key as a string
      case ChargeToCd.COLL:
        displayValue = 'Collect';
        break;
      case 'PPD': // key as a string
      case ChargeToCd.PPD:
        displayValue = 'Prepaid';
        break;
      default:
        displayValue = value;
    }
    return displayValue;
  }

}
