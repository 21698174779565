import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn } from '@angular/forms';
import * as _ from 'lodash';
import { ConditioningService } from '../conditioning-service/conditioning.service';

@Injectable()
export class FormatValidationService {
  private _numberTrailerPattern = '^[0-9]{3}[-]{1}[0-9]{1,7}$';
  private _letterTrailerPattern = '^[a-zA-Z]{1,4}[-]{1}[0-9]{0,6}$';
  private _doorPattern = '^[0-9]{1,4}$';
  /**
   * This expression matches three different formats of postal codes: 5 digit US ZIP code,
   * 5 digit US ZIP code + 4, and 6 digit alphanumeric Canadian Postal Code. The first one
   * must be 5 numeric digits. The ZIP+4 must be 5 numeric digits, a hyphen, and then 4
   * numeric digits. The Canadian postal code must be of the form ANA NAN where A is any
   * uppercase alphabetic character and N is a numeric digit from 0 to 9.
   * @type {string}
   * @private
   */
  private _usZipCode = '^(\\d{5}-\\d{4}|\\d{5}|\\d{9})$';
  private _caPostalCode = '^([a-zA-Z]\\d[a-zA-Z]( )?\\d[a-zA-Z]\\d)$';
  private _mxPostalCode = '^\\d{5}$';

  madCodeValidationPattern = '^[A-Za-z]{3}[A-Za-z\\s]{2}([A-Za-z]{3}|[0-9]{3})[0-9]{3}$';
  billToMadCodeValidationPattern = '^[A-Za-z]{3}[A-Za-z\\s]{2}(991)[0-9]{3}$';
  sicValidationPattern = '^[A-Za-z]{3}$';

  /* @ngInject */
  constructor(private conditioningService: ConditioningService) {} // end of constructor

  isValidProNumber(inputValue:any) {
    return this.isValidProNumberCheckDigit(inputValue);
  }

  isValidDoorNumber(inputValue:any) {
    const regExp = new RegExp(this._doorPattern);
    return regExp.test(inputValue);
  }
  isValidMexicoPostalCode(inputValue:any) {
    return new RegExp(this._mxPostalCode).test(inputValue);
  }
  isValidUsZipCode(inputValue:any) {
    return new RegExp(this._usZipCode).test(inputValue);
  }
  isValidCanadianPostalCode(inputValue:any) {
    return new RegExp(this._caPostalCode).test(inputValue);
  }

  isValidTrailerNumber(inputValue:any) {
    const numberTrailerRegExp = new RegExp(this._numberTrailerPattern).test(inputValue);
    const letterTrailerRegExp = new RegExp(this._letterTrailerPattern).test(inputValue);
    return numberTrailerRegExp || letterTrailerRegExp;
  }

  isValidProNumberCheckDigit(inputValue:any) {
    const proNumber = this.conditioningService.conditionProNumber(inputValue);
    if (proNumber === '' || proNumber.length < 9 || proNumber.length > 11) {
      return false;
    }
    // divide first 8 chars by 7 multiple remainder by 7
    const checkDigit = Number(proNumber.substring(8));
    const number = Number.parseInt(proNumber.substring(0, 8));
    const mod = number % 7;
    return mod === checkDigit;
  }

  // Added New function to verify entered Pro is of 9 or 11 digit
  proNumberCheckDigit(inputValue:any) {
    const proNumber = this.conditioningService.conditionProNumber(inputValue);
    if (proNumber === '' || proNumber.length < 9 || proNumber.length > 11) {
      return false;
    }
    return true;
  }

  isSicValid(value:any) {
    return value != null && value.length === 3 && new RegExp(this.sicValidationPattern).test(value);
  }
  isMadCodeValid(value:any): boolean {
    return value != null && value.length === 11 && new RegExp(this.madCodeValidationPattern).test(value);
  }
  isBillToMadCodeValid(value:any) {
    return value != null && value.length === 11 && new RegExp(this.billToMadCodeValidationPattern).test(value);
  }
  isValidNumeric(value:any) {
    return value != null && _.trim(value).length > 0 && !isNaN(value);
  }

  isValidAlphaNumeric(value:any): boolean {
    return value != null && /^[a-z0-9]+$/i.test(value);
  }

  containsLetters(value:any): boolean {
    return value != null && /[a-z]/i.test(value);
  }

  isNmfcValid(value:any) {
    return value != null && value.length >= 4 && new RegExp(/^[0-9]{4,6}?(-[0-9]{1,2})?$/).test(value);
  }

  proCheckDigitValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      return !control.value || control.value.length < 9 || this.isValidProNumber(control.value)
        ? undefined
        : { proCheckDigit: control.value };
    };
  }
}
