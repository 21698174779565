import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UUID } from 'angular2-uuid';
import { Observable } from 'rxjs';
import { NotificationService } from '../notification/notification.service';
import { DataOptions } from './data-options';
import { ErrorMessageParser } from './error-message-parser';
import { first } from 'rxjs/operators';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';
import { XpoSnackBar } from '@xpo-ltl/ngx-ltl-core/snack-bar';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';

@Injectable()
export class DataApiService {
  private correlationId = '';

  constructor(
    private http: HttpClient,
    private notificationService: NotificationService,
    private configManager: ConfigManagerService,
    private loggingApiService: LoggingApiService,
    private snackBar: XpoSnackBar
  ) {
    this.correlationId = UUID.UUID();
  }

  /**
   * Converts an object to query/form string format
   * eg: {a:'1',b:'2'} -> a=1&b=2
   * @param data
   * @returns {string}
   */
  static objectToQueryString(data: any = {}) {
    let queryString = '';
    const buildQueryElement = (key, value) => {
      return value ? key + '=' + encodeURI(value) + '&' : '';
    };
    Object.keys(data).forEach((element) => {
      if (data[element] instanceof Array) {
        (data[element] as Array<any>).forEach((arrayElement) => {
          queryString += buildQueryElement(element, arrayElement);
        });
      } else if (data[element] instanceof Date) {
        queryString += buildQueryElement(
          element,
          (data[element] as Date).getTime()
        );
      } else {
        queryString += buildQueryElement(element, data[element]);
      }
    });
    return queryString.slice(0, -1); // remove trailing '&' (easier to do here as opposed to testing all values on creation);
  }

  /**
   * Generates a new correlation ID to be used.
   */
  startNewCorrelationSession() {
    this.correlationId = UUID.UUID();
  }
  getCorrelationId() {
    return this.correlationId;
  }

  //noinspection JSUnusedGlobalSymbols
  get(
    url: string,
    queryParams?: any,
    options?: DataOptions,
    httpConfigOptions?: { headers: HttpHeaders }
  ) {
    return this.promiseHandler(
      this.http.get(
        this.generateQueryString(url, queryParams),
        httpConfigOptions || { headers: new HttpHeaders() }
      ),
      options
    );
  }

  //noinspection JSUnusedGlobalSymbols
  post(
    url: string,
    data: any,
    queryParams?: any,
    options?: DataOptions,
    httpConfigOptions?: { headers: HttpHeaders }
  ) {
    return this.promiseHandler(
      this.http.post(
        this.generateQueryString(url, queryParams),
        data,
        httpConfigOptions || { headers: new HttpHeaders() }
      ),
      options
    );
  }

  //noinspection ReservedWordAsName, JSUnusedGlobalSymbols
  delete(
    url: string,
    queryParams?: any,
    options?: DataOptions,
    httpConfigOptions?: { headers: HttpHeaders }
  ) {
    return this.promiseHandler(
      this.http.delete(
        this.generateQueryString(url, queryParams),
        httpConfigOptions || { headers: new HttpHeaders() }
      ),
      options
    );
  }

  //noinspection JSUnusedGlobalSymbols
  put(
    url: string,
    data: any,
    queryParams?: any,
    options?: DataOptions,
    httpConfigOptions?: { headers: HttpHeaders }
  ) {
    return this.promiseHandler(
      this.http.put(
        this.generateQueryString(url, queryParams),
        data,
        httpConfigOptions || { headers: new HttpHeaders() }
      ),
      options
    );
  }

  /**
   * handles the observable response.
   * @param {Observable<any>} observable - http observable
   * @param {DataOptions} options - data options (headers, etc...)
   * @returns {Promise<any>}
   */
  private promiseHandler(
    observable: Observable<any>,
    options: DataOptions = new DataOptions()
  ) {
    try {
      if (options.loadingOverlayEnabled) {
        this.notificationService.showOverlayMessage();
      }
      return new Promise((resolve, reject) => {
        observable.pipe(first()).subscribe({
          next: (response) => {
            if (options.toastOnSuccess) {
              this.snackBar.open({
                message: options.toastMessage,
                matConfig:{
                  duration:3000,
                  verticalPosition: 'bottom',
                },
                status:'success'
              })
            }
            if (options.loadingOverlayEnabled) {
              this.notificationService.hideOverlayMessage();
            }
            const code = response.code;
            // tslint:disable-next-line:no-bitwise
            if ((code & 200) === 200) {
              resolve(response);
            } else {
              this.handleErrorResponse(response, options);
              reject(response.error.message);
            }
          },
          error: (err: HttpErrorResponse) => {
            if (options.loadingOverlayEnabled) {
              this.notificationService.hideOverlayMessage();
            }
            this.handleErrorResponse(err, options);
            reject(err);
          },
        });
      });
    } catch (error) {
       this.loggingApiService.error(JSON.stringify(error));
       return Promise.reject()
    }
  }

  private handleErrorResponse(error: any, options: DataOptions) {
    if (options.toastOnError) {
      if (
        error.status !== undefined &&
        options.errorMessageMap != null &&
        options.errorMessageMap[error.status] != null
      ) {
        this.snackBar.open({
          message: options.errorMessageMap[error.status],
          matConfig:{
            duration:3000,
            verticalPosition: 'bottom',
          },
          status:'success'
        })
        return;
      }
      this.snackBar.open({
        message: ErrorMessageParser.parseMessage(error),
        matConfig:{
          duration:3000,
          verticalPosition: 'bottom',
        },
        status:'success'
      })
    }
    if (options.loadingOverlayEnabled) {
      this.notificationService.hideOverlayMessage();
    }
  }

  /**
   * Takes an object and converts it to a string.
   * NOTE: this is only needed to support the weird jabber array[] param syntax. If that is gone, can just call direct to $http
   * @param url - base url to append to
   * @param queryParams - Optional. If not used, just returns the url.
   *                      Has the following behavior:
   *                          key is 'normal', aka 'accountName' - returns key=value
   *                          key has '[]' (key[])
   *                              If the value is not an array - returns key[]=value
   *                              If value is an array - returns key[]=value1&key[]=value2 ...
   * @returns {string} - updated url with query string
   */
  private generateQueryString(url: string = '', queryParams?: Array<string>) {
    const queryString = queryParams
      ? `?${DataApiService.objectToQueryString(queryParams)}`
      : '';

    return `${this.configManager.getSetting(ConfigManagerProperties.apiUrl)}${
      url.startsWith('/') ? url.substring(1) : url
    }${queryString}`;
  }
}
