import { MatchedPartyStatusCd, MatchedPartySourceCd, BolPartyTypeCd } from '@xpo-ltl/sdk-common';
import { MatchCustomerBillingDetail } from '@xpo-ltl/sdk-customer';
import * as _ from 'lodash';
import { isNullOrUndefined } from '../../shared/services/tools/tools.service';
import { XrtSearchRequest, XrtSortExpressions } from '../../shared/model/xrt';
import { CustomerMatchAddressValidator } from '../../shared/services/customer-api/model/customer-match-address';
import { ElasticsearchService } from '../../shared/services/elasticsearch/elasticsearch.service';
import { NotificationService } from '../../shared/services/notification/notification.service';
import { CustomerApiService } from './../../shared/services/customer-api/customer-api-service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

// temp class
export class XrtSearchResponse {
  acctPartyRole: string = undefined;
  countryCd: string = undefined;
  postalCd: number = undefined;
  addressLine: string = undefined;
  acctInstId: number = undefined;
  cityName: string = undefined;
  acctMadCd: string = undefined;
  usZip4: number = undefined;
  stateCd: string = undefined;
  lstUpdtTmst: Date = undefined;
  name2: string = undefined;
  name1: string = undefined;
  seqNbr: number = undefined;
}

export class MatchListItem {
  selected = false;
  relevance = 0;
  madCode = '';
  name1 = '';
  name2 = '';
  address = '';
  timesMatchedCount = 0;
  source: MatchCustomerBillingDetail | any;
  customerNumber = 0;
  city = '';
  state = '';
  zip = '';
  zip4 = '';
  country = '';
  customer? = null
}

export class CustomerMatchViewModel {
  name = '';
  name2 = '';
  address = '';
  city = '';
  state = '';
  zip = '';
  zip4 = '';
  madCode = '';
  country = '';
  matchList: Array<MatchListItem> = [];
  customerType: BolPartyTypeCd;
  matchedPartyStatusCd: MatchedPartyStatusCd;
  matchedPartySourceCd: MatchedPartySourceCd;
  MatchedPartyStatusCd = MatchedPartyStatusCd;
  MatchedPartySourceCd = MatchedPartySourceCd;

  public elasticSearchExecuted = false;
  private matchedAddress: MatchCustomerBillingDetail = new MatchCustomerBillingDetail();
  private userSelectedNotMatched = false;
  isLotusUser: boolean = false;


  constructor(
    customerType: BolPartyTypeCd,
    public asEntered: MatchCustomerBillingDetail,
    public fullMatch: MatchCustomerBillingDetail,
    public adjustedAddress: MatchCustomerBillingDetail,
    public possibleMatches: Array<MatchCustomerBillingDetail>,
    public addressValidatedInd: boolean,
    public fieldsRevisedInd: boolean,
    public vendorValidationErrorInd: boolean,
    public matchPartyStatusCd: MatchedPartyStatusCd,
    public customerApiService: CustomerApiService,
    public elasticSearch: ElasticsearchService,
    public configManager: ConfigManagerService,
    public isReadOnly: boolean,
    private notificationService: NotificationService,
  ) {
    this.matchedPartyStatusCd = matchPartyStatusCd;
    if (fieldsRevisedInd && this.adjustedAddress) {
      this.mapCustomer(this.adjustedAddress);
      if (CustomerMatchAddressValidator.hasValidInputs(this.fullMatch)) {
        this.matchedAddress = this.fullMatch;
      } else {
        this.matchedAddress = this.adjustedAddress;
      }
      if (this.matchPartyStatusCd === MatchedPartyStatusCd.MCH) {
        this.matchedPartySourceCd = MatchedPartySourceCd.SYSTEM_MATCHED;
      }
    } else if (this.fullMatch && CustomerMatchAddressValidator.hasValidInputs(this.fullMatch)) {
      this.mapCustomer(fullMatch);
      this.matchedAddress = this.fullMatch;
      this.matchedPartySourceCd = MatchedPartySourceCd.SYSTEM_MATCHED;
    } else if (CustomerMatchAddressValidator.hasValidInputs(this.asEntered)) {
      this.mapCustomer(this.asEntered);
    }

    if (this.fullMatch && this.fullMatch.madCd) {
      this.madCode = this.fullMatch.madCd;
    }

    if (possibleMatches != null) {
      this.mapPossibleCustomerMatches(possibleMatches);
    }
    this.customerType = customerType;

    // cheking the lotus user(Customer 2.0)
    if(atob(localStorage.getItem('bill-entry.IsLotusUser')) === 'Yes'){
      this.isLotusUser = true;
    }
  }

  getMatchedAddress() {
    return this.matchedAddress;
  }

  private mapCustomer(customer: MatchCustomerBillingDetail) {
    this.name = customer.nameLine1;
    this.name2 = customer.nameLine2;
    this.address = customer.address;
    this.city = customer.city;
    this.state = customer.stateCd;
    this.zip = customer.zip6;
    this.zip4 = customer.zip4;
    this.country = customer.countryCd;
  }

  isMatched() {
    if (
      this.userSelectedNotMatched ||
      (this.customerType === BolPartyTypeCd.BILL_TO &&
        (this.matchedPartyStatusCd === MatchedPartyStatusCd.NO_DERIVED_BILL_TO_SELF_INV ||
          this.matchedPartyStatusCd === MatchedPartyStatusCd.DERIVED_BILL_TO ||
          this.matchedPartyStatusCd === MatchedPartyStatusCd.NO_DERIVED_BILL_TO))
    ) {
      return true;
    }
    const matched =
      this.matchedPartyStatusCd !== undefined &&
      this.matchedPartySourceCd !== undefined &&
      this.matchedPartyStatusCd !== MatchedPartyStatusCd.UN_MCH &&
      ((this.madCode !== undefined && this.madCode.length > 0) ||
        this.matchedPartySourceCd === MatchedPartySourceCd.NOT_MATCHED);
    return matched;
  }

  showSuccessIcon() {
    return (
      this.matchedPartyStatusCd === MatchedPartyStatusCd.MCH ||
      (this.matchedPartyStatusCd === MatchedPartyStatusCd.UN_MCH &&
        this.matchedPartySourceCd === MatchedPartySourceCd.USER_SELECTED) ||
      this.matchedPartyStatusCd === MatchedPartyStatusCd.DERIVED_BILL_TO ||
      this.matchedPartyStatusCd === MatchedPartyStatusCd.NO_DERIVED_BILL_TO_SELF_INV
    );
  }

  handleSearchAgain(formValue?, type?) {
    this.matchList.length = 0;
    this.elasticSearchExecuted = true;
    if (this.isLotusUser) {
      this.searchLotus(formValue, type);
    } else {
      this.searchElastic();
    }
  }

  handleMatch(selectedCustomer: MatchListItem) {
    this.userSelectedNotMatched = false;
    this.matchedAddress = selectedCustomer.source;
    this.madCode = this.matchedAddress.madCd;
    this.matchedPartySourceCd = this.elasticSearchExecuted
      ? MatchedPartySourceCd.USER_SELECTED_SEARCH
      : MatchedPartySourceCd.USER_SELECTED;
    this.matchedPartyStatusCd = MatchedPartyStatusCd.MCH;
  }

  // Handling match result for lotus
  handleMatchLotus(selectedCustomer:any) {
    this.userSelectedNotMatched = false;
    if(selectedCustomer.source.customer){
      selectedCustomer.source.address = selectedCustomer.source.customer.addressLine1;
      selectedCustomer.source.city = selectedCustomer.source.customer.cityName;
      selectedCustomer.source.countryCd = selectedCustomer.source.customer.countryCd;
      selectedCustomer.source.custNbr = selectedCustomer.source.customer.customerNbr;
      selectedCustomer.source.madCd = selectedCustomer.source.customer.madCd;
      selectedCustomer.source.nameLine1 = selectedCustomer.source.customer.name1;
      selectedCustomer.source.nameLine2 = selectedCustomer.source.customer.name2;
      selectedCustomer.source.stateCd = selectedCustomer.source.customer.stateCd;
      selectedCustomer.source.zip4 = selectedCustomer.source.customer.usZip4;
      selectedCustomer.source.zip6 = selectedCustomer.source.customer.postalCd;
      this.matchedAddress = selectedCustomer.source;
    }else{
      this.matchedAddress = selectedCustomer.source;
    }
    this.madCode = this.matchedAddress.madCd;
    this.matchedPartySourceCd = this.elasticSearchExecuted
      ? MatchedPartySourceCd.USER_SELECTED_SEARCH
      : MatchedPartySourceCd.USER_SELECTED;
    this.matchedPartyStatusCd = MatchedPartyStatusCd.MCH;
  }

  hasFullMatch() {
    return isNullOrUndefined(this.fullMatch);
  }
  handleUnsure() {
    this.userSelectedNotMatched = false;
    this.madCode = undefined;
    this.matchedPartySourceCd = MatchedPartySourceCd.NOT_MATCHED;
    this.matchedPartyStatusCd = MatchedPartyStatusCd.POSSIBLES_FOUND;
    this.setNoMatchAddressInfo();
  }
  private setNoMatchAddressInfo() {
    if (!this.isAddressEmtpy(this.adjustedAddress)) {
      if(this.isLotusUser){
        this.adjustedAddress.madCd = '';
      }
      this.matchedAddress = this.adjustedAddress;
      if (this.matchedAddress.address && this.matchedAddress.address.length > 30) {
        this.matchedAddress.address = this.matchedAddress.address.substr(0, 30);
      }
    } else {
      _.assign(this.matchedAddress, this.asEntered);
    }
  }
  handleNoMatch() {
    this.userSelectedNotMatched = true;
    this.madCode = undefined;
    this.matchedPartySourceCd = MatchedPartySourceCd.NOT_MATCHED;
    this.matchedPartyStatusCd = MatchedPartyStatusCd.UN_MCH;
    this.setNoMatchAddressInfo();
  }
  isAddressEmtpy(cust: MatchCustomerBillingDetail): boolean {
    return !cust.nameLine1 || !cust.address || !cust.city || !cust.stateCd || !cust.countryCd || !cust.zip6;
  }

  isUnsure(): boolean {
    return (
      this.matchedPartySourceCd === MatchedPartySourceCd.NOT_MATCHED &&
      this.matchedPartyStatusCd === MatchedPartyStatusCd.UN_MCH
    );
  }

  hasMatchList(): boolean {
    return this.matchList && this.matchList.length > 0;
  }

  private mapPossibleCustomerMatches(customers: Array<MatchCustomerBillingDetail>) {
    customers.forEach((possibleMatch) => {
      const matchListItem = new MatchListItem();
      matchListItem.relevance = possibleMatch.matchScorePercentage;
      matchListItem.madCode = possibleMatch.madCd;
      matchListItem.address = possibleMatch.address;
      matchListItem.name1 = possibleMatch.nameLine1;
      matchListItem.name2 = possibleMatch.nameLine2;
      matchListItem.timesMatchedCount = possibleMatch.timesMatchedCount;
      matchListItem.source = possibleMatch;
      matchListItem.city = possibleMatch.city;
      matchListItem.state = possibleMatch.stateCd;
      matchListItem.zip = possibleMatch.zip6;
      matchListItem.zip4 = possibleMatch.zip4;
      matchListItem.country = possibleMatch.countryCd;
      matchListItem.customerNumber = Number.parseInt(possibleMatch.custNbr);
      this.matchList.push(matchListItem);
    });
  }

  private searchElastic() {
    const filters = {};
    if (this.madCode) {
      filters['acctMadCd'] = this.madCode;
    } else {
      if (this.name) {
        filters['name1.startsWith'] = this.name;
      }
      if (this.address) {
        filters['addressLine.startsWith'] = this.address;
      }
      if (this.city) {
        filters['cityName'] = this.city;
      }
      if (this.state) {
        filters['stateCd'] = this.state;
      }
      if (this.zip) {
        filters['postalCd'] = this.zip;
      }
    }
    filters['acctPartyRole'] = this.customerType === BolPartyTypeCd.BILL_TO ? 'B' : 'P';

    const xrtSearchRequest = new XrtSearchRequest();
    xrtSearchRequest.page = 1;
    xrtSearchRequest.pageSize = 50;
    xrtSearchRequest.filters = filters;
    xrtSearchRequest.sortExpressions = [new XrtSortExpressions('name1', true)];
    xrtSearchRequest.type = 'customerSearch';

    this.elasticSearch.xrtSearch(xrtSearchRequest).then((response: XrtSearchResponse[]) => {
      response.forEach((searchResponse, index) => {
        const matchListItem = new MatchListItem();
        matchListItem.selected = index === 0;
        matchListItem.relevance = 0;
        matchListItem.madCode = searchResponse.acctMadCd;
        matchListItem.address = searchResponse.addressLine;
        matchListItem.name1 = searchResponse.name1;
        matchListItem.name2 = searchResponse.name2;
        matchListItem.timesMatchedCount = 0;
        matchListItem.source = this.createMatchCustomerBillingDetail(searchResponse);
        matchListItem.customerNumber = searchResponse.acctInstId;
        matchListItem.city = searchResponse.cityName;
        matchListItem.state = searchResponse.stateCd;
        matchListItem.zip = searchResponse.postalCd ? `${searchResponse.postalCd}` : null;
        matchListItem.zip4 = searchResponse.usZip4 ? `${searchResponse.usZip4}` : null;
        matchListItem.country = searchResponse.countryCd;
        this.matchList.push(matchListItem);
      });
    });
  }

  private toStringValue(value: any) {
    return value ? `${value}` : undefined;
  }

  createMatchCustomerBillingDetail(xrt: XrtSearchResponse): MatchCustomerBillingDetail {
    const matchCustomer: MatchCustomerBillingDetail = new MatchCustomerBillingDetail();
    matchCustomer.address = xrt.addressLine;
    matchCustomer.city = xrt.cityName;
    matchCustomer.stateCd = xrt.stateCd;
    matchCustomer.zip6 = this.toStringValue(xrt.postalCd);
    matchCustomer.zip4 = this.toStringValue(xrt.usZip4);
    matchCustomer.nameLine1 = xrt.name1;
    matchCustomer.nameLine2 = xrt.name2;
    matchCustomer.countryCd = xrt.countryCd;
    matchCustomer.madCd = xrt.acctMadCd;
    matchCustomer.custNbr = this.toStringValue(xrt.acctInstId);

    return matchCustomer;
  }

  // Lotus Customer 2.0 API functionality start here
  // @Component:- Customer 2.0 functions
  private searchLotus(formValue, type) {
    this.customerApiService._proceedSearching.next(true);
    this.madCode = undefined;
    const payload = {
      requestType: 'OrderEntryDetailed',
      customerNameAndAddress: {
        name: this.name,
        addressLine1: this.address,
        postalCd: this.zip,
      },
      customerTypeCds: ['PickupDelivery'],
      accuracyThreshold: +formValue.score
    };

    // If type is billto than we have to add data billTo
    if (type === 'BillTo') {
      payload.customerTypeCds[0] = 'BillTo';
    }

    this.customerApiService.matchCustomers(payload).subscribe((res: any) => {
      this.customerApiService._proceedSearching.next(false);
      if(res.data.numberOfMatchingResults > 0){
        const filterdArray = res.data.matchedCustomers.map(item => this.objectMappingForLotus(item));
      const finalResponse = filterdArray.filter(item => item.matchScorePercentage >= +formValue.score);
      if (finalResponse.length) {
        finalResponse.forEach((searchResponse, index) => {
          const matchListItem = new MatchListItem();
          matchListItem.selected = index === 0;
          matchListItem.relevance = searchResponse.matchScorePercentage;
          matchListItem.madCode = searchResponse.madCd;
          matchListItem.address = searchResponse.address;
          matchListItem.name1 = searchResponse.nameLine1;
          matchListItem.name2 = searchResponse.nameLine2;
          matchListItem.timesMatchedCount = 0;
          matchListItem.source = searchResponse;
          matchListItem.customerNumber = searchResponse.customerNbr;
          matchListItem.city = searchResponse.city;
          matchListItem.state = searchResponse.stateCd;
          matchListItem.zip = searchResponse.zip6 ? `${searchResponse.zip6}` : null;
          matchListItem.zip4 = searchResponse.zip4 ? `${searchResponse.zip4}` : null;
          matchListItem.country = searchResponse.countryCd;
          this.matchList.push(matchListItem);
        });
      }else{
        this.matchedPartyStatusCd = MatchedPartyStatusCd.INVALID_ADDRESS;
        this.madCode = undefined;
      }
      }else{
        this.matchedPartyStatusCd = MatchedPartyStatusCd.INVALID_ADDRESS;
        this.madCode = undefined;
      }
    }, error => {
      this.customerApiService._proceedSearching.next(false);
      console.log(error);
    });
  }

  objectMappingForLotus(obj) {
    let newObj;
    newObj = {
      address: obj.addressLine1,
      city: obj.cityName,
      countryCd: obj.countryCd,
      custNbr: obj.customerNbr,
      madCd: obj.madCd,
      matchScorePercentage: obj.accuracyPercentage,
      nameLine1: obj.name1,
      nameLine2: obj.name2,
      stateCd: obj.stateCd,
      timesMatchedCount: 0,
      zip4: obj.usZip4,
      zip6: obj.postalCd,
      customer: obj.customerAliasContactMatch
    };
    return newObj;
  }
}
