<form [formGroup]="feedbackFormGroup" (ngSubmit)="send()" class="feedback-form" novalidate>
  <xpo-card>
    <xpo-card-header>
      <xpo-card-title>
        {{ feedbackFormConfig.title }}
      </xpo-card-title>
    </xpo-card-header>
    <xpo-card-content>
      <div class="form-group">
        <label [for]="FeedbackFormFields.FromName">From:</label>
        <mat-form-field floatLabel="always">
          <input
            matInput
            autocomplete="off"
            [id]="FeedbackFormFields.FromName"
            [formControlName]="FeedbackFormFields.FromName"
          />
          <mat-error>From Name is required.</mat-error>
        </mat-form-field>
      </div>
      <div class="form-group">
        <label [for]="FeedbackFormFields.Subject">Subject:</label>
        <mat-form-field>
          <input
            matInput
            autocomplete="off"
            [id]="FeedbackFormFields.Subject"
            [formControlName]="FeedbackFormFields.Subject"
          />
          <mat-error *ngIf="feedbackFormGroup.get(FeedbackFormFields.Subject).hasError('required')"
            >Subject is required.</mat-error
          >
          <mat-error *ngIf="feedbackFormGroup.get(FeedbackFormFields.Subject).hasError('pattern')"
            >Invalid character found.</mat-error
          >
        </mat-form-field>
      </div>
      <div class="form-group form-group__column">
        <label [for]="FeedbackFormFields.Message">Message:</label>
        <mat-form-field>
          <textarea
            matInput
            cdkTextareaAutosize
            [id]="FeedbackFormFields.Message"
            [formControlName]="FeedbackFormFields.Message"
            #autosize="cdkTextareaAutosize"
            cdkAutosizeMinRows="2"
            cdkAutosizeMaxRows="10"
          >
          </textarea>
          <mat-error *ngIf="feedbackFormGroup.get(FeedbackFormFields.Message).hasError('required')"
            >Message is required.</mat-error
          >
          <mat-error *ngIf="feedbackFormGroup.get(FeedbackFormFields.Message).hasError('pattern')"
            >Invalid character found.</mat-error
          >
        </mat-form-field>
      </div>
    </xpo-card-content>
    <xpo-card-actions>
      <xpo-button-group>
        <button  mat-stroked-button mat-button xpoButton xpoSmallButton type="button"  (click)="cancel()" tabindex="-1">
          Cancel
        </button>
        <button mat-flat-button xpoButton xpoSmallButton type="submit" [disabled]="feedbackFormGroup.invalid">Send</button>
      </xpo-button-group>
    </xpo-card-actions>
  </xpo-card>
</form>
