export abstract class Lookup {
  constructor(
    public code: string,
    public name: string
  ) { }

  static compare(x: Lookup, y: Lookup) {
    return (
      x && y &&
      x.code && y.code &&
      x.code === y.code
    );
  }

  toString() {
    return this.code;
  }
}
