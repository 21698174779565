import { Component, Input, OnInit } from '@angular/core';
import { detect } from 'detect-browser';

export type XpoFormFooterButtonTypes = 'Primary' | 'Secondary';

@Component({
  moduleId: module.id,
  selector: 'xpo-form-footer',
  templateUrl: 'form-footer.component.html',
  styleUrls: ['form-footer.component.scss'],
})
export class XpoFormFooterComponent implements OnInit {
  primaryButtons: XpoFormFooterButton[] = [];
  secondaryButtons: XpoFormFooterButton[] = [];
  browserInfo;

  @Input() buildVersion: string;

  constructor() {}
  ngOnInit(): void {
    this.browserInfo = detect();
  }

  @Input()
  get footerButtons(): XpoFormFooterButton[] {
    return this.primaryButtons.concat(this.secondaryButtons);
  }

  set footerButtons(buttons: XpoFormFooterButton[]) {
    this.primaryButtons = buttons.filter((v) => v.type === 'Primary');
    this.secondaryButtons = buttons.filter((v) => v.type === 'Secondary');
  }
}

export class XpoFormFooterButton {
  constructor(
    public display: string,
    public action: (button: XpoFormFooterButton) => void,
    public type: XpoFormFooterButtonTypes = 'Secondary',
    public hide: boolean = false,
    public disabled: boolean = false,
    public key?: string,
    public altRequired: boolean = true
  ) {}

  handleKeyDown(event) {
    if (
      !this.hide &&
      !this.disabled &&
      !event.handled &&
      this.key &&
      event.key &&
      ((this.altRequired && event.altKey) || !this.altRequired) &&
      event.key.toUpperCase() === this.key.toUpperCase()
    ) {
      event['handled'] = true; // could not stop the event. Event pipe already hydrated.
      this.action(this);
    }
  }

  get displayValue() {
    let value = this.display;
    if (this.key) {
      const index = value.toUpperCase().indexOf(this.key.toUpperCase());
      if (index >= 0) {
        if (index === 0) {
          value = `<u>${value[0]}</u>${value.slice(index + 1)}`;
        } else if (index === value.length - 1) {
          value = `${value.slice(0, index)}<u>${value[index]}</u>`;
        } else {
          value = `${value.slice(0, index)}<u>${value[index]}</u>${value.slice(index + 1)}`;
        }
      }
    }
    return value;
  }
}
