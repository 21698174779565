import { Pipe, PipeTransform } from '@angular/core';
import { CustomerTypeCd } from '@xpo-ltl/sdk-common';

@Pipe({
  name: 'customerTypeCd',
})
export class CustomerTypeCdPipe implements PipeTransform {
  transform(value: CustomerTypeCd, args?: any): any {
    let displayValue = `${value}`;
    switch (value) {
      case CustomerTypeCd.BILL_TO:
        displayValue = 'Bill To';
        break;
      case CustomerTypeCd.PICKUP_DELIVERY:
        displayValue = 'P&D';
        break;
      case CustomerTypeCd.CORPORATE:
        displayValue = 'Corporate';
        break;
      case CustomerTypeCd.FUTURE:
        displayValue = 'Future';
        break;
      case CustomerTypeCd.SERVICE_RECIPIENT:
        displayValue = 'Recipient';
        break;
    }
    return displayValue;
  }
}
