import { Commodity } from './commodity';

export class OrderDetails {
  shipperCommodityProfileStatus: any = undefined; // TODO;
  totalPieces = 0;
  totalWeight = 0;
  initialTotalWeight = 0;
  totalPalletCount = 0;
  items: number = undefined;
  commodities: Commodity[] = [];
  initialTotalPieces = 0;
}
