import { Pipe, PipeTransform } from '@angular/core';
import { CommodityClassCd } from '@xpo-ltl/sdk-common';
@Pipe({
  name: 'commodityClassCd'
})
export class CommodityClassCdPipe implements PipeTransform {

  transform(value: CommodityClassCd | string, args?: any): any {
    let displayValue = '';
    if (value && value.toLowerCase().startsWith('clss')) {
      displayValue = value.substring(4);
    }
    return displayValue;
  }

}
