<xpo-card class="confirmation">
  <xpo-card-header>
    <xpo-card-title class="confirmation__title">
      {{ title }}
    </xpo-card-title>
  </xpo-card-header>
  <xpo-card-content class="confirmation__message">
    {{ message }}
  </xpo-card-content>
  <xpo-card-actions>
    <!--  -->
    <xpo-button-group>
      <button *ngIf="showCancelButton" mat-stroked-button mat-button xpoButton xpoSmallButton (click)="handleCancelClicked()" type="button" tabindex="-1">
        {{ cancelText }}
      </button>
      <button mat-flat-button xpoButton xpoSmallButton (click)="handleConfirmClicked()" type="button">{{ confirmText }}</button>
    </xpo-button-group>
  </xpo-card-actions>
</xpo-card>
