import { XpoValidationError } from '../xpo-validation-error';
import { Severity } from './severity.enum';

export abstract class BaseValidation<T> {
  errorCode: string;
  errorDescription: string;
  fieldName: string;
  severity: Severity;

  constructor(
    errorCode: string = 'Unknown',
    errorDescription: string = 'No description provided',
    fieldName: string = 'Unknown',
    severity: Severity = Severity.Error
  ) {
    this.errorCode = errorCode;
    this.errorDescription = errorDescription;
    this.fieldName = fieldName;
    this.severity = severity;
  }

  /**
   * return the associated validation errors.
   * @returns {XpoValidationError[]}
   */
  getValidationErrors(): XpoValidationError[] {
    return [
      {
        severity: this.severity,
        errorCode: this.errorCode,
        errorMessage: this.errorDescription,
        errorField: this.fieldName,
      },
    ];
  }

  /**
   * Used to reset validation errors between calls.
   */
  resetValidationErrors() {}

  /**
   * validation routine.
   * @param {T} value
   * @returns {boolean}
   */
  abstract isValid(value: T): boolean;
}
