export enum OrderDetailsFormFields {
  ShipperCommodityProfileStatus = 'shipperCommodityProfileStatus',
  TotalPieces = 'totalPieces',
  TotalWeight = 'totalWeight',
  TotalPalletCount = 'totalPalletCount',
  Items = 'items',
  Commodities = 'commodities',
  InitialTotalWeight = 'initialTotalWeight',
  InitialTotalPieces = 'initialTotalPieces'
}
