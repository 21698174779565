import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { XpoFeedbackFormComponent } from '../feedback-form/feedback-form.component';
import { XpoFeedbackFormModule } from '../feedback-form/feedback-form.module';
import { XpoFormFooterComponent } from './form-footer/form-footer.component';
import { XpoFormHeaderComponent } from './form-header/form-header.component';
import { XpoFormPanelComponent, XpoFormPanelInputDirective } from './form-panel/form-panel.component';
import { XpoFormPanelService } from './form-panel/form-panel.service';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';

@NgModule({
  declarations: [
    XpoFormFooterComponent,
    XpoFormHeaderComponent,
    XpoFormPanelComponent,
    XpoFormPanelInputDirective,
  ],
  imports: [
    CommonModule,
    RouterModule,
    XpoButtonModule,
    XpoDialogModule,
    XpoCardModule,
    XpoFeedbackFormModule,
  ],
  exports: [
    XpoFormFooterComponent,
    XpoFormHeaderComponent,
    XpoFormPanelComponent,
    XpoFormPanelInputDirective,
  ],
  providers: [XpoFormPanelService],
  entryComponents: [XpoFeedbackFormComponent],
})
export class XpoFormComponentsModule { }
