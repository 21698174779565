import { Injectable } from '@angular/core';
import {
  ListPricingAccessorialsByTypeResp,
  AccessorialService,
  PricingApiService as SdkPricingApiService,
  ListPricingAccessorialsByTypeQuery,
} from '@xpo-ltl/sdk-pricing';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseService } from '../base-service';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

@Injectable()
export class PricingApiService extends BaseService {
  private pricingAccessorialsByTypeResp: ListPricingAccessorialsByTypeResp;
  public excludedAccessorials: string[] = [
    'APT',
    'AKA',
    'AKB',
    'AKC',
    'AKD',
    'AKL',
    'AKM',
    'AKN',
    'AKR',
    'AKS',
    'AKU',
    'AKZ',
    'CCS',
    'DRA',
    'DTD',
    'EXC',
    'FSC',
    'HCD',
    'HCP',
    'HMS',
    'HST',
    'NST',
    'QST',
    'RDC',
    'SIF',
    'SPQ',
    'SST',
    'TLF',
    'USX',
    'VHZ',
    'VPE',
    'VPF',
    'VPG',
    'VPI',
    'VPJ',
    'VPK',
    'VPM',
    'VPQ',
    'VZM',
    'WHD',
    'WHF',
    'WHO',
    'WHW',
    'WTH',
    'XLF',
    'XST',
    'ELS'
  ];
  constructor(private pricingApiService: SdkPricingApiService, private configManager: ConfigManagerService) {
    super();
  }
  /**
   *  This method mutates the array elements and removes some
   * @param theAccessorials
   */
  private conditionAndFilterResponse(theAccessorials: AccessorialService[]) {
    if (typeof theAccessorials !== 'undefined') {
      let index = theAccessorials.length - 1;

      while (index >= 0) {
        if (
          'MEX199' === theAccessorials[index].rulesTariffName ||
          this.excludedAccessorials.indexOf(theAccessorials[index].accessorialCd) > -1
        ) {
          theAccessorials.splice(index, 1);
        } else if (theAccessorials[index].accessorialCd === 'COD' || theAccessorials[index].accessorialCd === 'EVC') {
          theAccessorials[index].literalInd = false;
        }

        index -= 1;
      }
    }
  }

  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.pricingApiEndpoint);
  }

  getPricingAccessorials(): Observable<ListPricingAccessorialsByTypeResp> {
    if (!this.pricingAccessorialsByTypeResp) {
      const params: ListPricingAccessorialsByTypeQuery = {
        ...new ListPricingAccessorialsByTypeQuery(),
      };

      return this.pricingApiService.listPricingAccessorialsByType(params).pipe(
        map((response: ListPricingAccessorialsByTypeResp) => {
          this.pricingAccessorialsByTypeResp = response;
          this.conditionAndFilterResponse(response.accessorials);
          return response;
        }, tap((response: ListPricingAccessorialsByTypeResp) => (this.pricingAccessorialsByTypeResp = response)))
      );
    } else {
      return of(this.pricingAccessorialsByTypeResp);
    }
  }
}
