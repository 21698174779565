import { Pipe, PipeTransform } from '@angular/core';
import { ConditioningService } from '../services/conditioning-service/conditioning.service';

@Pipe({
  name: 'proNumberFormatter'
})
export class ProNumberFormatterPipe implements PipeTransform {
  constructor(private conditioningService: ConditioningService) {}

  transform(value: any, args?: any): any {

    let formatDigits = 10;
    if (args) {
      formatDigits = Number.parseInt(args);
    }
    return this.conditioningService.conditionProNumber(value, formatDigits);
  }

}
