import { LineItemChargeToCd } from '@xpo-ltl/sdk-common';
import { Actionable } from './actionable';

export class ActionableWithChargeTo extends Actionable {
  chargeToCode: LineItemChargeToCd;

  constructor() {
    super();
  }
}

export enum ActionableWithChargeToFormFields {
  ChargeToCode = 'chargeToCode'
}
