import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ChromeVersionDialogComponentData {
  minVersion: string | number;
  currentVersion: string | number;
}

@Component({
  selector: 'xpo-chrome-version-guard-dialog',
  templateUrl: './chrome-version-guard-dialog.component.html'
})
export class ChromeVersionGuardDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: ChromeVersionDialogComponentData) { }

}
