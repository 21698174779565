import { Injectable } from '@angular/core';
import {
  BillClassCd,
  ShipmentSourceCdHelper,
  AccessorialUnitOfMeasureCdHelper,
  ActionCd,
  BillStatusCd,
  BolUsageTypeCd,
  ChargeToCdHelper,
  ShipmentRemarkTypeCd,
  BillClassCdHelper,
} from '@xpo-ltl/sdk-common';
import {
  BillEntryStats,
  BolUsage,
  GetShipmentFromDb2Resp,
  Shipment,
  UpsertShipmentRqst,
  UpsertShipmentSuppRefNbrsRqst,
  GetShipmentResp,
  AccessorialService,
} from '@xpo-ltl/sdk-shipment';
import { Observable } from 'rxjs';
import { ReplaySubject } from 'rxjs';
import { Accessorial, Bill, OrderDetails, OrderHeader, PARTY_TYPE_ID, Remarks } from '../../../bill-entry/model';
import { BillSource } from '../../../bill-entry/model/bill-source.enum';
import { DateTimeHelper } from '../../util/date-time-helper';
import { SalvageCenterValidationService } from '../salvage-center-validation/salvage-center-validation.service';
import { CommoditiesTransformerService } from './commodities-transformer.service';
import { AccessorialTransformer } from './components/accessorial-transformer';
import { AdditionalInformationTransformer } from './components/additional-information-transformer';
import { PartiesTransformer } from './components/parties-transformer';
import { SpecialInstructionsTransformer } from './components/special-instructions-transformer';
import { SrnTransformer } from './components/srn-transformer';
import { TimeDateCriticalTransformer } from './components/time-date-criticial-transformer';
import { RemarksTransformerService } from './remarks-transformer.service';
import { LoggingApiService } from '@xpo-ltl/sdk-logging';

@Injectable()
export class BillEntryTransformerService {
  constructor(
    private commoditiesTransformerService: CommoditiesTransformerService,
    private loggingApiService: LoggingApiService,
    private remarksTransformerService: RemarksTransformerService,
    private salvageCenterValidationService: SalvageCenterValidationService
  ) {}

  toUpsertShipmentRqst(bill: Bill): UpsertShipmentRqst {
    const rqst: UpsertShipmentRqst = new UpsertShipmentRqst();
    if (bill && bill.orderHeader) {
      rqst.shipment = new Shipment();

      const stats = new BillEntryStats();
      stats.shipmentInstId = bill.orderHeader.shipmentInstanceId || undefined;
      stats.billEntryEndDateTime = new Date(bill.sessionEndTime);
      stats.billEntryStartDateTime = new Date(bill.sessionStartTime);
      stats.billEntryUserId = bill.billEntryUserId;
      stats.listActionCd = ActionCd.ADD;
      rqst.billEntryStats = [stats];

      // --- BOL USAGE ---
      if (bill.orderHeader.bolInstanceId) {
        const bolUsage: BolUsage = new BolUsage();
        bolUsage.shipmentInstId = bill.orderHeader.shipmentInstanceId || undefined;
        bolUsage.bolInstId = bill.orderHeader.bolInstanceId;
        bolUsage.typeCd = BolUsageTypeCd.BILLING;
        bolUsage.bolBillingVersionNbr = bill.orderHeader.bolVersionNumber
          ? `${bill.orderHeader.bolVersionNumber}`
          : undefined;
        rqst.bolUsage = bolUsage;
      }

      // -- OTHER ---
      rqst.shipment.sourceCd = ShipmentSourceCdHelper.toEnum(bill.orderHeader.shipmentSourceCd);
      rqst.shipment.shipmentInstId = bill.orderHeader.shipmentInstanceId || undefined;
      rqst.parentInstId = bill.orderHeader.parentInstanceId;

      // --- ACCESSORIALS ---
      rqst.accessorialService = AccessorialTransformer.toUpsertShipment(bill.accessorials);

      // Commodities
      if (bill.orderDetails && bill.orderDetails.commodities) {
        rqst.commodity = this.commoditiesTransformerService.toUpsertShipment(
          bill.orderDetails.commodities,
          bill.orderHeader.proNumber
        );
      }

      // --- REMARKS ---
      rqst.remark = this.remarksTransformerService.toUpsertShipment(bill.remarks);

      // --- ORDER DETAILS ---
      // bill.orderDetails.commodityProfile; // TODO
      rqst.shipment.totalChargeAmount = bill.orderDetails.items ? bill.orderDetails.items : undefined;

      // bill.orderDetails.shipperCommodityProfileStatus; // TODO
      rqst.shipment.totalPiecesCount = bill.orderDetails.totalPieces;
      rqst.shipment.totalWeightLbs = bill.orderDetails.totalWeight;
      rqst.shipment.totalPalletsCount = bill.orderDetails.totalPalletCount;

      rqst.shipment.billClassCd = bill.orderHeader.billClassCode;
      const nonRevenueBill = !(
        rqst.shipment.billClassCd === BillClassCd.SPLIT_SEGMENT ||
        rqst.shipment.billClassCd === BillClassCd.NORMAL_MVMT ||
        rqst.shipment.billClassCd === BillClassCd.ACCESSORIAL_ONLY
      );

      // --- ORDER HEADER ---
      rqst.shipment.chargeToCd = nonRevenueBill ? undefined : bill.orderHeader.debtorTypeCode;
      // bill.orderHeader.formName; // TODO
      rqst.shipment.pickupDate = DateTimeHelper.conditionDateToServer(bill.orderHeader.pickupDate);
      rqst.shipment.proNbr = bill.orderHeader.proNumber;
      // bill.orderHeader.relatedProNumber; // TODO
      rqst.shipment.spotQuoteId = Number.parseInt(bill.orderHeader.spotQuote) || undefined;

      // --- PARTIES ---
      if (bill.asEnteredBolParties) {
        rqst.asEnteredBolParty = PartiesTransformer.toAsEnteredBolParty(bill.asEnteredBolParties, nonRevenueBill);
        rqst.shipment.originTerminalSicCd = bill.asEnteredBolParties[PARTY_TYPE_ID.Shipper].sicCd;
        rqst.shipment.destinationTerminalSicCd = bill.asEnteredBolParties[PARTY_TYPE_ID.Consignee].sicCd;
      }

      if (bill.asMatchedParties && bill.asEnteredBolParties) {
        rqst.asMatchedParty = PartiesTransformer.toAsMatchedParty(
          bill.asMatchedParties,
          bill.orderHeader.debtorTypeCode,
          bill.asEnteredBolParties
        );
      }

      // SRNS
      SrnTransformer.toUpsertShipment(bill.supplementalReferenceNumberCollection, rqst);

      // --- ADDITIONAL INFORMATION ---
      AdditionalInformationTransformer.toUpsertShipment(bill.additionalInformation, rqst);

      // // --- SPECIAL INSTRUCTIONS --
      SpecialInstructionsTransformer.toUpsertShipmentRqst(bill.specialInstructions, rqst);

      if (bill.timeDateCritical) {
        rqst.shipment.callForAppointmentInd = bill.timeDateCritical.tdc || false;
        rqst.shipment.destinationNotifyInd = bill.timeDateCritical.notifyOnArrival || false;
        rqst.shipment.appointmentRequiredInd = bill.timeDateCritical.appointmentRequired || false;

        // SPEED-2913 - Dont map EDD is is Salvage Center and GCBZ
        if (
          bill.orderHeader.billClassCode !== BillClassCd.GENERAL_CLAIMS_BUS_SHPMT &&
          !this.salvageCenterValidationService.containsSalvageCenterParty(bill.asMatchedParties)
        ) {
          rqst.shipment.estimatedDeliveryDate = DateTimeHelper.conditionDateToServer(
            bill.timeDateCritical.estimatedDeliveryDate
          );
        }

        rqst.timeDateCritical = TimeDateCriticalTransformer.toUpsertShipmentRqst(bill);
      }
    }

    return rqst;
  }

  /** For SRN corrections */
  toUpsertShipmentSuppRefNbrsRqst(bill: Bill): UpsertShipmentSuppRefNbrsRqst {
    const rqst: UpsertShipmentSuppRefNbrsRqst = new UpsertShipmentSuppRefNbrsRqst();
    if (bill && bill.supplementalReferenceNumberCollection) {
      SrnTransformer.toUpsertShipmentSuppRefNbrs(bill.supplementalReferenceNumberCollection, rqst);
    }

    return rqst;
  }

  toBill(resp: GetShipmentResp): Observable<Bill> {
    const observableResults = new ReplaySubject<Bill>(1);
    const bill: Bill = new Bill();
    bill.source = BillSource.SHIPMENT;

    const completeFunc = () => {
      observableResults.next(bill);
      observableResults.complete();
    };

    if (resp) {
      // Order Header
      bill.orderHeader = new OrderHeader();
      bill.orderHeader.billClassCode = BillClassCdHelper.toEnum(resp.shipment.billClassCd) || BillClassCd.NORMAL_MVMT;
      bill.orderHeader.debtorTypeCode = ChargeToCdHelper.toEnum(resp.shipment.chargeToCd);
      bill.orderHeader.pickupDate = DateTimeHelper.conditionDateFromServer(new Date(resp.shipment.pickupDate));
      bill.orderHeader.proNumber = resp.shipment.proNbr;
      bill.orderHeader.shipmentInstanceId = resp.shipment.shipmentInstId;
      bill.orderHeader.billStatusCode = resp.shipment.billStatusCd;
      bill.orderHeader.shipmentSourceCd = resp.shipment.sourceCd;
      bill.orderHeader.relatedProNumber = resp.parentProNbr;
      bill.orderHeader.spotQuote = resp.shipment.spotQuoteId ? `${resp.shipment.spotQuoteId}` : undefined;
      bill.orderHeader.debtorTermFlipInd = resp.shipment.debtorTermFlipInd;

      // Stuff entered user id and timestamp if billed
      if (bill.orderHeader.billStatusCode === BillStatusCd.BILLED) {
        if (resp.billEntryStats && resp.billEntryStats.length > 0) {
          bill.billEntryDate = DateTimeHelper.conditionTimeFromServer(
            new Date(resp.billEntryStats[resp.billEntryStats.length - 1].billEntryEndDateTime)
          );
          bill.billEntryUserId = resp.billEntryStats[resp.billEntryStats.length - 1].billEntryUserId;
        }
      }

      // Parties
      bill.asEnteredBolParties = PartiesTransformer.fromAsEnteredBolParty(
        resp.asEnteredBolParty,
        resp.shipment.originTerminalSicCd,
        resp.shipment.destinationTerminalSicCd
      );
      bill.asMatchedParties = PartiesTransformer.fromAsMatchedParty(
        resp.asMatchedParty,
        resp.shipment.originTerminalSicCd,
        resp.shipment.destinationTerminalSicCd
      );

      // SRNs
      bill.supplementalReferenceNumberCollection = SrnTransformer.toBill(resp);
      let hasGuaranteedAccessorial = false;
      let hasRrsAccessorial = false;
      let hasG12Accessorial = false;

      // Accessorials
      if (resp.accessorialService) {
        this.setAccessorialsToBill(bill, resp.accessorialService, (accessorialCd) => {
          if (AccessorialTransformer.GurCode === accessorialCd) {
            hasGuaranteedAccessorial = true;
          }
          if (AccessorialTransformer.RrsCode === accessorialCd) {
            hasRrsAccessorial = true;
          }
          if (AccessorialTransformer.G12Code === accessorialCd) {
            hasG12Accessorial = true;
          }
        });
      }

      // Special Services
      bill.specialInstructions = SpecialInstructionsTransformer.toBill(resp);

      // Add flag if accessorial exists or accessorial if flag exists
      if (hasGuaranteedAccessorial) {
        bill.specialInstructions.guaranteedInd = true;
      } else if (bill.specialInstructions.guaranteedInd) {
        // May not have accessorials yet
        if (!bill.accessorials) {
          bill.accessorials = [];
        }
        bill.accessorials.push(AccessorialTransformer.createAccessorial(AccessorialTransformer.GurCode));
      }

      if (hasRrsAccessorial && hasRrsAccessorial === true) {
        bill.specialInstructions.rapidRemoteServiceInd = true;
      }
      if (hasG12Accessorial && hasG12Accessorial === true) {
        bill.specialInstructions.g12Ind = true;
      }

      // Order Details
      bill.orderDetails = new OrderDetails();
      bill.orderDetails.totalPieces = resp.shipment.totalPiecesCount;
      bill.orderDetails.initialTotalPieces = resp.shipment.totalPiecesCount;
      bill.orderDetails.totalWeight = resp.shipment.totalWeightLbs;
      bill.orderDetails.initialTotalWeight = resp.shipment.totalWeightLbs;
      bill.orderDetails.totalPalletCount = resp.shipment.totalPalletsCount;

      // --- TIME DATE CRITICAL ---
      bill.timeDateCritical = TimeDateCriticalTransformer.toBill(
        resp.timeDateCritical,
        resp.shipment.estimatedDeliveryDate
          ? DateTimeHelper.conditionDateFromServer(new Date(resp.shipment.estimatedDeliveryDate))
          : undefined,
        resp.shipment
      );

      // remarks
      if (resp.remark && resp.remark.length > 0) {
        bill.remarks = new Remarks();
        resp.remark.forEach((respRemark) => {
          switch (respRemark.typeCd) {
            case ShipmentRemarkTypeCd.ADHOC_HZ_MATL_RMK: {
              bill.remarks.hazmatEmergencyContactInfo = respRemark.remark;
              break;
            }
            case ShipmentRemarkTypeCd.PIECE_CNT_RMK: {
              bill.remarks.pieceCount = respRemark.remark;
              break;
            }
            case ShipmentRemarkTypeCd.OSD_CNTCT: {
              bill.remarks.osdContact = respRemark.remark;
              break;
            }
            case ShipmentRemarkTypeCd.SHIPPING_RMK: {
              bill.remarks.operationalRemarks = respRemark.remark;
              break;
            }
            default: {
              break;
            }
          }
        });
      }

      // --- ADDITIONAL INFORMATION ---
      bill.additionalInformation = AdditionalInformationTransformer.toBill(resp);

      // Commodities
      this.commoditiesTransformerService
        .mapCommodity(resp.commodity, false, bill.orderHeader.billStatusCode !== BillStatusCd.BILLED)
        .subscribe(
          (results) => {
            bill.orderDetails.commodities = results;
          },
          (error) => {
            this.loggingApiService.error(`Error while parsing commodity information.  ${error.message}`);
          },
          () => {
            completeFunc();
          }
        );
    } else {
      completeFunc();
    }
    return observableResults;
  }

  toBillCorrect(resp: GetShipmentFromDb2Resp): Bill {
    const bill: Bill = new Bill();

    if (resp) {
      // Order Header
      bill.orderHeader = new OrderHeader();
      bill.orderHeader.billClassCode = BillClassCdHelper.toEnum(resp.shipment.billClassCd) || BillClassCd.NORMAL_MVMT;
      bill.orderHeader.debtorTypeCode = ChargeToCdHelper.toEnum(resp.shipment.chargeToCd);
      bill.orderHeader.pickupDate = DateTimeHelper.conditionDateFromServer(new Date(resp.shipment.pickupDate));
      bill.orderHeader.proNumber = resp.shipment.proNbr;
      bill.orderHeader.shipmentInstanceId = resp.shipment.shipmentInstId;
      bill.orderHeader.billStatusCode = resp.shipment.billStatusCd;
      bill.orderHeader.shipmentSourceCd = resp.shipment.sourceCd;
      bill.orderHeader.spotQuote = resp.shipment.spotQuoteId ? `${resp.shipment.spotQuoteId}` : undefined;
      bill.orderHeader.debtorTermFlipInd = resp.shipment.debtorTermFlipInd;

      // Parties
      bill.asMatchedParties = PartiesTransformer.fromAsMatchedParty(
        resp.asMatchedParty,
        resp.shipment.originTerminalSicCd,
        resp.shipment.destinationTerminalSicCd
      );

      // SRNS
      bill.supplementalReferenceNumberCollection = SrnTransformer.toBill(resp);

      // Accessorials
      if (resp.accessorialService) {
        this.setAccessorialsToBill(bill, resp.accessorialService);
      }

      // --- TIME DATE CRITICAL ---
      bill.timeDateCritical = TimeDateCriticalTransformer.toBill(
        resp.timeDateCritical,
        resp.shipment.estimatedDeliveryDate
          ? DateTimeHelper.conditionDateFromServer(new Date(resp.shipment.estimatedDeliveryDate))
          : undefined,
        resp.shipment
      );

      // set SrnCorrection true as current bill object is for SrnCorrections
      bill.isSrnCorrection = true;
    }
    return bill;
  }

  private setAccessorialsToBill(bill: Bill, accessorials: AccessorialService[], iteratorFn?: (accesorial) => void) {
    accessorials.forEach((respAccessorial) => {
      const acc = new Accessorial();
      acc.chargeToCode = respAccessorial.chrgToCd;
      acc.sequenceNbr = Number.parseInt(respAccessorial.sequenceNbr);
      acc.amount = respAccessorial.amount;
      acc.actionCd = respAccessorial.listActionCd;
      acc.accessorialCd = respAccessorial.accessorialCd;
      acc.accessorialDesc = respAccessorial.description;
      acc.unitOfMeasure = AccessorialUnitOfMeasureCdHelper.toEnum(respAccessorial.accessorialUnitOfMeasure);
      acc.quantity = respAccessorial.accessorialQuantity ? respAccessorial.accessorialQuantity : undefined;
      bill.accessorials.push(acc);
      if (iteratorFn) {
        iteratorFn(acc.accessorialCd);
      }
      // if (AccessorialTransformer.GurCode === acc.accessorialCd) {
      //   hasGuaranteedAccessorial = true;
      // }
      // if (AccessorialTransformer.RrsCode === acc.accessorialCd) {
      //   hasRrsAccessorial = true;
      // }
      // if (AccessorialTransformer.G12Code === acc.accessorialCd) {
      //   hasG12Accessorial = true;
      // }
    });
  }
}
