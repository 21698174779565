import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { InfrastructureApiService } from '../services/infrastructure-api/infrastructure-api.service';
import { XpoFeedbackFormComponent } from './feedback-form.component';
import { XpoButtonModule } from '@xpo-ltl/ngx-ltl-core/button';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';

@NgModule({
  declarations: [XpoFeedbackFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    XpoButtonModule,
    XpoCardModule,
    MatFormFieldModule,
    MatInputModule
  ],
  exports: [XpoFeedbackFormComponent],
  providers: [InfrastructureApiService],
})
export class XpoFeedbackFormModule { }
