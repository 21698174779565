import { BillClassCd, ChargeToCd, ProStatusCd, BillStatusCd, ShipmentSourceCd } from '@xpo-ltl/sdk-common';

export class OrderHeader {
  proStatusCode: ProStatusCd = undefined;
  billClassCode: BillClassCd = BillClassCd.NORMAL_MVMT; // Default to norm bill
  debtorTypeCode: ChargeToCd = undefined;
  pickupDate: Date = undefined;
  proNumber: string = undefined;
  relatedProNumber: string = undefined;
  shipmentInstanceId: number = null;
  bolInstanceId: number = null;
  bolVersionNumber: number = null;
  billStatusCode: BillStatusCd;
  shipmentSourceCd: ShipmentSourceCd = undefined;
  spotQuote: string = undefined;
  parentInstanceId: number = undefined;
  debtorTermFlipInd = false;
  baseBillStatusCode?: BillStatusCd; // Auxiliary prop when is srn correction
}

// TODO: Remove when we have enums/speedy-g does more than revenue
export enum FormName {
  REVENUE = 'Order Entry - Revenue',
  NON_REVENUE = 'Order Entry - Non-Revenue',
}
