import { ActionCd } from '@xpo-ltl/sdk-common';


/**
 * Class used to track action code for list items.  By default the action is add
 * when a new items is constructed.
 */
export class Actionable {
  actionCd: ActionCd = ActionCd.ADD;
  sequenceNbr = -1;

  static handleActionCdUpdate(currentActionCd: ActionCd, shipmentInstanceId: number): ActionCd {
    if (currentActionCd !== ActionCd.ADD && (currentActionCd === ActionCd.DELETE || currentActionCd === ActionCd.NO_ACTION)) {
      let action = ActionCd.UPDATE;
      if ( !shipmentInstanceId || shipmentInstanceId < 0 ) {
        action = ActionCd.ADD;
      }
      currentActionCd = action;
    }
    return currentActionCd;
  }
}
