import { XrtSortExpressions } from './xrt-sort-expressions';

export class XrtSearchRequest {
  page: number;
  pageSize: number;
  sortExpressions: XrtSortExpressions[];
  type: string;
  filters: {[key: string]: string | string[] | Object} = {};

  constructor() { }

  toJSON() {
    const temp = [];
    const json = {
      query: {
        page: this.page,
        pageSize: this.pageSize,
        sortExpressions: this.sortExpressions,
        type: this.type,
      }
    };

    Object.keys(this.filters).forEach((key) => {
      temp['Filter.' + key] = this.filters[key];
    });

    Object.keys(temp).forEach((key) => {
      json.query[key] = temp[key];
    });

    return json;
  }
}

