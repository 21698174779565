export class LoggingConstants {
  static readonly ActivityNames = {
    ApiGatewayCall: 'API Gateway Call',
    BillEntryNavigation: 'Bill Entry Navigation',
    BillSubmitUnexpectedError: 'Bill Submit Unexpected Error',
  };
  static readonly ActionNames = {
    Request: 'request',
    Response: 'response',
    Error: 'error',
    Change: 'ValueChanged'
  };
  static readonly MESSAGE_TYPE = 'json';
}
