export class ErrorMessageParser {
  static parseMessage(err: any): string {
    let reason = '';
    try {
      const error = err['error'];
      if (error) {
        const innerError = error['error'];
        if (innerError) {
          if (innerError.moreInfo && innerError.moreInfo.some((item) => item.errorCode)) {
            const moreInfoItem = innerError.moreInfo.filter((info) => info.errorCode)[0];
            reason = `${moreInfoItem.errorCode} ${moreInfoItem.message} ${moreInfoItem.location}`;
          } else if (innerError.errorCode && innerError.message) {
            reason = `${innerError.errorCode} ${innerError.message}`;
          } else {
            reason = 'Unknown error while validating PRO number';
          }
        } else {
          if (error.moreInfo && error.moreInfo.some((item) => item.errorCode)) {
            reason = `${error.errorCode} ${error.message}`;
            const moreInfoItem = error.moreInfo.filter((info) => info.errorCode)[0];
            reason = `${moreInfoItem.errorCode}`;
          }
        }
      }
    } catch (err) {
      reason = 'Unable to parse error message';
    }
    return reason;
  }
}
