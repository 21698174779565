import { AccessorialUnitOfMeasureCd } from '@xpo-ltl/sdk-common';
import { ActionableWithChargeTo } from './actionable-with-charge-to';

export class Accessorial extends ActionableWithChargeTo {
  accessorialCd: string = undefined;
  accessorialDesc: string = undefined;
  amount = 0;
  quantity: number = undefined;
  unitOfMeasure: AccessorialUnitOfMeasureCd = undefined;
  literalInd = false;
  deleteEnabled = true;

  constructor() {
    super();
  }

  static hasValue(accessorial: Accessorial): boolean {
    return !!accessorial.accessorialCd;
  }
}
