import { Injectable } from '@angular/core';
import {
  CityOperationsApiService as SdkCityOperationsApiService,
  GetOperationsCustomerProfilePath,
  GetOperationsCustomerProfileQuery,
  GetOperationsCustomerProfileResp,
} from '@xpo-ltl/sdk-cityoperations';
import { get as _get } from 'lodash';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class CityOperationsApiService {
  private getOperationsCustomerProfileResp: GetOperationsCustomerProfileResp;

  constructor(public cityOperationService: SdkCityOperationsApiService) {}

  getOperationsCustomerProfile(customerAcctId: number): Observable<GetOperationsCustomerProfileResp> {
    if (_get(this.getOperationsCustomerProfileResp, 'customerOperationsProfile.customerAcctId') === customerAcctId) {
      return of(this.getOperationsCustomerProfileResp);
    } else if (!customerAcctId) {
      return of(undefined);
    } else {
      const path: GetOperationsCustomerProfilePath = {
        ...new GetOperationsCustomerProfilePath(),
        customerAcctId,
      };

      const query: GetOperationsCustomerProfileQuery = {
        ...new GetOperationsCustomerProfileQuery(),
      };

      return this.cityOperationService
        .getOperationsCustomerProfile(path, query)
        .pipe(tap((result: GetOperationsCustomerProfileResp) => (this.getOperationsCustomerProfileResp = result)));
    }
  }
}
