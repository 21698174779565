import { Pipe, PipeTransform } from '@angular/core';
import { BillClassCd } from '@xpo-ltl/sdk-common';
@Pipe({
  name: 'billType'
})
export class BillTypePipe implements PipeTransform {

  transform(value: BillClassCd, args?: any): any {
    let labelValue = '';
    switch ( value ) {
      case BillClassCd.SPLIT_SEGMENT:
      case BillClassCd.NORMAL_MVMT:
      case BillClassCd.ACCESSORIAL_ONLY:
        labelValue = 'Revenue Bill';
        break;
      case BillClassCd.PARTIAL_SEGMENT:
      case BillClassCd.MASTER_SEGMENT:
      case BillClassCd.MASTER_SHPMT:
      case BillClassCd.EXPEDITE:
      case BillClassCd.ASTRAY_FRT_SEGMENT:
      case BillClassCd.CLAIMS_OVRG_RPTG_BILL:
      case BillClassCd.CO_BUS_SHPMT:
      case BillClassCd.GENERAL_CLAIMS_BUS_SHPMT:
        labelValue = 'Non-Revenue Bill';
        break;
    }
    return labelValue;
  }

}
