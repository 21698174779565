export enum PartyFormFields {
  PartyTypeId = 'partyTypeId',
  PartyTypeName = 'partyTypeName',
  Name1 = 'name1',
  Name2 = 'name2',
  Address = 'address',
  City = 'city',
  State = 'state',
  Zip = 'zip',
  Zip4 = 'zip4',
  ZipJoined = 'zipJoined',
  Phone = 'phone',
  Extension = 'extension',
  Country = 'country',
  CustomerNumber = 'customerNumber',
  SicCd = 'sicCd',
  MatchedStatusCd = 'matchedStatusCd',
  MatchedSourceCd = 'matchedSourceCd',
  MadCd = 'madCode',
  HardErrors = 'hardErrors',
  SequenceNbr = 'sequenceNbr',
  ActionCd = 'actionCd',
  DirFlag = 'dirFlag',
  SelfInvoiceInd = 'selfInvoiceInd',
  shipToSalvageSelected = 'shipToSalvageSelected',
}
