import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { User } from '../model/user';
import { UserApiService } from '../services/user-api/user-api.service';

@Injectable()
export class LoggedInUserResolver implements Resolve<User> {
  constructor(private userService: UserApiService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<any> {
    return new Promise((resolve, reject) => {
      this.userService.getLoggedInUser(false, false).then(
        (response) => {
          // todo: stick this in a service somewhere?
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
