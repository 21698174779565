import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { AccessorialPipe } from './accessorial.pipe';
import { BillClassCdPipe } from './bill-class-cd.pipe';
import { BillTypePipe } from './bill-type.pipe';
import { BolPartyTypeCdPipe } from './bol-party-type-cd.pipe';
import { CamelCaseToSpacesPipe } from './camelcase-to-spaces.pipe';
import { ChargeToCdPipe } from './charge-to-cd.pipe';
import { CommodityClassCdPipe } from './commodity-class-cd.pipe';
import { CommodityPackageCdPipe } from './commodity-package-cd.pipe';
import { CustomerTypeCdPipe } from './customer-type-cd.pipe';
import { MatchInfoPipe } from './match-info.pipe';
import { MatchedPartyStatusCdPipe } from './matched-party-status-cd.pipe';
import { PartyFormatterPipe } from './party-formatter.pipe';
import { XpoPhoneNumberPipe } from './phone-number.pipe';
import { ProNumberFormatterPipe } from './pro-number-formatter.pipe';
import { RoundPipe } from './round.pipe';
import { RuleOverrideReasonCdPipe } from './rule-override-reason-cd.pipe';
import { StatePipe } from './state.pipe';
import { XpoTimeFormatterPipe } from './time-formatter.pipe';
import { UtcDateFormatPipe } from './utc-date-formatter.pipe';

const pipeModules = [
  AccessorialPipe,
  BillClassCdPipe,
  BolPartyTypeCdPipe,
  CamelCaseToSpacesPipe,
  ChargeToCdPipe,
  CommodityClassCdPipe,
  CommodityPackageCdPipe,
  MatchedPartyStatusCdPipe,
  ProNumberFormatterPipe,
  RuleOverrideReasonCdPipe,
  XpoPhoneNumberPipe,
  XpoTimeFormatterPipe,
  BillTypePipe,
  PartyFormatterPipe,
  CustomerTypeCdPipe,
  UtcDateFormatPipe,
  RoundPipe,
  MatchInfoPipe,
  StatePipe,
];

@NgModule({
  declarations: pipeModules,
  imports: [CommonModule],
  exports: pipeModules,
  providers: pipeModules,
})
export class XpoPipeModule { }
