import {
  CommodityClassCd,
  CommodityClassCdHelper,
  CommodityPackageCd,
  CommodityPackageCdHelper,
} from '@xpo-ltl/sdk-common';

import { CommodityPackageCdPipe } from '../../../shared/pipes/commodity-package-cd.pipe';
import { HazmatDetails } from '../../hazmat-details/model';
import { ActionableWithChargeTo } from './actionable-with-charge-to';
import { CommodityFormFields } from './commodity-form-fields.enum';

export class Commodity extends ActionableWithChargeTo {
  static readonly INVALID_COMMODITIES = ['Clss350'];

  static fieldsWithValidators = [
    CommodityFormFields.ChargeToCode,
    CommodityFormFields.Pieces,
    CommodityFormFields.Weight,
    CommodityFormFields.Packaging,
    CommodityFormFields.Class,
    CommodityFormFields.Description,
    CommodityFormFields.HazmatInd,
  ];

  static packagingListOptions: string[] = CommodityPackageCdHelper.values()
    .sort()
    .map((v) => CommodityPackageCdPipe.prototype.transform(v));
  static commodityClassListOptions: string[] = CommodityClassCdHelper.values()
    .filter((commodity) => !Commodity.INVALID_COMMODITIES.some((invalid) => invalid === commodity))
    .sort(Commodity.commodityClassCodeSort);

  pieces: number = undefined;
  weight: number = undefined;
  packaging: CommodityPackageCd = undefined;
  nmfc: string = undefined;
  class: CommodityClassCd = undefined;
  description: string = undefined;
  hazmatInd = false;
  hazmatDetails: HazmatDetails = undefined;
  originalDescription: string = undefined;
  classInd: boolean = false;

  constructor() {
    super();
  }

  static hasValue(v: Commodity): boolean {
    return !!v.pieces || !!v.weight || !!v.packaging || !!v.class || !!v.description;
  }

  /**
   * Returns true if commodity array contains a commodity with hazmatInd set to true.
   *
   * @static
   * @param {Commodity[]} commodities
   * @returns {boolean}
   * @memberof Commodity
   */
  static hasHazmatCommodity(commodities: Commodity[]): boolean {
    return commodities.some((commodity) => commodity.hazmatInd);
  }

  static commodityClassCodeSort(val1: string, val2: string) {
    let number1 = Number.parseInt(val1.substring(4));
    let number2 = Number.parseInt(val2.substring(4));
    if (number1 === 925 || number1 === 775) {
      number1 = number1 / 10;
    }
    if (number2 === 925 || number2 === 775) {
      number2 = number2 / 10;
    }
    return number1 - number2;
  }
}
