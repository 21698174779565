import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { ChromeVersionGuardDialogComponent } from './chrome-version-guard-dialog.component';
import { XpoCardModule } from '@xpo-ltl/ngx-ltl-core/card';
import { XpoDialogModule } from '@xpo-ltl/ngx-ltl-core/dialog';
import { MatDialogModule } from '@angular/material/dialog';

@NgModule({
  declarations: [ChromeVersionGuardDialogComponent],
  imports: [CommonModule, XpoDialogModule, XpoCardModule, MatDialogModule ],
  entryComponents: [ChromeVersionGuardDialogComponent]
})
export class XpoChromeVersionGuardModule { }
