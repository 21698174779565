// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  firebase: {
  apiKey: "AIzaSyBIo-Gnt7bOdJLouRxWEeDzmBkI-h90JjY",
  authDomain: "xpo-ltl-dev-cf3b6.firebaseapp.com",
  databaseURL: "https://xpo-ltl-dev-cf3b6.firebaseio.com",
  projectId: "xpo-ltl-dev",
  storageBucket: "xpo-ltl-dev.appspot.com",
  messagingSenderId: "339239167082",
  appId: "1:339239167082:web:3ce32ab40948ae98f29613"
  },
};
