import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { XpoFeedbackFormConfig } from '../shared/feedback-form/feedback-form.config';
import { UserApiService } from '../shared/services/user-api/user-api.service';
import { ConfigManagerProperties } from './model/config-manager-properties.enum';

export const feedbackFormFactory = (configManager: ConfigManagerService, userService: UserApiService) => {
  const subject = `${configManager.getSetting(ConfigManagerProperties.feedbackEmailSubject)} - ${configManager.getSetting(ConfigManagerProperties.region)}`;
  const title = configManager.getSetting(ConfigManagerProperties.feedbackEmailSubject);
  const fromName = (
    userService.cachedUser.emailAddress
    || userService.cachedUser.userId ? userService.cachedUser.userId.split('@')[0] : 'no-id'
  );
  const fromEmail = userService.cachedUser.emailAddress || configManager.getSetting(ConfigManagerProperties.feedbackEmailAddress);
  return {
    disableFromNameInput: true,
    disableSubjectInput: true,
    fromEmail: fromEmail,
    fromName: fromName,
    invalidCharacterPattern: configManager.getSetting(ConfigManagerProperties.illegalCharacterDetectionPattern),
    subject: subject,
    title: title,
    toEmail: configManager.getSetting(ConfigManagerProperties.feedbackEmailAddress),
  } as XpoFeedbackFormConfig;
};
