import { Component, OnInit, ElementRef, Input, Directive, HostListener, OnDestroy, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { XpoFormPanelService } from './form-panel.service';

let uid = 0;

@Component({
  selector: 'xpo-form-panel',
  templateUrl: 'form-panel.component.html',
  styleUrls: ['form-panel.component.scss'],
})
export class XpoFormPanelComponent implements OnInit, OnDestroy {
  private _headerDisplay: string;
  private _formStatus = 'hidden';
  private _focusElement: HTMLElement | HTMLElement[];
  private focusedPanelSubscription: Subscription;

  constructor(public panelService: XpoFormPanelService, private el: ElementRef) {}

  get offsetTop(): number {
    return this.el.nativeElement.offsetTop;
  }

  /**
   * Focusable element in the panel,
   * if its an array it toggles through all the elements
   *
   * @type {ElementRef}
   * @memberof XpoFormPanelComponent
   */
  @Input()
  set focusElement(value: HTMLElement | HTMLElement[]) {
    if (value instanceof Array) {
      this._focusElement = value;
      if (!this.focusedPanelSubscription) {
        this.subscribeToFocusChanges();
      }
    } else if (value) {
      this._focusElement = value;
    }
  }

  get focusElement(): HTMLElement | HTMLElement[] {
    return this._focusElement;
  }

  @Input() focusElementIndex = 0;

  /**
   * Title of the panel shown on card header
   *
   * @memberof XpoFormPanelComponent
   */
  @Input() title = 'panel-' + uid++;

  /**
   * Used in shortcut keys
   *
   * @memberof XpoFormPanelComponent
   */
  @Input() altKey = '';

  /**
   * Used in shortcut keys for mac
   *
   * @memberof XpoFormPanelComponent
   */
  @Input() macAltKey = '';

  /**
   * Title Displayed on the form header (if you wanted to use a shorter name)
   * defaults to to title of panel
   *
   * @type {string}
   * @memberof XpoFormPanelComponent
   */
  @Input()
  get headerDisplay(): string {
    let value = this._headerDisplay ? this._headerDisplay : this.title;
    if (this.altKey) {
      const index = value.toUpperCase().indexOf(this.altKey.toUpperCase());
      if (index >= 0) {
        if (index === 0) {
          value = `<u>${value[0]}</u>${value.slice(index + 1)}`;
        } else if (index === value.length - 1) {
          value = `${value.slice(0, index)}<u>${value[index]}</u>`;
        } else {
          value = `${value.slice(0, index)}<u>${value[index]}</u>${value.slice(index + 1)}`;
        }
      }
    }
    return value;
  }
  set headerDisplay(v: string) {
    this._headerDisplay = v;
  }

  /**
   * Status of the panel, used to color the badges
   *
   * @memberof XpoFormPanelComponent
   */
  @Input()
  set formStatus(v: string) {
    if (v) {
      this._formStatus = v.toLowerCase();
    }
  }
  get formStatus(): string {
    return this._formStatus;
  }

  /**
   * Some panels have warnings, so this is to toggle the orange badge
   *
   * @memberof XpoFormPanelComponent
   */
  @Input() hasWarning:any = false;

  ngOnInit() {
    this.panelService.addPanel(this);
  }

  ngOnDestroy() {
    if (this.focusedPanelSubscription) {
      this.focusedPanelSubscription.unsubscribe();
      this.focusedPanelSubscription = null;
    }
  }

  /**
   * Sets the focus to the assigned focus element and scrolls the panel into view.
   * If its an array of focusable elements it toggles through them every time this is called.
   * When it reaches the last element it restarts to the first one.
   *
   * @memberof XpoFormPanelComponent
   */
  setFocus() {
    if (this.focusElement instanceof Array) {
      this.focusElement[this.focusElementIndex % this.focusElement.length].focus();
      this.focusElementIndex++;
    } else {
      this.focusElement.focus();
    }

    this.el.nativeElement.scrollIntoView(true);
  }

  /**
   * When a child input is focused, brodcast out that its the selected panel.
   * TODO: make the selected panel in the header run on this instead of the scroll position
   *
   * @param {any} event
   * @memberof XpoFormPanelComponent
   */
  handleChildInputFocus(event) {
    this.panelService.setFocusedPanel(this);
  }

  /**
   * Subscribes to the focused panel, if its not the focused panel, it resets the focus element index to 0,
   * so when focus on this panel again, it starts at the first focusable element
   *
   * @private
   * @memberof XpoFormPanelComponent
   */
  private subscribeToFocusChanges(): void {
    this.focusedPanelSubscription = this.panelService.focusedPanel.subscribe((panel) => {
      if (panel !== this) {
        this.focusElementIndex = 0;
      }
    });
  }

  generateClass(title){
    return title.replace(/\s+/g, '-').toLowerCase() + '-style';
  }
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[formControlName]',
})
export class XpoFormPanelInputDirective {
  constructor(@Optional() private parentPanel: XpoFormPanelComponent) {}

  @HostListener('focus', ['$event'])
  onFocust(event) {
    if (this.parentPanel) {
      this.parentPanel.handleChildInputFocus(event);
    }
  }
}
