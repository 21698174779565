import { RuleOverrideReasonCd, RuleFailureReasonCd, RuleShmEntityTypeCd } from '@xpo-ltl/sdk-common';
import { ActionCd } from '@xpo-ltl/sdk-common';
import {
  UpsertShipmentRqst,
  SuppRefNbr,
  RuleOverride,
  GetShipmentResp,
  GetShipmentFromDb2Resp,
  UpsertShipmentSuppRefNbrsRqst,
} from '@xpo-ltl/sdk-shipment';
import { SupplementalReferenceNumberCollection, SupplementalReferenceNumber } from '../../../../bill-entry/model';

export class SrnTransformer {
  private static MAX_INSTRUCTION_LENGTH = 120;
  constructor() {}

  static toUpsertShipment(srnCollection: SupplementalReferenceNumberCollection, rqst: UpsertShipmentRqst): void {
    rqst.suppRefNbr = [];
    rqst.ruleOverrides = [];

    if (srnCollection && srnCollection.referenceNumbers && srnCollection.referenceNumbers.length > 0) {
      srnCollection.referenceNumbers.forEach((srn) => {
        if (srn.referenceType && srn.referenceType.trim().length > 0 && srn.number) {
          const srnNbr = new SuppRefNbr();
          srnNbr.sequenceNbr = `${srn.sequenceNbr}`;
          srnNbr.refNbr = srn.number;
          srnNbr.typeCd = srn.referenceType;
          srnNbr.listActionCd = srn.actionCd;
          rqst.suppRefNbr.push(srnNbr);
        }
      });

      // speed -3119,  dont create override if the srn is NOT required
      srnCollection.referenceNumbers.forEach((srn) => {
        if (srn.statusReason && srn.statusReason.toString() !== 'false' && srn.ruleInstanceId && srn.required) {
          const override = new RuleOverride();
          override.overrideReasonCd = srn.statusReason;
          override.failureReasonCd = SrnTransformer.overrideToFailureReason(srn.statusReason);
          override.shmEntityTypeCd = RuleShmEntityTypeCd.SUPP_REF_NBR;
          override.ruleInstId = srn.ruleInstanceId;
          override.errorDescription = SrnTransformer.getTruncatedSpecialInstructions(srn) || srn.referenceType;
          override.shmEntityTypeSequenceNbr = `${srn.sequenceNbr}`;
          rqst.ruleOverrides.push(override);
        }
      });
    }
  }

  /** upsert for SRN corrections */
  static toUpsertShipmentSuppRefNbrs(
    srnCollection: SupplementalReferenceNumberCollection,
    rqst: UpsertShipmentSuppRefNbrsRqst
  ): void {
    rqst.suppRefNbr = [];
    rqst.ruleOverrides = [];

    if (srnCollection && srnCollection.referenceNumbers && srnCollection.referenceNumbers.length > 0) {
      srnCollection.referenceNumbers.forEach((srn) => {
        if (srn.referenceType && srn.referenceType.trim().length > 0 && srn.number) {
          // for srn numbers
          const srnNbr = new SuppRefNbr();
          srnNbr.sequenceNbr = '-1'; // default to -1, API will generate seq
          srnNbr.refNbr = srn.number;
          srnNbr.typeCd = srn.referenceType;
          srnNbr.listActionCd = ActionCd.ADD; // default the actionCd to ADD, API is removing the existing and replacing it with new
          rqst.suppRefNbr.push(srnNbr);
        }

        // speed -3119,  dont create override if the srn is NOT required
        if (srn.statusReason && srn.statusReason.toString() !== 'false' && srn.ruleInstanceId && srn.required) {
          // for rule overrides
          const override = new RuleOverride();
          override.overrideReasonCd = srn.statusReason;
          override.failureReasonCd = SrnTransformer.overrideToFailureReason(srn.statusReason);
          override.shmEntityTypeCd = RuleShmEntityTypeCd.SUPP_REF_NBR;
          override.ruleInstId = srn.ruleInstanceId;
          override.errorDescription = SrnTransformer.getTruncatedSpecialInstructions(srn) || srn.referenceType;
          override.shmEntityTypeSequenceNbr = `${srn.sequenceNbr}`;
          rqst.ruleOverrides.push(override);
        }
      });
    }
  }

  static getTruncatedSpecialInstructions(srn: SupplementalReferenceNumber) {
    let specialInstructions = srn.specialInstructions;
    if (specialInstructions && specialInstructions.length > SrnTransformer.MAX_INSTRUCTION_LENGTH) {
      specialInstructions = specialInstructions.substring(0, SrnTransformer.MAX_INSTRUCTION_LENGTH);
    }
    return specialInstructions;
  }

  static suppRefNbrMapper(supRefNbr: SuppRefNbr): SupplementalReferenceNumber {
    const srn = new SupplementalReferenceNumber();
    srn.actionCd = supRefNbr.listActionCd;
    srn.number = supRefNbr.refNbr;
    srn.referenceType = supRefNbr.typeCd;
    srn.specialInstructions = supRefNbr.description;
    srn.sequenceNbr = Number.parseInt(supRefNbr.sequenceNbr);
    return srn;
  }
  static toBill(resp: GetShipmentResp | GetShipmentFromDb2Resp): SupplementalReferenceNumberCollection {
    const srnCollection = new SupplementalReferenceNumberCollection();
    srnCollection.referenceNumbers = [];
    if (resp.suppRefNbr) {
      srnCollection.referenceNumbers = resp.suppRefNbr.map(SrnTransformer.suppRefNbrMapper);
    }
    return srnCollection;
  }

  /**
   * Returns Failure reason associated with override reason.
   *
   * @private
   * @static
   * @param {RuleOverrideReasonCd} overrideReason
   * @returns {RuleFailureReasonCd}
   * @memberof SrnTransformer
   */
  private static overrideToFailureReason(overrideReason: RuleOverrideReasonCd): RuleFailureReasonCd {
    switch (overrideReason) {
      case RuleOverrideReasonCd.SRN_AVAILABLE_NOT_VALID: {
        return RuleFailureReasonCd.SRN_NOT_VALID;
      }
      case RuleOverrideReasonCd.SRN_NOT_AVAILABLE: {
        return RuleFailureReasonCd.SRN_NOT_ENTERED;
      }
    }
  }
}
