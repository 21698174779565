import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { AngularFireModule } from "@angular/fire/compat";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION } from '@xpo-ltl/config-manager';
import { XpoLtlAuthConfigLoaderService, XpoLtlAuthModule, } from '@xpo-ltl/ngx-auth';
import { XpoConfirmDialogModule, } from '@xpo-ltl/ngx-ltl-core/confirm-dialog';
import { XPO_AUTH_CONFIG, XpoAuthConfig } from '@xpo/ngx-auth';
import { XpoPageNotFoundRoutingModule } from '@xpo/ngx-core/error-page';
import { environment } from 'environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import {
  APPLICATION_MINIMUM_VERSION_CONFIG
} from './core/guards/application-minimum-version/application-minimum-version-config';
import { GlobalErrorHandlingLogger } from './shared/services/logging-api/global-error-handling-logger';
import { SharedModule } from './shared/shared.module';
import { CorrelationIdHttpInterceptor } from '@xpo-ltl/data-api';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SdkLoggingModule } from '@xpo-ltl/sdk-logging';

export function authConfigFactory(configService: XpoLtlAuthConfigLoaderService): XpoAuthConfig {
  configService.initSettings('./assets/config.json');

  return new XpoAuthConfig({
    appCode: configService.appCode,
    scopes: configService.scopes,
    isProd: configService.isProd,
    applicationRootUri: configService.appRootUri,
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AngularFireModule.initializeApp(environment.firebase),
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule,
    // LoginModule,
    SharedModule,
    XpoConfirmDialogModule,
    XpoLtlAuthModule.forRoot({
      provide: XPO_AUTH_CONFIG,
      useFactory: authConfigFactory,
      deps: [XpoLtlAuthConfigLoaderService],
    }),
    AppRoutingModule,
    MatProgressSpinnerModule,
    SdkLoggingModule,
    XpoPageNotFoundRoutingModule
    // always make last
  ],
  providers: [
    {
      provide: CONFIG_MANAGER_SERVICE_CONFIGURATION,
      useValue: { configFileUrl: './assets/config.json' },
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CorrelationIdHttpInterceptor,
      multi: true,
    },
    { provide: ErrorHandler, useClass: GlobalErrorHandlingLogger },
    {
      provide: APPLICATION_MINIMUM_VERSION_CONFIG,
      useValue: {
        versionMismatchRoute: 'version-mismatch',
        bypassValues: ['local-build'],
        rootRoute: 'ebol-search',
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor() {
  }
}
