import { Injectable } from '@angular/core';
import { User } from '../../model/user';
import { BaseService } from '../base-service';
import { DataApiService } from '../data-api/data-api.service';
import { DataOptions } from '../data-api/data-options';

import { Envelope } from '@xpo-ltl/api-common';
import { catchError, timeout } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { BehaviorSubject, of } from 'rxjs';
import { ConfigManagerProperties } from '../../../core/model/config-manager-properties.enum';

@Injectable()
export class UserApiService extends BaseService {
  public _cachedUser: User = null;
  public _loggedInUserData = new BehaviorSubject<any>(null);
  loggedInUserData$ = this._loggedInUserData.asObservable();

  /**
   * Check Fixed Roles against User roles
   * @param {string[]} userRoles
   * @param {string[]} matchingRoles
   * @returns {boolean}
   */
  static findRoleMatch(userRoles: string[], matchingRoles: string[]): boolean {
    let hasRole = false;

    if ( !matchingRoles || (matchingRoles.length === 0)) {
      return true;
    }

    if ( !userRoles || (userRoles.length === 0)) {
      return false;
    }

    for (let a = 0; a < userRoles.length && !hasRole; a++ ) {
      for (const matchingRole of matchingRoles) {
        if (userRoles[a].endsWith('/' + matchingRole)) {
          hasRole = true;
          break;
        }
      }
    }

    return hasRole;
  }


  constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService, private _http: HttpClient) {
    super();
  }

  get cachedUser(): User {
    return this._cachedUser;
  }

  getLoggedInUser(showOverlay = true, showErrorToast = true): Promise<User> {
    return new Promise((resolve, reject) => {
      if (this._cachedUser == null) {
        this.dataApiService.get(`${this.configManager.getSetting(ConfigManagerProperties.loggedInUserRoot)}`, null, <DataOptions>{
          loadingOverlayEnabled: showOverlay,
          toastOnError: showErrorToast
        }).then(
          (response: Envelope) => {
            this._cachedUser = response['data'] as User;
            resolve(this._cachedUser);
          },
          error => {
            reject(error);
          }
        );
      } else {
        resolve(this._cachedUser);
      }
    });
  }

  /**
   * Check for associated role(s)
   * @param {string[]} roles
   * @returns Promise<boolean>
   */
  hasRoles(roles: string[]): Promise<boolean> {
    return new Promise((resolve, reject) => {
      // of(this._loggedInUserData.value).toPromise()
      // this.getLoggedInUser(false, false)
      of(this._loggedInUserData.value).toPromise().then(
        userInfo => {
          // this._cachedUser = userInfo as User;
          resolve(UserApiService.findRoleMatch(userInfo.roles, roles));
        },
        error => {
          reject(error);
        }
      );
    });
  }

  protected getEndPoint(): string {
    return this.configManager.getSetting(ConfigManagerProperties.userApiEndpoint);
  }

  // Get Pilot User for Customer 2.0 access
  getPioltUser(){
    return this._http.get(`${this.configManager.getSetting(ConfigManagerProperties.apiUrl)}${this.configManager.getSetting(ConfigManagerProperties.pioltApiEndPoint)}`).pipe(timeout(75000), catchError((error) => {
      throw error;
    }));
  }
}
