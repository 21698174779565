import { RadioactivityUomCd } from '@xpo-ltl/sdk-common';

export enum RadioactiveFormFields {
  RadioactiveChemical = 'radioactiveChemical',
  Radioactivity = 'radioactivity',
  Uom = 'uom',
  TransportIndex = 'transportIndex',
  FissileExpected = 'fissileExpected',
}

export interface RadioactiveClassification {
  radioactiveChemical: string;
  radioactivity: number;
  uom: RadioactivityUomCd;
  transportIndex: number;
  fissileExpected: boolean;
}

export class RadioactiveClassificationHelper {
  static hasValue(value: RadioactiveClassification): boolean {
    return !!value && (
      !!value.fissileExpected
      || !!value.radioactiveChemical
      || !!value.radioactivity
      || !!value.transportIndex
      || !!value.uom
    );
  }
}
